import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAlert } from "react-alert";
import { getEmployerList } from '../../API/Services/authService';
import { AppContext } from '../../AppContext';
import moment from 'moment';
const EmployerList = () => {
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const { userProfile } = useContext(AppContext);
    useEffect(()=>{
        userProfile()
    },[])

    useEffect(() => {
        getAllRequest();
    }, [])

    const getAllRequest = () => {
        setLoader(true)
        getEmployerList()
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data?.employers)
                    // alert.success(response?.data?.isError);
                    // navigate(RouterConstant.Layout);

                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })

    }


    return (
        <div className="data-table table-responsive">
            <table className="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col">S/N</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Contact Person Name</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Email</th>
                        <th scope="col">No of Employee</th>
                        <th scope="col">Created At</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        apiData?.map((employer, index) => {
                            return (
                                <tr key={index}>
                                    <td scope="row" className="pt-md-4 pt-3">{index+1}</td>
                                    <td className="pt-md-4 pt-3">{employer?.companyName}</td>
                                    <td className="pt-md-4 pt-3">{employer?.contactPersonName}</td>
                                    <td className="pt-md-4 pt-3">{employer?.mobileCountryCode} {employer?.mobile}</td>
                                    <td className="pt-md-4 pt-3">{employer?.email}</td>
                                    <td className="pt-md-4 pt-3">{employer?.noOfEmployee}</td>
                                    <td className="pt-md-4 pt-3">{moment(employer?.createdAt).format('DD/MM/YYYY')} {moment(employer?.createdAt).format('LT')}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
export default EmployerList
