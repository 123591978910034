import './userDetail.scss'
import moment from 'moment';
import { ReactComponent as CommentIcon } from '../../assets/images/icons/comment_icon.svg';
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg';
import { ReactComponent as AcceptIcon } from '../../assets/images/icons/accept.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/icons/reject.svg';
import { ReactComponent as RejectedIcon } from '../../assets/images/icons/rejected.svg'


const BasicDatail = ({ data, acceptBasicDetail, rejectBasicDetail, addNote, toggleField, toggle, toggleFieldStatus }) => {
    return (
        
        <>
            <div className="profile-section-card">
                <div className="head-wrap">
                    <h3 className="heading">Basic Detail
                        {/* <span className={`badge ms-3 badge-msg ${data?.basicDetailsStatus === -1 ? "bg-danger" : data?.basicDetailsStatus === 2 ? "bg-success" : ''}`}>
                            {`${data?.basicDetailsStatus === -1 ? "Rejected" : data?.basicDetailsStatus === 2 ? "Approved" : ''}`}
                        </span> */}
                    </h3>
                    <ul className="head-action">
                        {
                            // data?.basicDetailsStatus !== 0 ?
                            // <li className="accet-reject" onClick={() => toggle({ "action": 'accept', 'type': 'basicDetails' })}>
                            //     <i className="fa fa-check text-success"></i>
                            // </li>
                            // :null
                        }
                        <li className=" accet-reject  cursor-pointer" onClick={() => toggle({ "action": 'Add Comment', 'type': 'basicDetails' })}>
                            <CommentIcon className="" onClick={addNote} />
                            <span className='ms-3'>Add Comment</span>
                        </li>
                        {
                            //     <li className="accet-reject" >
                            // </li>
                        }

                    </ul>
                </div>
                <div className="detail-field">
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">First Name</label>
                            <p className="value">{data?.firstName}</p>
                        </div>
                        {
                            data?.isSendForKYCApproval != 4 && data?.fieldStatus?.firstName != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "firstName", "fieldData": data?.firstName, "feildType": "text", "fieldValidation": "Field is required", "fieldLabel": "First Name","required":true})}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            data?.fieldStatus?.firstName == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                data?.fieldStatus?.firstName == 3 ?
                                    <>
                                        <div className="admin-action-wrap rejected" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            data?.isSendForKYCApproval == 2 ?
                                <>
                                    {data?.fieldStatus?.firstName != 2 ?
                                        <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'firstName' })}>
                                            <AcceptIcon className="admin-action" />
                                            <label className="label-key">Approve</label>
                                        </div>
                                        : null
                                    }
                                    {data?.fieldStatus?.firstName == 1 ?
                                        <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'firstName' })}>
                                            <RejectIcon className="admin-action" />
                                            <label className="label-key">Reject</label>
                                        </div>
                                        : null
                                    }
                                </>
                                : null
                        }




                    </div>

                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Last Name</label>
                            <p className="value">{data?.lastName}</p>
                        </div>
                        {
                            data?.isSendForKYCApproval != 4 && data?.fieldStatus?.lastName != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "lastName", "fieldData": data?.lastName, "feildType": "text", "fieldValidation": "Field is required", "fieldLabel": "Last Name","required":false })}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            data?.fieldStatus?.lastName == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                data?.fieldStatus?.lastName == 3 ?
                                    <>
                                        <div className="admin-action-wrap rejected" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            data?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        (data?.fieldStatus?.lastName != 2) ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'lastName' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div> : null
                                    }
                                    {
                                        data?.fieldStatus?.lastName == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'lastName' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }

                                </>
                                : null
                        }

                    </div>
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Phone Number</label>
                            <p className="value">{data?.mobileCountryCode} {data?.mobile}</p>
                        </div>

                        {/* <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "mobile", "fieldData": data?.mobile, "feildType": "text", "fieldValidation": "Field is required", "fieldLabel": "Contact" })}>
                            <EditIcon className="admin-action" />
                            <label className="label-key">Edit</label>
                        </div> */}
                        {
                            data?.fieldStatus?.mobile == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                data?.fieldStatus?.mobile == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            data?.isSendForKYCApproval == 2 ?
                                <>
                                    {data?.fieldStatus?.mobile != 2 ?
                                        <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'mobile' })}>
                                            <AcceptIcon className="admin-action" />
                                            <label className="label-key">Approve</label>
                                        </div>
                                        : null
                                    }
                                    {
                                        data?.fieldStatus?.mobile == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'mobile' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }

                                </>

                                : null
                        }

                    </div>

                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Gender</label>
                            <p className="value">{data?.gender}</p>
                        </div>
                        {
                            data?.isSendForKYCApproval != 4 && data?.fieldStatus?.gender != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "gender", "fieldData": data?.gender, "feildType": "select", "fieldValidation": "Field is required", "fieldLabel": "Gender","required":true})}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            data?.fieldStatus?.gender == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                data?.fieldStatus?.gender == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            data?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        data?.fieldStatus?.gender != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'gender' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }

                                    {
                                        data?.fieldStatus?.gender == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'gender' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }

                                </>
                                : null
                        }

                    </div>


                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Date of Birth</label>
                            <p className="value">{data.dob?moment(data.dob).format('DD/MM/YYYY'):'N/A'}</p>
                        </div>
                        {
                            data?.isSendForKYCApproval != 4 && data?.fieldStatus?.dob != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "dob", "fieldData": data.dob, "feildType": "select", "fieldValidation": "Field is required", "fieldLabel": "DOB","required":true})}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            data?.fieldStatus?.dob == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                data?.fieldStatus?.dob == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            data?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        data?.fieldStatus?.dob != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'dob' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        data?.fieldStatus?.dob == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'dob' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }

                                </>
                                : null
                        }

                    </div>
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Company Name</label>
                            <p className="value">{data?.companyName ? data?.companyName : data?.workDetails?.companyName}</p>
                        </div>
                        {
                            data?.isSendForKYCApproval != 4 && data?.fieldStatus?.companyName != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "companyName", "fieldData": data?.companyName, "feildType": "select", "fieldValidation": "Field is required", "fieldLabel": "Company Name","required":true })}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            data?.fieldStatus?.companyName == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                data?.fieldStatus?.companyName == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            data?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        data?.fieldStatus?.companyName != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'companyName' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }

                                    {
                                        data?.fieldStatus?.companyName == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'companyName' })} >
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }

                                </>
                                : null
                        }

                    </div>
                </div>
                {/* code of text area */}

            </div>
            {
                data?.basicDetailsComment && data?.isSendForKYCApproval == 1||data?.isSendForKYCApproval == 3 ?
                    <div className="commented-profile">
                        <textarea readOnly="true" value={data?.basicDetailsComment ? data?.basicDetailsComment : ''} />
                    </div>
                    : null
            }

        </>
    );
}

export default BasicDatail;