import React, { useState } from 'react';
import PdfPopUp from "../../Components/PdfPopUp/PdfPopUp";
import { getDateFromNo } from '../../helper/dateData';
import { ReactComponent as CommentIcon } from '../../assets/images/icons/comment_icon.svg';
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg';
import { ReactComponent as AcceptIcon } from '../../assets/images/icons/accept.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/icons/reject.svg';
import { ReactComponent as RejectedIcon } from '../../assets/images/icons/rejected.svg'
import _ from "lodash";

const CompanyDetail = ({ data, apiresponse, rejectBasicDetail, acceptBasicDetail, addNote, toggleField, toggle, toggleFieldStatus }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [viewFile, setViewFile] = useState('')
    const handlePdfPopUp = (document) => {
        setIsOpen(!isOpen);
        setViewFile(document)
    }
    return (
        <>

            <div className="profile-section-card">
                <div className="head-wrap">
                    <h3 className="heading">
                        Company Detail
                        {/* <span className={`badge ms-3 badge-msg ${data?.status === -1 ? "bg-danger" : data?.status === 2 ? "bg-success" : ''}`}>
                            {`${data?.status === -1 ? "Rejected" : data?.status === 2 ? "Approved" : ''}`}
                        </span> */}
                    </h3>
                    <ul className="head-action">
                        {
                            // data?.status !== 0 ?
                            // <li className="admin-action-wrap reject" onClick={() => toggle({ "action": 'accept', 'type': 'workDetails' })}>
                            //     <AcceptIcon className="admin-action" />
                            // </li>
                            // : null
                        }
                        <li className="admin-action-wrap cursor-pointer reject" onClick={() => toggle({ "action": 'Add Comment', 'type': 'workDetails' })}>
                            <CommentIcon className="" onClick={addNote} />
                            <span className='ms-3'>Add Comment</span>
                        </li>
                        {/* <li className="accet-reject" onClick={addNote}>
                        <i className="fa fa-envelope text-primary"></i>
                    </li> */}
                    </ul>
                </div>

                <div className="detail-field">
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Contractor Name</label>
                            <p className="value">{data?.contractor}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.contractor != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "contractor", "fieldData": data?.contractor, "feildType": "select", "fieldValidation": "Field is required", "fieldLabel": "Contractor Name", "required": true, "companyDetails": apiresponse?.companyName })}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.contractor == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.contractor == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.contractor != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'contractor' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.contractor == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'contractor' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }

                                </>
                                : null
                        }

                    </div>
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Location</label>
                            <p className="value">{data?.site}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.site != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "site", "fieldData": data?.site, "feildType": "select", "fieldValidation": "Field is required", "fieldLabel": "Location", "required": true })}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.site == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.site == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.site != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'site' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.site == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'site' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }

                                </>
                                : null
                        }

                    </div>


                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Employee ID</label>
                            <p className="value">{data?.employeeId}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.employeeId != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "employeeId", "fieldData": data?.employeeId, "feildType": "text", "fieldValidation": "Field is required", "fieldLabel": "Employee ID", "required": true })}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.employeeId == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.employeeId == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            data?.employeeId &&
                            <>
                        {
                            apiresponse?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.employeeId != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'employeeId' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.employeeId == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'employeeId' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }

                                </>
                                : null
                        }
                            </>
                        }

                    </div>

                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Salary</label>
                            <p className="value">N {data?.salary}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.salary != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "salary", "fieldData": data?.salary, "feildType": "text", "fieldValidation": "Field is required", "fieldLabel": "Salary" })}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.salary == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.salary == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.salary != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'salary' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.salary == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'salary' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }


                                </>
                                : null
                        }


                    </div>

                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Last Salary Date</label>

                            <p className="value">{getDateFromNo(data?.salarycreditDay ? data?.salarycreditDay : 'N/A', "Every Month")}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.salarycreditDay != 2 ?
                                <>
                                    <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "salarycreditDay", "fieldData": data?.salarycreditDay, "feildType": "select", "fieldValidation": "Field is required", "fieldLabel": "Last Salary Date", "required": true })}>
                                        <EditIcon className="admin-action" />
                                        <label className="label-key">Edit</label>
                                    </div>
                                </>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.salarycreditDay == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.salarycreditDay == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.salarycreditDay != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'salarycreditDay' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.salarycreditDay == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'salarycreditDay' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }


                                </>
                                : null
                        }

                    </div>
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Work E-mail</label>
                            <p className="value">{data?.workEmail}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.workEmail != 2 ?
                                <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "workEmail", "fieldData": data?.workEmail, "feildType": "email", "fieldValidation": "Field is required", "fieldLabel": "Work E-mail", "required": true })}>
                                    <EditIcon className="admin-action" />
                                    <label className="label-key">Edit</label>
                                </div>
                                : null
                        }

                        {
                            (data?.workEmail) &&

                            <>

                                {
                                    apiresponse?.fieldStatus?.workEmail == 2 ?
                                        <>
                                            <div className="admin-action-wrap accept" >
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Accepted</label>
                                            </div>
                                        </>
                                        :
                                        apiresponse?.fieldStatus?.workEmail == 3 ?
                                            <>
                                                <div className="admin-action-wrap accept" >
                                                    <RejectedIcon className="admin-action" />
                                                    <label className="label-key">Rejected</label>
                                                </div>
                                            </>
                                            : null
                                }
                                {
                                    apiresponse?.isSendForKYCApproval == 2 ?
                                        <>
                                            {
                                                apiresponse?.fieldStatus?.workEmail != 2 ?
                                                    <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'workEmail' })}>
                                                        <AcceptIcon className="admin-action" />
                                                        <label className="label-key">Approve</label>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                apiresponse?.fieldStatus?.workEmail == 1 ?

                                                    <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'workEmail' })}>
                                                        <RejectIcon className="admin-action" />
                                                        <label className="label-key">Reject</label>
                                                    </div>
                                                    : null
                                            }

                                        </>
                                        : null
                                }
                            </>
                        }
                    </div>
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Bank Statement</label>
                            {
                                data?.bankStatements ?
                                    <p title="View Document" className="value text-truncate pe-4 cusror-pointer" onClick={() => handlePdfPopUp(data?.bankStatements)}><i class="fa fa-file-text me-2" aria-hidden="true" />View Document</p> :
                                    <p title="View Document" className="value text-truncate pe-4 cusror-pointer">N/A</p>
                            }
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.bankStatements != 2 ?
                                <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "bankStatements", "fieldData": data?.bankStatements, "feildType": "file", "fieldValidation": "Field is required", "fieldLabel": "Bank Statement", "required": true })}>
                                    <EditIcon className="admin-action" />
                                    <label className="label-key">Edit</label>
                                </div>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.bankStatements == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.bankStatements == 3 ?
                                    <>
                                        <div className="admin-action-wrap accept" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.bankStatements != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'bankStatements' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.bankStatements == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'bankStatements' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }


                                </>
                                : null
                        }

                    </div>
                    {/* code of text area */}


                </div>
            </div>

            {
                data?.comment && apiresponse?.isSendForKYCApproval == 1 || apiresponse?.isSendForKYCApproval == 3 ?
                    <div className="commented-profile">
                        <textarea readOnly="true" value={data?.comment} />
                    </div>
                    : null
            }

            <PdfPopUp
                isOpen={isOpen}
                toggle={handlePdfPopUp}
                file={viewFile}
            />
        </>
    );
}

export default CompanyDetail;