import moment from "moment"
import { Navigate } from "react-router-dom"

// remove item
export function clearItem(item) {
    return localStorage.removeItem(item)
}
// get token
export function getToken() {
    return localStorage.getItem('token')
}
//  set token
export function setToken(token) {
    return localStorage.setItem('token', token)
}
// check token
export function checkToken() {
    if (localStorage.getItem('token'))
        return true
    else
        return false
}
//  get user ID
export function getUserId() {
    return localStorage.getItem('userId')
}

//  set user ID
export function setUserId(userId) {
    return localStorage.setItem('userId', userId)
}

// set header content type
export function getHeaderType() {
    return localStorage.getItem("contentType")
}
export function setHeaderType(contentType) {
    return localStorage.setItem("contentType", contentType)
}


// next month date

export function calculateNextMonthDate(day) {

    const digit = day; // The digit representing the current month (0-11)
    const currentDate = moment();
    const currentDay = currentDate.date();
    const nextMonth = currentDate.add(1, 'month');
    if (digit > currentDay || digit == currentDay) {
        return nextMonth?.date(digit)?.format('DD/MM/YYYY');
    } else {
        return currentDate?.date(digit)?.format('DD/MM/YYYY')
    }

}

