import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/images/logo-lg.svg'
import { ReactComponent as LogoutIcon } from '../assets/images/icons/logout_icon.svg';
import './sidebar.scss'
import Logout from '../Components/LogoutModal/Logout';
import _ from "lodash";
import { RouterConstant } from '../constants/RouteConstant';
import { AppContext } from '../AppContext';
const Sidebar = ({ toggle, background, toggleSidebar }) => {
    const [sidebarToggleState, setSidebarToggleState] = useState(true);
    
    const [isOpen, setIsOpen] = useState(false);
    const employeeAllString = ['employee-incomplete', 'kyc-pending', 'kyc-rejected', 'employee-completed']
    const advanceStatusAllString = ['live-request', 'past-request', 'lapse-request'];
    const requestAllString = ['pending-request', 'rejected-request', 'approved-request'];
    const adminControlAllString = ['admin-control'];
    const processingFeeAllString = ['processing-fee-control'];
    const manageAdminAllString = ['manage-admin']
    const { userProfile, userData } = useContext(AppContext);
    useEffect(()=>{
        userProfile()
    },[])
    // const allMatched = stringsToMatch.every((str) => array.includes(str));

    const handleChildClick = (e) => {
        e.stopPropagation();
    }

    const handleToggleLogout = () => {
        setIsOpen(!isOpen)
    }


    const userPermission = (permissionMatched) => {
        // let allMatched = false
        if (userData?.role === "admin") {
            return true
        } else {
            if (userData?.permissions?.length) {
                return permissionMatched.some((str) => userData?.permissions.includes(str));
            }
        }
    }


    return (
        <>

            <aside className={`sidebar-outer-wrapper ${toggle ? "active" : ""} ${background ? "background-active" : ""}`} onClick={sidebarToggleState ? toggleSidebar : ""}>
                <div className={`sidebar-inner-wrapper`} onClick={handleChildClick}>
                    <div className="logo-wrapper">
                        <Logo className="logo" />
                        <i className="fa fa-arrow-left" aria-hidden="true" onClick={!sidebarToggleState ? toggleSidebar : ""}></i>
                    </div>
                    <ul className="menu-wrapper">
                        <li className="menu-item-wrapper">
                            <NavLink onClick={toggleSidebar} to={RouterConstant.Layout} className="menu-item">
                                Overview
                            </NavLink>
                        </li>
                        {
                            userPermission(employeeAllString) &&
                            <li className="menu-item-wrapper">
                                <NavLink onClick={toggleSidebar} to={RouterConstant.Employees} className="menu-item">
                                    Employees</NavLink>
                            </li>
                        }
                        {/* <li className="menu-item-wrapper">
                        <NavLink to="/" className="menu-item">
                            Employer
                        </NavLink>
                    </li> */}

                        <li className="menu-item-wrapper">
                            <NavLink onClick={toggleSidebar} to={RouterConstant.EmployerList} className="menu-item">
                                Employer
                            </NavLink>
                        </li>
                        {
                            userPermission(advanceStatusAllString) &&
                            <li className="menu-item-wrapper">
                                <NavLink onClick={toggleSidebar} to={RouterConstant.AdvanceStatus} className="menu-item">
                                    Advance Status
                                </NavLink>
                            </li>
                        }
                        {
                            userPermission(requestAllString) &&
                            <li className="menu-item-wrapper">
                                <NavLink onClick={toggleSidebar} to={RouterConstant.Request} className="menu-item">
                                    Request
                                </NavLink>
                            </li>
                        }
                        {
                            userPermission(adminControlAllString) &&
                            <li className="menu-item-wrapper">
                                <NavLink onClick={toggleSidebar} to={RouterConstant.AdminControl} className="menu-item">
                                    Admin Controls
                                </NavLink>
                            </li>
                        }
                        {
                            userPermission(manageAdminAllString) &&
                            <li className="menu-item-wrapper">
                                <NavLink onClick={toggleSidebar} to={RouterConstant.manageAdmin} className="menu-item">
                                    Manage Admin
                                </NavLink>
                            </li>
                        }
                        {
                            userPermission(processingFeeAllString) &&
                            <li className="menu-item-wrapper">
                                <NavLink onClick={toggleSidebar} to={RouterConstant.ProcessingFeeControl} className="menu-item">
                                    Processing Fee Controls
                                </NavLink>
                            </li>
                        }
                        
                            <li className="menu-item-wrapper">
                                <NavLink onClick={toggleSidebar} to={RouterConstant.referedEmployer} className="menu-item">
                                    Refered Employer
                                </NavLink>
                            </li>
                        
                        <li className="menu-item-wrapper log-out-item">
                            <div onClick={handleToggleLogout} className="menu-item d-flex cursor-pointer">
                                <div className="menu-icon-wrapper me-3"><LogoutIcon /></div>
                                <p className="menu-item-text">Log out</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </aside>

            <Logout
                isOpen={isOpen}
                toggleLogout={handleToggleLogout}
            />
        </>
    )
}

export default Sidebar
