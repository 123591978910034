import React, { useEffect } from 'react'
import { getDateFromNo } from '../../helper/dateData'
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg';
import { ReactComponent as AcceptIcon } from '../../assets/images/icons/accept.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/icons/reject.svg';
import { ReactComponent as RejectedIcon } from '../../assets/images/icons/rejected.svg'

const ApproveLimitDetails = ({ data, apiresponse, acceptBasicDetail, rejectBasicDetail, addNote, toggleField, toggle, toggleFieldStatus }) => {


    return (
        <>
            <div className="profile-section-card">
                <div className="head-wrap">
                    <h3 className="heading">Approve Limit Details</h3>
                </div>
                <div className="detail-field">
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Approved Limit </label>
                            <p className="value">{data?.accountLimit}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.accountLimit != 2 ?
                                <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "accountLimit", "fieldData": data?.accountLimit, "feildType": "text", "fieldValidation": "Field is required", "fieldLabel": "Approve Limit","required":true })}>
                                    <EditIcon className="admin-action" />
                                    <label className="label-key">Edit</label>
                                </div>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.accountLimit == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.accountLimit == 3 ?
                                    <>
                                        <div className="admin-action-wrap rejected" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.accountLimit != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'accountLimit' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.accountLimit == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'accountLimit' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }


                                </>
                                : null
                        }

                    </div>
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Loan Due Date </label>
                            <p className="value">{getDateFromNo(data?.salaryDueDate ? data?.salaryDueDate : 'N/A', 'Every Month')}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.salaryDueDate != 2 ?
                                <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "salaryDueDate", "fieldData": data?.salaryDueDate, "feildType": "select", "fieldValidation": "Field is required", "fieldLabel": "Salary Due Date","required":true })}>
                                    <EditIcon className="admin-action" />
                                    <label className="label-key">Edit</label>
                                </div>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.salaryDueDate == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.salaryDueDate == 3 ?
                                    <>
                                        <div className="admin-action-wrap rejected" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.salaryDueDate != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'salaryDueDate' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.salaryDueDate == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'salaryDueDate' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }


                                </>
                                : null
                        }

                    </div>
                </div>

            </div>
            {
                data?.comment  && apiresponse?.isSendForKYCApproval == 1||apiresponse?.isSendForKYCApproval == 3  ?
                    <div className="commented-profile">
                        <textarea readOnly="true" value={data?.comment} />
                    </div>
                    : null
            }

        </>
    )
}

export default ApproveLimitDetails
