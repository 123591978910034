import React, { useContext, useEffect, useRef, useState } from "react";
import "./request.scss";
import { NavLink, useNavigate } from "react-router-dom";
import {
    getAllUserRequestList,
    getAllUserRequestListCsv,
    getRequest,
    getRequestCsv,
} from "../../API/Services/authService";
import { useAlert } from "react-alert";
import LoaderWrapper from "../../Components/LoaderWrapper/LoaderWrapper";
import ReactPaginate from "react-paginate";
import { ReactComponent as Asc } from "../../assets/images/icons/assending.svg";
import { ReactComponent as Desc } from "../../assets/images/icons/decending.svg";
import _ from "lodash";
import moment from "moment";
import SearchWrapper from "../../Components/Search";
import { RouterConstant } from "../../constants/RouteConstant";
import { useLocation } from "react-router-dom";
import { ReactComponent as ScrollLeft } from "./../../assets/images/icons/scroll_left.svg";
import { ReactComponent as ScrollRight } from "./../../assets/images/icons/scroll_right.svg";
import { CSVLink } from "react-csv";
import { AppContext } from "../../AppContext";


const RequestMoney = () => {
    // let location = useLocation()
    const ref = useRef();
    const { state } = useLocation();
    const navigate = useNavigate();
    const pagination = useRef();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [status, setStatus] = useState("pending");
    const [pageNumber, setPageNumber] = useState(1);
    const [limit, setLimit] = useState(50);
    const [searchString, setSearchString] = useState("");
    const delayedQuery = useRef(_.debounce((q) => sendQuery(q), 500)).current;
    const [searchQuery, setSearchQuery] = useState("");
    const [scrollPoint, setScrollPoint] = useState(0);
    const [csvData, setCsvData] = useState([]);
    const [pageNumberCsv, setPageNumberCsv] = useState(1);
    const { userProfile, userData } = useContext(AppContext);
    useEffect(()=>{
        userProfile()
    },[])

    useEffect(()=>{
        if(userData?.permissions?.find((element) => element === 'approved-request')){
            setStatus('approved');
        }else if(userData?.permissions?.find((element) => element === 'rejected-request')){
            setStatus('rejected');
        }else{
            setStatus('pending');
        }

    },[userData])
    
    const userPermission = (permissionMatched) => {
        // let allMatched = false
        if (userData?.role === "admin") {
            return true
        } else {
            if (userData?.permissions?.length) {
                return userData?.permissions?.some((section) => section === permissionMatched);
            }
        }
    }
    const [sorting, setSorting] = useState({
        order: -1,
        keyName: "createdAt",
    });

    const headers = [
        { label: "User ID", key: "userId" },
        { label: "User Name", key: "userName" },
        { label: "Mobile No", key: "mobileNo" },
        { label: "Company Name", key: "companyName" },
        { label: "Request Id", key: "requestId" },
        { label: "Requested Amount", key: "requestedAmount" },
        { label: "Approved Limit", key: "approvedLimit" },
        { label: "Available limit", key: "availableLimit" },
        { label: "Request Date", key: "requestDate" },
        { label: "Status", key: "status" },
    ];



    useEffect(() => {
        getAllRequest();
    }, [status, sorting, pageNumber, limit, searchQuery, userData]);

    const getAllRequest = () => {
        setLoader(true);
        if (state?.userId) {
            getAllUserRequestList(
                status,
                sorting,
                pageNumber,
                limit,
                searchQuery,
                state?.userId
            )
                .then((response) => {
                    if (response?.data?.status === "OK") {
                        setApiData(response?.data?.data?.requests);
                        setTotalPageCount(response?.data?.data?.total);
                        // alert.success(response?.data?.isError);
                        // navigate(RouterConstant.Layout);
                    } else {
                        console.log("test login api", response);
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    console.log("catch block", error);
                    alert.error(error?.message);
                    setLoader(false);
                });
        } else {
            getRequest(status, sorting, pageNumber, limit, searchQuery)
                .then((response) => {
                    if (response?.data?.status === "OK") {
                        setApiData(response?.data?.data?.requests);
                        setTotalPageCount(response?.data?.data?.total);
                        // alert.success(response?.data?.isError);
                        // navigate(RouterConstant.Layout);
                    } else {
                        console.log("test login api", response);
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    console.log("catch block", error);
                    alert.error(error?.message);
                    setLoader(false);
                });
        }
    };

    const handleAdminFilter = (profileType) => {
        let pageNo = 1;
        setPageNumber(pageNo);
        setStatus(profileType);
    };


    const setPage = ({ selected }) => {
        let pageNo = selected + 1;
        setPageNumber(pageNo);
        // getStoreValue(pageNo)
    };

    const handleSorting = (sortKey, sortOrder) => {
        setSorting({
            order: sortOrder,
            keyName: sortKey,
        });
    };

    const handleSearch = (event) => {
        setSearchString(event.target.value);
        setPageNumber(1);
        delayedQuery(event.target.value);
    };
    const sendQuery = (query) => setSearchQuery(query);

    const scroll = (scrollOffset) => {
        let tempScroll = scrollOffset;
        tempScroll = scrollPoint + scrollOffset;
        let ofset = ref.current.scrollLeft;
        let elementScrollWidth = ref.current.scrollWidth;
        let elementWidth = ref.current.offsetWidth;
        setScrollPoint(tempScroll);
        ref.current.scroll({
            left: tempScroll,
            behavior: "smooth",
        });
    };

    const redirectToPaymentPage = (redirectLink) => {
        navigate(redirectLink);
    };

    const handleClickDownload = async () => {
        setLoader(true);
        let tempCsvData = [];
        let limit = 50;
        let totalLoopCount = totalPageCount / limit;

        for (let i = 0; i < totalLoopCount; i++) {
            if (state?.userId) {
                await getAllUserRequestListCsv(
                    status,
                    sorting,
                    i + 1,
                    limit,
                    searchQuery,
                    state?.userId
                )
                    .then((response) => {
                        if (response?.data?.status === "OK") {
                            let requestData = response?.data?.data?.requests;
                            requestData.map(request => {
                                tempCsvData.push(
                                    {
                                        userId: request?.user?.generatedUserId,
                                        userName: `${request?.user?.firstName} ${request?.user?.lastName}`,
                                        mobileNo: `+${request?.user?.mobileCountryCode} ${request?.user?.mobile}`,
                                        requestId: request?.generatedRequestId,
                                        companyName: request?.user?.companyName,
                                        requestedAmount: request?.requestedAmount,
                                        approvedLimit: request?.user?.accountLimit,
                                        availableLimit: request?.user?.accountLimit - request?.user?.withdrawnAmount,
                                        requestDate: moment(request?.createdDate).format("DD/MM/YYYY"),
                                        status: request?.status,
                                    }
                                )
                            });
                            // setTotalPageCount(response?.data?.data?.total);
                        } else {
                            // alert.success(response?.data?.isError);
                        }
                    })
                    .catch((error) => {
                        alert.error(error?.message);
                        setLoader(false);
                    });
            } else {
                await getRequestCsv(status, sorting, i + 1, limit, searchQuery)
                    .then((response) => {
                        if (response?.data?.status === "OK") {
                            let requestData = response?.data?.data?.requests;
                            requestData.forEach(request => {
                                tempCsvData.push(
                                    {
                                        userId: request?.user?.generatedUserId,
                                        userName: `${request?.user?.firstName} ${request?.user?.lastName}`,
                                        mobileNo: `+${request?.user?.mobileCountryCode} ${request?.user?.mobile}`,
                                        requestId: request?.generatedRequestId,
                                        companyName: request?.user?.companyName,
                                        requestedAmount: request?.requestedAmount,
                                        approvedLimit: request?.user?.accountLimit,
                                        availableLimit: request?.user?.accountLimit - request?.user?.withdrawnAmount,
                                        requestDate: moment(request?.createdDate).format("DD/MM/YYYY"),
                                        status: request?.status,
                                    }
                                )
                            });
                            // setTotalPageCount(response?.data?.data?.total);
                        } else {
                            // alert.success(response?.data?.isError);
                        }
                    })
                    .catch((error) => {
                        alert.error(error?.message);
                        setLoader(false);
                    });
            }
        }
        setCsvData(current => [...current, ...tempCsvData]);
    };

    useEffect(() => {
        if (csvData.length > 0) {
            const downloadElement = document.getElementById("clickDownload");
            setTimeout(() => {
                downloadElement.click();
                setLoader(false);
                setCsvData([])
            }, 3000)
        }
    }, [csvData]);

    return (
        <>
            <div className="top-filter-button">
                <ul className="list-unstyled btn-filter-list">
                    {
                        userPermission('pending-request') &&
                        <li
                            className={`btn white-btn ${status === "pending" && "active"}`}
                            onClick={() => handleAdminFilter("pending")}
                        >
                            Pending Request
                        </li>
                    }
                    {
                        userPermission('rejected-request') &&
                        <li
                            className={`btn white-btn ${status === "rejected" && "active"}`}
                            onClick={() => handleAdminFilter("rejected")}
                        >
                            Rejected Request
                        </li>
                    }
                    {
                        userPermission('approved-request') &&
                        <li
                            className={`btn white-btn ${status === "approved" && "active"}`}
                            onClick={() => handleAdminFilter("approved")}
                        >
                            Approved Request
                        </li>
                    }
                </ul>
                <SearchWrapper
                    type="text"
                    name="searchString"
                    className="mb-3 mt-4 mt-md-0"
                    value={searchString}
                    onChange={handleSearch}
                    placeholder="Search..."
                />
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <span className="export-csv" onClick={handleClickDownload}>
                    <i className="fa fa-download"></i>Export
                </span>
                <div className="scrollButton">
                    <ScrollLeft className="scroll_button" onClick={() => scroll(-200)} />
                    <ScrollRight className="scroll_button" onClick={() => scroll(200)} />
                </div>
            </div>
            <CSVLink id="clickDownload" data={csvData} headers={headers}></CSVLink>
            <div className="data-table table-responsive" ref={ref}>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">User ID</th>
                            <th scope="col">
                                Name
                                {sorting["keyName"] === "fullName" &&
                                    sorting["order"] === -1 ? (
                                    <Desc
                                        className="ascending_icons ms-3"
                                        onClick={() => handleSorting("fullName", 1)}
                                    />
                                ) : (
                                    <Asc
                                        className="ascending_icons ms-3"
                                        onClick={() => handleSorting("fullName", -1)}
                                    />
                                )}
                            </th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Request ID</th>
                            <th scope="col">
                                Company Name
                                {sorting["keyName"] === "companyName" &&
                                    sorting["order"] === -1 ? (
                                    <Desc
                                        className="ascending_icons ms-3"
                                        onClick={() => handleSorting("companyName", 1)}
                                    />
                                ) : (
                                    <Asc
                                        className="ascending_icons ms-3"
                                        onClick={() => handleSorting("companyName", -1)}
                                    />
                                )}
                            </th>
                            <th scope="col">Amount Requested</th>
                            <th scope="col">Approved Limit</th>
                            <th scope="col">Available Limit</th>
                            <th scope="col">
                                Request Date/Time
                                {sorting["keyName"] === "createdAt" &&
                                    sorting["order"] === -1 ? (
                                    <Desc
                                        className="ascending_icons ms-3"
                                        onClick={() => handleSorting("createdAt", 1)}
                                    />
                                ) : (
                                    <Asc
                                        className="ascending_icons ms-3"
                                        onClick={() => handleSorting("createdAt", -1)}
                                    />
                                )}
                            </th>
                            <th scope="col">Status</th>
                            {status !== "rejected" && <th scope="col">More</th>}
                        </tr>
                    </thead>
                    <tbody>

                        {
                            apiData?.map((request, index) => {
                                return (
                                    <tr key={index}>
                                        <td scope="row" className="pt-md-4 pt-3">{((index + 1) + ((pageNumber - 1) * limit))}</td>
                                        <td className="pt-md-4 pt-3">{request?.user?.generatedUserId}</td>
                                        <td className="pt-md-4 pt-3">{request?.user?.firstName} {request?.user?.lastName}</td>
                                        <td className="pt-md-4 pt-3">{request?.user?.mobileCountryCode} {request?.user?.mobile}</td>
                                        <td className="pt-md-4 pt-3">{request?.generatedRequestId}</td>
                                        <td className="pt-md-4 pt-3">{request?.user?.companyName}</td>
                                        <td className="pt-md-4 pt-3">{request?.requestedAmount}</td>
                                        <td className="pt-md-4 pt-3">{request?.user?.accountLimit}</td>
                                        <td className="pt-md-4 pt-3">{request?.user?.accountLimit - request?.user?.withdrawnAmount}</td>
                                        <td className="pt-md-4 pt-3">{moment(request?.createdAt).format('DD/MM/YYYY')}</td>
                                        <td className="pt-md-4 pt-3">{request?.status}</td>
                                        {
                                            status !== 'rejected' &&

                                            <td className="pt-md-4 pt-3">
                                                {
                                                    status === 'approved' ?
                                                        <span onClick={() => navigate(`${RouterConstant.PaymentDetail}/${request._id}`)} className="heighlight">Pay Now</span>
                                                        :
                                                        <span onClick={() => navigate(`${RouterConstant.ViewRequest}/${request._id}`)} className="heighlight"> view more</span>
                                                }
                                            </td>
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                <div className="my-5 d-flex justify-content-center">
                    <ReactPaginate
                        ref={pagination}
                        pageCount={totalPageCount / limit}
                        pageRangeDisplayed={10}
                        marginPagesDisplayed={1}
                        breakLabel="..."
                        onPageChange={setPage}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        previousLabel={<>&laquo;</>}
                        nextLabel={<>&raquo;</>}
                    />
                </div>
            </div>

            {loader ? <LoaderWrapper /> : ""}
        </>
    );
};
export default RequestMoney;
