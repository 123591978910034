import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { getAdvanceStatusList ,changeStatusBulk, allUserAdvanceStatusList} from '../../API/Services/authService'
import { useAlert } from "react-alert";
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Asc } from '../../assets/images/icons/assending.svg';
import { ReactComponent as Desc } from '../../assets/images/icons/decending.svg';
import _ from "lodash";
import moment from 'moment'
import SearchWrapper from '../../Components/Search';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import ReactDatePicker from 'react-datepicker';

import {useLocation } from 'react-router-dom'
import { ReactComponent as ScrollLeft } from './../../assets/images/icons/scroll_left.svg'
import { ReactComponent as ScrollRight } from './../../assets/images/icons/scroll_right.svg'


const UserAdvanceStatus = () => {
    const ref = useRef()
    const {state} = useLocation();
    const pagination = useRef();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [advanceStatus, setAdvanceStatus] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchString, setSearchString] = useState('');
    const delayedQuery = useRef(_.debounce(q => sendQuery(q), 1000)).current;
    const [searchQuery, setSearchQuery] = useState('');
    const [checkedAll, setCheckedAll] = useState(false);
    const [checked, setChecked] = useState({});
    const [bulkBtnStatus, setbulkBtnStatus] = useState(false);
    const [isbulkModalToggle, setisbulkModalToggle] = useState(false);
    const [multipleRequestIds, setMultipleRequestIds] = useState([]);

    const [isDatePickerToggle, setIsDatePickerToggle] = useState(false);
    const [Cdate, setDate] = useState(new Date());


    const [scrollPoint, setScrollPoint] = useState(100);


    const [sorting, setSorting] = useState({
        order: -1,
        keyName: 'fullName',
    });

    useEffect(() => {
        getAdvanceList();
    }, [advanceStatus, sorting, pageNumber, limit, searchQuery])

    const getAdvanceList = () => {
        setLoader(true)
        allUserAdvanceStatusList(advanceStatus, sorting, pageNumber, limit, searchString, state?.userId)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data);
                    setCheckValue(response?.data?.data);
                    // alert.success(response?.data?.isError);
                    // navigate(RouterConstant.Layout);

                } else {
                    console.log("else", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })
        

    }

    const handleAdminFilter = (status) => {
        setAdvanceStatus(status);
        // getAllUserList(advanceStatus)
    }

    const setPage = ({ selected }) => {
        let pageNo = selected + 1
        setPageNumber(pageNo);
        // getStoreValue(pageNo)
    }
    //  
    const handleSorting = (sortKey, sortOrder) => {
        setSorting({
            order: sortOrder,
            keyName: sortKey
        })
    }

    const handleSearch = (event) => {
        setSearchString(event.target.value);
        delayedQuery(event.target.value);
    }
    const sendQuery = query => setSearchQuery(query);

    useEffect(() => {
        let allChecked = true;
        for (const inputName in checked) {
            if (checked[inputName] === false) {
                allChecked = false;
            }
        }
        if (allChecked) {
            setCheckedAll(true);
        } else {
            setCheckedAll(false);
        }
        getCheckedStatus(checked);
    }, [checked]);

    // Select All checked
    const selectAll = (value) => {
        setCheckedAll(value);
        setChecked((prevState) => {
            const newState = { ...prevState };
            for (const inputName in newState) {
                newState[inputName] = value;
            }
            return newState;
        });
    };

    // code of checkbox 
    const toggleCheck = (inputName) => {
        setChecked((prevState) => {
            const newState = { ...prevState };
            newState[inputName] = !prevState[inputName];
            return newState;
        });
    };

    const setCheckValue = (resp) => {
        let obj = {};
        resp?.advanceStatus.map((e, index) => {
            obj[e?._id] = false;
        })
        setChecked(obj);
    }

    const getCheckedStatus = (item) => {
        let keys = Object.keys(item);
        let filteredRequestIds = keys.filter(function (key) {
            return item[key]
        });
        setMultipleRequestIds(filteredRequestIds);
        if (filteredRequestIds.length > 0) {
            setbulkBtnStatus(true);
        } else {
            setbulkBtnStatus(false);
        }
    }

    const handlebulkAction=()=>{
        setisbulkModalToggle(true);
    }

    const changeStatusInBulk=(status)=>{
        if(status==='completed'){
            setisbulkModalToggle(false);
            setIsDatePickerToggle(!isDatePickerToggle);
        }else{
            setLoader(true);
            let param={'status':status,requestIds:multipleRequestIds}
            changeStatusBulk(param) 
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setbulkBtnStatus(false);
                    setMultipleRequestIds([]);
                    setisbulkModalToggle(false);
                    getAdvanceList();
                    alert.success(response?.data?.message);
                    // navigate(RouterConstant.Layout);
                } else {
                    console.log("else", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })
        }
        

    }

    const closeAlertModal = () => {
        setisbulkModalToggle(false);
        setIsDatePickerToggle(false);
    }

    const selectDate=(date)=>{
        console.log("date",date);
        let tempDOB = moment(date).format('DD-MM-YYYY');
        setDate(tempDOB);
    }

    const saveDueDate=()=>{
        setLoader(true);
        let param={'status':'completed',requestIds:multipleRequestIds};
        param['dueDateAdvanceStatusComplete']=Cdate;
        changeStatusBulk(param) 
        .then((response) => {
            if (response?.data?.status === 'OK') {
                setIsDatePickerToggle(false);
                setbulkBtnStatus(false);
                setMultipleRequestIds([]);
                setisbulkModalToggle(false);
                getAdvanceList();
                alert.success(response?.data?.message);
                // navigate(RouterConstant.Layout);
            } else {
                console.log("else", response)
            }
            setLoader(false)
        })
        .catch(error => {
            console.log("catch block", error)
            //   setMessage(error.data.message);
            alert.error(error?.message);
            setLoader(false)
        })


    }

    
    const scroll = (scrollOffset) => {
        let tempScroll = scrollOffset
        tempScroll = scrollPoint + scrollOffset
        let ofset = ref.current.scrollLeft
        let elementScrollWidth = ref.current.scrollWidth
        let elementWidth = ref.current.offsetWidth
        setScrollPoint(tempScroll)
        ref.current.scroll({ 
            left: tempScroll, 
            behavior: 'smooth'
          });;
        
      };

    return (
        <>
            <div className="top-filter-button">
                <ul className="list-unstyled btn-filter-list">
                    <li className={`btn white-btn ${advanceStatus === 1 && 'active'}`} onClick={() => handleAdminFilter(1)}>Live</li>
                    <li className={`btn white-btn ${advanceStatus === 2 && 'active'}`} onClick={() => handleAdminFilter(2)}>Past</li>
                    <li className={`btn white-btn ${advanceStatus === 3 && 'active'}`} onClick={() => handleAdminFilter(3)}>Lapse</li>
                </ul>
                <SearchWrapper
                    type='text'
                    name='searchString'
                    className='mb-3 mt-4 mt-md-0'
                    value={searchString}
                    onChange={handleSearch}
                    placeholder="Search..."
                />
            </div>
            {
                bulkBtnStatus ?
                    <div >
                        <button className='top-filter-button' onClick={handlebulkAction}>Action</button>
                    </div>
                    : null
            }

<div className="scrollButton">
                <ScrollLeft className="scroll_button" onClick={() => scroll(-200)}/>
                <ScrollRight className="scroll_button" onClick={() => scroll(200)}/>
            </div>
            <div className="data-table table-responsive" ref={ref}>
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            {advanceStatus == 1 && ( apiData?.advanceStatus?.length>0) ?
                                <th > <input type="checkbox"
                                    onChange={(event) => selectAll(event.target.checked)}
                                    checked={checkedAll} /></th>
                                : null
                            }

                            <th scope="col">S/N</th>
                            <th scope="col">User ID</th>
                            <th scope="col">Name
                                {
                                    (sorting['keyName'] === 'fullName') && (sorting['order'] === 1) ? <Desc className="ascending_icons ms-3" onClick={() => handleSorting('fullName', -1)} />
                                        : <Asc className="ascending_icons ms-3" onClick={() => handleSorting('fullName', 1)} />
                                }
                            </th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Request ID</th>
                            <th scope="col">Company Name
                                {
                                    (sorting['keyName'] === 'companyName') && (sorting['order'] === 1) ? <Desc className="ascending_icons ms-3" onClick={() => handleSorting('companyName', -1)} />
                                        : <Asc className="ascending_icons ms-3" onClick={() => handleSorting('companyName', 1)} />
                                }
                            </th>
                            <th scope="col">Amt Approved</th>
                            <th scope="col">Approved Date / Time
                                {
                                    (sorting['keyName'] === 'createdDate') && (sorting['order'] === 1) ? <Desc className="ascending_icons ms-3" onClick={() => handleSorting('createdDate', -1)} />
                                        : <Asc className="ascending_icons ms-3" onClick={() => handleSorting('createdDate', 1)} />
                                }
                            </th>
                            <th scope="col">Processing Fee</th>
                            <th scope="col">Adjustable Amt</th>
                            {
                                advanceStatus !== 3 ?
                                    <th scope="col">Due Date</th>
                                    : null
                            }
                            <th scope="col">
                                {
                                    advanceStatus === 3 ?
                                        'Lapsed By' :
                                        'More'
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            apiData?.advanceStatus?.map((employee, index) => {                                
                                return <tr key={index}>
                                    {advanceStatus === 1 ?
                                        <td > <input
                                            type="checkbox"
                                            name={employee?._id}
                                            onChange={() => toggleCheck(employee?._id)} checked={checked[employee?._id]}
                                        /></td>
                                        : null
                                    }

                                    <td scope="row" className="pt-md-4 pt-3">{((index + 1) + ((pageNumber - 1) * limit))}</td>
                                    <td className="pt-md-4 pt-3">{(employee?.userData?.generatedUserId) ? (employee?.userData?.generatedUserId) : '-'}</td>
                                    <td className="pt-md-4 pt-3">{employee?.userData?.firstName} {employee?.userData?.lastName}</td>
                                    <td className="pt-md-4 pt-3">{employee?.userData?.mobileCountryCode} {employee?.userData?.mobile}</td>

                                    <td className="pt-md-4 pt-3">{(employee?.userData?.generatedUserId) ? (employee?.userData?.generatedUserId) : '-'}</td>
                                    <td className="pt-md-4 pt-3">{(employee?.userData?.companyName) ? (employee?.userData?.companyName) : '-'}</td>
                                    <td className="pt-md-4 pt-3">{(employee?.requestedAmount) ? (employee?.requestedAmount) : '-'}</td>
                                    <td className="pt-md-4 pt-3">{moment(employee?.createdAt).format('DD/MM/YYYY')}</td>
                                    <td className="pt-md-4 pt-3">{employee?.interestAmount ? employee?.interestAmount : '-'}</td>
                                    <td className="pt-md-4 pt-3">{((employee?.requestedAmount) ? (employee?.requestedAmount) : 0) + ((employee?.interestAmount) ? (employee?.interestAmount) : 0)}</td>
                                    {
                                        advanceStatus !== 3 ?
                                            <td className="pt-md-4 pt-3">{moment(employee?.createdDate).format('LT')}</td>
                                            : null
                                    }
                                    <td className="pt-md-4 pt-3">
                                        {
                                            advanceStatus === 1
                                                ?
                                                <NavLink to={`/advance-status/${employee?._id}`} className="heighlight">view more</NavLink>
                                                :
                                                advanceStatus === 2 ?
                                                    <span className="">Download Invoice</span> :

                                                    <span className="">12/12/2010</span>

                                        }
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <div className="my-5 d-flex justify-content-center">
                    <ReactPaginate
                        ref={pagination}
                        pageCount={apiData?.total / limit}
                        pageRangeDisplayed={limit}
                        marginPagesDisplayed={1}
                        onPageChange={setPage}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        previousLabel={<>&laquo;</>}
                        nextLabel={<>&raquo;</>}
                    />
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> : ''
            }

            <Modal
                isOpen={isbulkModalToggle} centered size='lg'>
                <ModalHeader toggle={closeAlertModal}>
                   Change Status
                </ModalHeader>
                <ModalBody className="logout-body">
                        <p>Kindly change the Status of the request</p>
                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" 
                            onClick={() => changeStatusInBulk('lapsed')}>
                            Lapsed
                        </Button>
                        <Button
                            className="btn primary-btn min-padding ms-2"
                            onClick={() => changeStatusInBulk('completed')}>
                            Complete
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={() => changeStatusInBulk('completed')}>
                            Cancel
                        </button>
                    </div>
                </ModalBody>

            </Modal>

            <Modal
                isOpen={isDatePickerToggle}>
                <ModalHeader toggle={closeAlertModal}>
                   Due Date
                </ModalHeader>
                <ModalBody className="logout-body">
                        <ReactDatePicker
                            showPopperArrow={false}
                            className={`form-control input-box`}
                            dateFormat="dd-mm-yyyy"
                            value={Cdate}
                            //  selected={Cdate}                          
                            placeholderText="Select Due Date"
                            maxDate={new Date()}
                            showYearDropdown
                            dropdownMode="select"
                            scrollableYearDropdown
                            onChange={selectDate}
                            />
                        <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={saveDueDate}>
                            Complete
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={closeAlertModal }>
                            Cancel
                        </button>
                    </div>
                </ModalBody>

            </Modal>


        </>
    )
}

export default UserAdvanceStatus
