import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useParams, useLocation } from 'react-router-dom'
import { getAllUser, getReferedEmployer, resetEmpPayments } from '../../API/Services/authService'
import { useAlert } from "react-alert";
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Asc } from '../../assets/images/icons/assending.svg';
import { ReactComponent as Desc } from '../../assets/images/icons/decending.svg';
import _ from "lodash";
import moment from 'moment'
import SearchWrapper from '../../Components/Search';
import { RouterConstant } from '../../constants/RouteConstant';
import { CSVLink } from "react-csv";
import { AppContext } from '../../AppContext';
const ReferedEmployer = () => {
    // let { userId } = useParams();
    const pagination = useRef();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [profileType, setProfiteType] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchString, setSearchString] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [profilePercentage, setProfilePercentage] = useState(0);
    const [csvData, setCsvData] = useState([]);
    const { state } = useLocation();
    const { userProfile, userData } = useContext(AppContext);
    const [sorting, setSorting] = useState({
        order: 1,
        keyName: 'createdDate',
    });

    useEffect(() => {
        userProfile()
    }, [])

    useEffect(() => {
        if (csvData.length > 0) {
            const downloadElement = document.getElementById("clickDownload");
            setTimeout(() => {
                downloadElement.click();
                setLoader(false);
                setCsvData([])
            }, 3000)
        }
    }, [csvData]);


    useEffect(() => {
        if (state) {
            const { tabId } = state;
            if (tabId != null) {
                setProfiteType(tabId);
            }
        }


    }, [])
    useEffect(() => {

        getAllReferedEmplopyer();
    }, [profileType, sorting, pageNumber, limit, searchQuery])

    const getAllReferedEmplopyer = () => {
        setLoader(true)
        getReferedEmployer(profileType, sorting, pageNumber, limit, searchString)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data);
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })

    }



    const setPage = ({ selected }) => {
        let pageNo = selected + 1
        setPageNumber(pageNo);
        // getStoreValue(pageNo)
    }

    //  
    const handleSorting = (sortKey, sortOrder) => {
        setSorting({
            order: sortOrder,
            keyName: sortKey
        })
    }

    

    

    

    return (
        <>
            <h3 className='heading'>Refered Employer</h3>
            
            <div className="data-table table-responsive">
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">Company Name</th>
                            <th scope="col">Manager's Name</th>
                            <th scope="col">Manager's Email</th>
                            <th scope="col">Manager's Phone Number</th>
                            <th scope="col">Date</th>
                            <th scope="col">Company Size</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            apiData?.users?.map((user, index) => {

                                return <tr key={index}>
                                    <td scope="row" className="pt-md-4 pt-3">{((index + 1) + ((pageNumber - 1) * limit))}</td>
                                    <td className="pt-md-4 pt-3">{(user?.companyName) ? (user?.companyName) : '-'}</td>
                                    <td className="pt-md-4 pt-3">{(user?.managerName) ? (user?.managerName) : '-'   }</td>
                                    <td className="pt-md-4 pt-3"><p className='text-break'>{user?.managerEmail}</p></td>
                                    <td className="pt-md-4 pt-3">{(user?.managerPhoneNumber? user?.managerPhoneNumber:user?.mobileNo)}</td>
                                    
                                    <td className="pt-md-4 pt-3">{moment(user?.createdDate).format('DD/MM/YYYY')}</td>
                                    <td className="pt-md-4 pt-3">{(user?.companySize)}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <div className="my-5 d-flex justify-content-center">
                    <ReactPaginate
                        ref={pagination}
                        pageCount={apiData?.total / limit}
                        pageRangeDisplayed={limit}
                        marginPagesDisplayed={1}
                        onPageChange={setPage}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        previousLabel={<>&laquo;</>}
                        nextLabel={<>&raquo;</>}
                    />
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> : ''
            }
        </>
    )
}

export default ReferedEmployer
