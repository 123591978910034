import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Auth/Login";
import { RouterConstant } from "./constants/RouteConstant";
import Layout from "./Layout";
import Employees from "./Pages/Employees";
import ViewEmployee from "./Pages/ViewEmployee";
import Overview from "./Pages/Overview";
import RequestMoney from "./Pages/Request";
import ViewRequest from "./Pages/ViewRequest/";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { checkToken } from "./helper/helper";
import EmployerList from "./Pages/Employer/EmployerList";
import AdvanceStatus from "./Pages/AdvanceStatus";
import Payment from "./Pages/Request/Payment";
import AdvanceStatusDetail from "./Pages/AdvanceStatus/AdvanceStatusDetail";
import AdminControls from "./Pages/AdminControls";
import CompanyContractor from "./Pages/AdminControls/CompanyContractor";
import ProcessingFeeControl from "./Pages/AdminControls/ProcessingFeeControl";
import UserAdvanceStatus from "./Pages/AdvanceStatus/UserAdvanceDetail";
import ManageAdmin from "./Pages/ManageAdmin";
import AdminDetailsDetail from "./Pages/ManageAdmin/AdminDetail";
import AdminListPage from "./Pages/ManageAdmin/AdminListPage";
import ReferedEmployer from "./Pages/ReferedEmployer";


const options = {
  timeout: 3000,
  position: positions.BOTTOM_CENTER
};
document.body.style.zoom = 0.78



function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = checkToken();
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

function PublicAuth({ children, redirectTo }) {
  let isAuthenticated = !checkToken();
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}


function App() {
  return (
    
    <Provider template={AlertTemplate} {...options}>
      <Router>
        <Routes>
          <Route path={RouterConstant.Login} element={
            <PublicAuth redirectTo={RouterConstant.Layout}>
              <Login />
            </PublicAuth>
          } />
          <Route path={RouterConstant.Layout} element={
            <RequireAuth redirectTo={RouterConstant.Login}>
              <Layout />
            </RequireAuth>
          }>
            <Route path={RouterConstant.Overview} element={<Overview />} />
            <Route path={RouterConstant.Request} element={<RequestMoney />} />
            <Route path={`${RouterConstant.PaymentDetail}/:requestId`} element={<Payment />} />
            <Route path={RouterConstant.Employees} element={<Employees />} />
            <Route path={`${RouterConstant.ViewEmployee}/:userId`} element={<ViewEmployee />} />
            <Route path={`${RouterConstant.ViewRequest}/:requestId`} element={<ViewRequest />} />
            <Route path={`${RouterConstant.EmployerList}`} element={<EmployerList />} />
            <Route path={RouterConstant.AdvanceStatus} element={<AdvanceStatus />} />
            <Route path={`${RouterConstant.AdvanceStatusUser}/:id`} element={<UserAdvanceStatus />} />
            <Route path={`${RouterConstant.AdvanceStatus}/:advanceId`} element={<AdvanceStatusDetail />} />
            <Route path={`${RouterConstant.AdminControl}`} element={<AdminControls />} />
            <Route path={`${RouterConstant.manageAdmin}`} element={<ManageAdmin />}>
              <Route path={`${RouterConstant.manageAdminList}`} element={<AdminListPage />} />
              <Route path={`${RouterConstant.manageAdminDetails}/:adminId`} element={<AdminDetailsDetail />} />
              <Route path={`${RouterConstant.manageAdminAdd}`} element={<AdminDetailsDetail />} />
            </Route>
            <Route path={`${RouterConstant.CompanyDetail}/:companyName`} element={<CompanyContractor />} />
            <Route path={`${RouterConstant.ProcessingFeeControl}`} element={<ProcessingFeeControl />} />
            <Route path={`${RouterConstant.referedEmployer}`} element={<ReferedEmployer />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
