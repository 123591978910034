import { Spinner } from "reactstrap"
import './style.scss';
const LoaderWrapper = () => {
    return (
        <div className="loader-wrapper">
            <Spinner color="success" />
        </div>
     );
}
 
export default LoaderWrapper;