import setting from './settings';

export default (() => {
  return {

    // Basic Auth
    'AUTH': setting.api.AUTH,

    // Auth
    login: `${setting.api.url}admin/login`,
    forgotPassword: `${setting.api.url}forgetPassword`,
    resetPassword: `${setting.api.url}resetPassword`,

    // all user
    getUserList: `${setting.api.url}user/all`,
    getUser: `${setting.api.url}user/details`,
    changeEmployeeStatus: `${setting.api.url}user/changeStatus`,
    updateEmployee: `${setting.api.url}user/edit`,
    addCommentOnSection: `${setting.api.url}user/addCommentOnSection`,
    approveRejectUserFields: `${setting.api.url}user/approveRejectFields`,
    approveKycVerification: `${setting.api.url}user/approveKycVerification`,
    sendKycVerification:`${setting.api.url}user/sendKycVerification`,
    validateNIN:`${setting.api.url}user/validateNIN`,
    updateUserNINVerification:`${setting.api.url}user/updateUserNINVerification`,
    rejectKycVerification:`${setting.api.url}user/rejectKycVerification`,
    paystackBankAccountList:`${setting.api.url}user/paystackBankAccountList`,
    bankAccountVerification:`${setting.api.url}user/bankAccountVerification`,
    updateUserBankAccountVerification:`${setting.api.url}user/updateUserBankAccountVerification`,
    companyNameList:`${setting.api.url}user/companyNameList`,
    getCompanyContractorSite:`${setting.api.url}user/getCompanyContractorSite`,
    paymentByCash : `${setting.api.url}user/paymentByCash`,
    
    // get all request
    getAllRequest : `${setting.api.url}request/all`,
    getRequestDetail : `${setting.api.url}request/details`,
    changeRequestStatus: `${setting.api.url}request/changeStatus`,
    changeAmountRequest: `${setting.api.url}request/updateRequestedAmount`,
    changeStatusBulk:`${setting.api.url}request/changeStatusBulk`,

    // individual request
    getAllUserRequest : `${setting.api.url}user/allRequestsOfUser`,
   
    deleteUserRequest: `${setting.api.url}user/deleteUser`,
   
    
    // employer list
    getEmployerList :`${setting.api.url}employer/list`,
    resetEmpPayment :`${setting.api.url}user/reset-payments`,

    // advance Status
    advanceStatusList :`${setting.api.url}user/advanceStatusList`,
    allUserAdvanceStatusList :`${setting.api.url}user/advanceStatusListOfUser`,
    // admin controls
    createCompanyName :`${setting.api.url}user/createCompanyName`,
    addCompanyContractors :`${setting.api.url}user/addCompanyContractors`,
    addCompanySite :`${setting.api.url}user/addCompanySite`,
    companyList :`${setting.api.url}user/companyNameList`,
    deleteCompany :`${setting.api.url}user/deleteCompany`,
    deleteCompanyContractors :`${setting.api.url}user/deleteCompanyContractors`,
    deleteCompanySite :`${setting.api.url}user/deleteCompanySite`,
    updateCompanyContractors :`${setting.api.url}user/updateCompanyContractors`,
    updateCompanySite :`${setting.api.url}user/updateCompanySite`,
    updateCompanyName :`${setting.api.url}user/updateCompanyName`,
    getDefaultProcessingFee:`${setting.api.url}user/getDefaultProcessingFee`,
    updateDefaultProcessingFee:`${setting.api.url}user/updateDefaultProcessingFee`,
    paystackPaymentByAdmin:`${setting.api.url}user/paystackPaymentByAdmin`,
    
    changeProfileStatusFromCompleted:`${setting.api.url}user/changeProfileStatusFromCompleted`,
    // admin permission and listing list-sub-admins
    listSubAdmin: `${setting.api.url}user/list-sub-admins`,
    updateSubAdmin: `${setting.api.url}user/updateSubAdmin`,
    addSubAdmin: `${setting.api.url}user/add-subadmin`,
    adminProfile: `${setting.api.url}user/adminProfile`,

    // refer your employer
    getReferedEmployer: `${setting.api.url}user/listRefer`,
    
  }
})()