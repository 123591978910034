import React, { useState } from 'react';
import PdfPopUp from "../../Components/PdfPopUp/PdfPopUp";
import { ReactComponent as CommentIcon } from '../../assets/images/icons/comment_icon.svg';
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg';
import { ReactComponent as AcceptIcon } from '../../assets/images/icons/accept.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/icons/reject.svg';
import { ReactComponent as RejectedIcon } from '../../assets/images/icons/rejected.svg'


const KYCDetail = ({ data, apiresponse, rejectBasicDetail, acceptBasicDetail, addNote, toggleField, toggle, toggleFieldStatus, verifyNin }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [viewFile, setViewFile] = useState('')
    const handlePdfPopUp = (document) => {
        setIsOpen(!isOpen);
        setViewFile(document)
    }
    return (
        <>

            <div className="profile-section-card">
                <div className="head-wrap">
                    <h3 className="heading">KYC Detail
                        {/* <span className={`badge ms-3 badge-msg ${data?.status === -1 ? "bg-danger" : data?.status === 2 ? "bg-success" : ''}`}>
                            {`${data?.status === -1 ? "Rejected" : data?.status === 2 ? "Approved" : ''}`}
                        </span> */}
                    </h3>
                    <ul className="head-action">
                        {
                            // data?.status !== 0 ?
                            // <li className="admin-action-wrap accept" onClick={() => toggle({ "action": 'accept', 'type': 'kycDetails' })}>
                            //     <i className="fa fa-check text-success"></i>
                            // </li>
                            // : null
                        }
                        <li className="admin-action-wrap cursor-pointer accept" onClick={() => toggle({ "action": 'Add Comment', 'type': 'kycDetails' })}>
                            <CommentIcon className="" onClick={addNote} />
                            <span className='ms-3'>Add Comment</span>
                        </li>
                        {/* <li className="accet-reject" onClick={addNote}>
                        <i className="fa fa-envelope text-primary"></i>
                    </li> */}
                    </ul>
                </div>
                <div className="detail-field">
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">NIN</label>
                            <p className="value">{data?.nin}</p>
                        </div>

                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.nin != 2 ?
                                <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "nin", "fieldData": data?.nin, "feildType": "number", "fieldValidation": "Field is required", "fieldLabel": "NIN", "required": true })}>
                                    <EditIcon className="admin-action" />
                                    <label className="label-key">Edit</label>
                                </div> : ''
                        }



                        {
                            apiresponse?.fieldStatus?.nin == 2 ?
                                <>
                                    <div className="admin-action-wrap accepted" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.nin == 3 ?
                                    <>
                                        <div className="admin-action-wrap rejected" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            data?.nin &&
                        <>
                       
                       {
                           // apiresponse?.isSendForKYCApproval == 2 && apiresponse?.kycDetails?.dojahValidationDone==1?
                           apiresponse?.isSendForKYCApproval == 2 ?
                               <>
                                   {
                                       apiresponse?.fieldStatus?.nin != 2 ?
                                           <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'nin' })}>
                                               <AcceptIcon className="admin-action" />
                                               <label className="label-key">Approve</label>
                                           </div>
                                           : null
                                   }
                                   {
                                       apiresponse?.fieldStatus?.nin == 1 ?
                                           <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'nin' })}>
                                               <RejectIcon className="admin-action" />
                                               <label className="label-key">Reject</label>
                                           </div>
                                           : null
                                   }


                               </>
                               : null
                       }
                        </>
                        }

                    </div>


                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Valid ID</label>
                            {
                                data?.validId
                                    ?
                                    <p title="View Document" className="value text-truncate pe-4 cusror-pointer" onClick={() => handlePdfPopUp(data?.validId)}><i class="fa fa-file-text me-2" aria-hidden="true" />View Document</p> :
                                    <p title="View Document" className="value text-truncate pe-4 cusror-pointer">N/A</p>
                            }
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.validId != 2 ?
                                <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "validId", "fieldData": data?.validId, "feildType": "file", "fieldValidation": "Field is required", "fieldLabel": "Valid Id" })}>
                                    <EditIcon className="admin-action" />
                                    <label className="label-key">Edit</label>
                                </div>
                                : null
                        }
                        {
                            data?.validId &&
                        <>
                        {
                            apiresponse?.fieldStatus?.validId == 2 ?
                                <>
                                    <div className="admin-action-wrap accepted" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.validId == 3 ?
                                    <>
                                        <div className="admin-action-wrap rejected" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        </>
                        }


                        {
                            // apiresponse?.isSendForKYCApproval == 2 && apiresponse?.kycDetails?.dojahValidationDone==1?
                            apiresponse?.isSendForKYCApproval == 2 ?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.validId != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'validId' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.validId == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'validId' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }


                                </>
                                : null
                        }

                    </div>

                </div>
                {/* code of text area */}

            </div>
            {
                data?.comment && apiresponse?.isSendForKYCApproval == 1 || apiresponse?.isSendForKYCApproval == 3 ?
                    <div className="commented-profile">
                        <textarea readOnly="true" value={(data?.comment) ? (data?.comment) : ''} />
                    </div>
                    : null
            }

            <PdfPopUp
                isOpen={isOpen}
                toggle={handlePdfPopUp}
                file={viewFile}
            />
        </>
    );
}

export default KYCDetail;