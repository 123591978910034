import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useParams, useNavigate, Link } from 'react-router-dom'
import {
    deleteUserRequest, changeProfileStatusFromCompleted, getUserDetail, addCommentOnSection, approveRejectUserFields, sendKycVerification, rejectKycVerification, approveKycVerification,
    validateNinCode, updateUserNINVerification, bankAccountVerification, updateUserBankAccountVerification
} from '../../API/Services/authService'
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper'
import BankDetail from './BankDetail'
import BasicDatail from './BasicDetail'
import CompanyDetail from './CompanyDetail'
import EmployeeStatus from './HelperComponent/EmployeeStatus'
import KYCDetail from './KYCDetail'
import { useAlert } from 'react-alert';
import UpdateDetail from './HelperComponent/UpdateDetail'
import ApproveLimitDetails from './ApproveLimitDetails';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import FormFieldStatus from './HelperComponent/FormFieldStatus';
import Confirmation from '../../Components/Confirmation'
import { RouterConstant } from '../../constants/RouteConstant';
import { ReactComponent as DropDownArrow } from './../../assets/images/icons/dropdown_icon.svg'
import _ from "lodash";
import moment from 'moment';
import { AppContext } from '../../AppContext'



const ViewEmployee = () => {
    let navigate = useNavigate()
    let { userId } = useParams();
    const [loader, setLoader] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [apiData, setApiData] = useState({});
    const [adminUserData, setUserData] = useState({})
    const [fieldData, setFieldData] = useState({})
    const [activateField, setActivateField] = useState(false)
    const alert = useAlert();
    const [openDeleteUser, setDeletUser] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [isOpenFieldModal, setIsOpenFieldStatus] = useState(false);
    const [isSendKycAlertModal, setIsSendKycBtnStatus] = useState(false);
    const [isAcceptKycModal, setIsAcceptKycModal] = useState(false);
    const [isAcceptedKeysvalidationModal, setIsAcceptValidationModal] = useState(false);
    const [isAccountLimitModal, setisAccountLimitModal] = useState(false);
    const [isKeyRejectModal, setIsKycRejectStatus] = useState(false);
    const [isNinDisplay, setNinDisplayModal] = useState(false);
    const [ninApiData, setNinApiresponse] = useState({});
    const [isNinValidated, setisNinValidated] = useState(false);
    const [ninMessage, setNinMessageDeatils] = useState({});
    const [isBankDetailsModal, setBankDetailsModal] = useState(false);
    const [bankApiData, setBankDeatilsApiData] = useState({});
    const [keyBlankMsg, setKeyBlankMsg] = useState({});
    const [profilePercentage, setProfilePercentage] = useState(0)
    const [isSuperAdmin, setIsSuperAdmin] = useState(true);
    const [profileType, setProfileType] = useState(0);
    const { userData } = useContext(AppContext);
    // useEffect(()=>{
    //     userProfile()
    // },[])
    const handleToggleProfileStatus = (data) => {

        setUserData(data);
        setIsOpen(!isOpen)
    }

    // for Field Status
    const handleToggleFieldStatus = (data) => {
        if (data?.keyName === 'nin') {
            setUserData(data);
            setIsOpenFieldStatus(!isOpenFieldModal)
            
        } else if (data?.keyName === 'accountNo' || data?.keyName === 'bankName' || data?.keyName === 'accountHolderName') {
            if (apiData?.bankDetails?.paystackBankAccountValidationDone == 1) {
                setUserData(data);
                setIsOpenFieldStatus(!isOpenFieldModal);
            } else if (apiData?.bankDetails?.paystackBankAccountValidationDone == 2) {
                let msg = { warning: "Bank Verification Rejected", msg: 'Your Bank Verification has been Rejected' };
                setNinMessageDeatils(msg);
                setisNinValidated(!isNinValidated);
            } else if (apiData?.bankDetails?.paystackBankAccountValidationDone == 0) {
                let msg = { warning: "Verification pending", msg: 'Your Bank Verification not completed' };
                setNinMessageDeatils(msg);
                setisNinValidated(!isNinValidated);
            }
        }
        else {
            setUserData(data);
            setIsOpenFieldStatus(!isOpenFieldModal)
        }

    }



    // get user Detail
    useEffect(() => {
        getAllUserList();
    }, [])

    // submit field to be updated
    const updateField = (popupStatus) => {
        setActivateField(popupStatus)
        getAllUserList();
    }

    const toggleField = (data) => {
        if (!activateField) {
            setFieldData(data);
        }
        setActivateField(!activateField)
    }

    // user status
    const updateStatus = (data, e) => {
        e.target.reset();
        let param = {
            userId: userId,
            commentKey: adminUserData?.type,
            comment: data?.comment
        }
        addCommentOnSection(param)
            .then(res => {
                if (res?.statusText === 'OK') {
                    getAllUserList();
                    setIsOpen(false);
                    alert.success(res?.data?.message);
                }

            })
            .catch(err => {
                console.log(err)
                alert.error(err?.message)
            })
    }

    // Update Field Status 
    const updateFieldStatus = (data) => {
        setLoader(true)
        let param = {
            'action': adminUserData?.action,
            'keyName': adminUserData?.keyName,
            'userId': userId
        };
        approveRejectUserFields(param)
        .then((res) => {
            if (res?.statusText === 'OK') {
                getAllUserList();
                setIsOpenFieldStatus(false);
                alert.success(res?.data?.message);
                setLoader(false)
            }

        }).catch((errr) => {
            console.log(errr);
            alert.error(errr?.message);
        })
    }

    const getAllUserList = () => {
        setLoader(true);
        getUserDetail(userId)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data?.user)
                    profilePer(response?.data?.data?.user)
                    let tempPercentage = Object.values(response?.data?.data?.user.fieldStatus);
                    let counts = {}
                    for (const num of tempPercentage) {
                        counts[num] = counts[num] ? counts[num] + 1 : 1;
                    }
                    counts[2] = counts[2] ? counts[2] : 0
                    // setProfilePercentage((counts[2] / tempPercentage.length) * 100)
                    // alert.success(response?.data?.isError);
                    // navigate(RouterConstant.Layout);

                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })

    }

    const profilePer = (resp) => {
        let totalKeys = ['firstName', 'lastName', 'mobile', 'gender', 'dob', 'companyName', 'salaryDueDate', 'nin', 'validId', 'bankName', 'accountNo',
            'accountHolderName', 'salary', 'salarycreditDay', 'bankStatements', 'accountLimit', 'contractor', 'site'];
        let count = 0;
        totalKeys.map((item, index) => {
            if (resp[item]) {
                count++;
            } else if (resp.kycDetails && resp.kycDetails[item]) {
                count++;
            } else if (resp.bankDetails && resp.bankDetails[item]) {
                count++;
            } else if (resp.workDetails && resp.workDetails[item]) {
                count++;
            }
        })
        setProfilePercentage((count / totalKeys.length) * 100)
    }

    // open delete user
    const handleToggleDelete = (tabId) => {
        setDeletUser(!openDeleteUser);
        setProfileType(tabId)
    }

    const deleteUser = (tabId) => {
        setSelectedUserId(apiData?._id);
        setLoader(true);
        let param = {
            "userId": apiData?._id
        }
        deleteUserRequest(param).then(response => {
            if (response?.data?.status === 'OK') {
                setDeletUser(false);
                if (tabId === 4) {
                    navigate(RouterConstant.Employees, { state: { tabId: 4 } });
                } else {
                    navigate(RouterConstant.Employees, { state: { tabId: 3 } });
                }
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })

    }

    const handleCloseAcceptProfile =()=>{
        setIsAcceptKycModal(false)
    }

    

    // validate Keys before  KYC Approval
    const approvalKeysValidation = (data) => {
        let values = Object.values(data['fieldStatus']);
        const newArr = [];
       
        for (let i = 0; i < values.length; i++) {
            if (values[i] === 1) {
                newArr.push(values[i]);
            }
        }
        if(_.isEmpty(data?.workDetail?.employeeId) && data?.fieldStatus?.employeeId === 1){
            newArr.pop();
        }
        if(_.isEmpty(data?.workDetail?.workEmail) && data?.fieldStatus?.workEmail === 1){
            newArr.pop();
        }
        if(_.isEmpty(data?.kycDetails?.nin) && data?.fieldStatus?.nin === 1){
            newArr.pop();
        }
        if(_.isEmpty(data?.kycDetails?.validId) && data?.fieldStatus?.validId === 1){
            newArr.pop();
        }
        if (newArr.length == 0) {
            setIsAcceptValidationModal(false);
            setIsAcceptKycModal(!isAcceptKycModal);
        }
        if(newArr.length > 0 ){
            alert.info('Please Approved All the fields');
        }
    }

    const sendForKyc = (data) => {
        if (apiData?.isSendForKYCApproval == 1 || apiData?.isSendForKYCApproval == 2 || apiData?.isSendForKYCApproval == 3) {
            let accountLimit = apiData['accountLimit'];
            let salaryDate = apiData['salaryDueDate'];
            if ((accountLimit == 0 || accountLimit == '') || !salaryDate) {
                let msg = { header: "Account Limit", msg: "You have not set the account limit" };
                setKeyBlankMsg(msg);
                setisAccountLimitModal(!isAccountLimitModal);
            } else {
                if (!apiData?.kycDetails?.validId && !apiData?.kycDetails?.nin) {
                    let msg = { header: "Blank Details", msg: "NIN details or Valid ID is required." };
                    setKeyBlankMsg(msg);
                    setisAccountLimitModal(!isAccountLimitModal);
                } else {
                    console.log('apiData', !apiData?.workDetails?.salarycreditDay)
                    if (!apiData?.firstName || !apiData?.lastName || !apiData?.mobile || !apiData?.gender ||
                        !apiData?.dob || !apiData?.companyName || !apiData?.workDetails?.contractor || !apiData?.workDetails?.site ||
                        !apiData?.workDetails?.salary || !apiData?.workDetails?.salarycreditDay ||
                        !apiData?.bankDetails?.accountNo || !apiData?.bankDetails?.bankName ||
                        !apiData?.bankDetails?.accountHolderName) {

                        let msg = { header: "Blank Details", msg: "You have not filled all the details" };
                        setKeyBlankMsg(msg);
                        setisAccountLimitModal(!isAccountLimitModal);
                    } else {
                        setIsSendKycBtnStatus(!isSendKycAlertModal);

                    }
                }



            }

            // setIsSendKycBtnStatus(true)
        }
        // kycBtnStatus(apiData);
    }
    const closeAlertModal = () => {
        setIsAcceptValidationModal(false);
        setisAccountLimitModal(false);
        setIsKycRejectStatus(false);
        setisNinValidated(false);
        setIsSendKycBtnStatus(false);
    }

    const sendForKycApprove = () => {
        let param = { "userId": userId };
        setLoader(true);
        sendKycVerification(param)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    // getAllUserList();
                    navigate(RouterConstant.Employees, { state: { tabId: 1 } });
                    setIsSendKycBtnStatus(false);
                    // setApiData(response?.data?.data?.user)
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false);
            })
    }

    //  code for Accept Kyc Confirmation
    const acceptProfileComfirmation = () => {
        approvalKeysValidation(apiData);
    }

    const acceptuserProfile = () => {
        let params = { "userId": userId }
        setIsAcceptKycModal(false);
        approveKycVerification(params).then(response => {
            if (response?.data?.status === 'OK') {
                navigate(RouterConstant.Employees, { state: { tabId: 2 } });
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })
    }

    

    // confirm Nin Verify
    const confirmNinSubmit = () => {
        let param = {
            "status": 1,
            "userId": userId
        };
        setLoader(true);
        updateUserNINVerification(param).then((response) => {
            if (response?.data?.status === 'OK') {
                setNinDisplayModal(false);
                getAllUserList();
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false);
            })
    }

    // Validate NIn APi call
    const valdiateNinApi = () => {
        let param = {
            "nin": apiData?.kycDetails?.nin
        }
        validateNinCode(param).
            then((response) => {
                if (response?.data?.status === 'OK') {
                    setNinDisplayModal(!isNinDisplay);
                    setNinApiresponse(response?.data?.data?.user);
                } else {
                    console.log("test login api", response)
                }
                // setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
            })
    }

    //  Reject Nin Api 
    const rejectNin = () => {
        let param = {
            "userId": userId,
            "status": 2
        }
        updateUserNINVerification(param).
            then((response) => {
                if (response?.data?.status === 'OK') {
                    setNinDisplayModal(false);
                    getAllUserList();
                } else {
                    console.log("test login api", response)
                }
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);

            })
    }

    // Reject Kyc Verification Code
    const rejectKycConfirmation = () => {
        setIsKycRejectStatus(!isKeyRejectModal);
    }
    const confirmedKycReject = () => {
        let param = { "userId": userId };
        setIsKycRejectStatus(false);
        rejectKycVerification(param).then((response) => {
            if (response?.data?.status === 'OK') {
                navigate(RouterConstant.Employees, { state: { tabId: 2 } });
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false);
            })

    }

    // Verify Bank 
    const verifyBank = (data) => {
        let params = {
            accountNo: apiData?.bankDetails?.accountNo,
            bankCode: apiData?.bankDetails?.bankCode
            // "accountNo": "2238803428",
            // "bankCode": "033"
        }
        setLoader(true);
        bankAccountVerification(params).then((response) => {
            if (response?.data?.status === 'OK') {
                setBankDeatilsApiData(response?.data?.data?.data)
                setBankDetailsModal(!isBankDetailsModal);
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false);
            })
    }

    const confirmBankVerification = () => {
        let params = {
            "userId": userId,
            "status": "1"
        }
        setLoader(true);
        updateUserBankAccountVerification(params).then((response) => {
            if (response?.data?.status === 'OK') {
                getAllUserList();
                setBankDetailsModal(false);
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        }).catch(error => {
            console.log("catch block", error)
            alert.error(error?.message);
            setLoader(false);
        })
    }

    const rejectBankVerification = () => {
        let params = {
            "userId": userId,
            "status": "2"
        }
        setBankDetailsModal(false);
        setLoader(true);
        updateUserBankAccountVerification(params).then((response) => {
            if (response?.data?.status === 'OK') {
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        }).catch(error => {
            console.log("catch block", error)
            alert.error(error?.message);
            setLoader(false);
        })
    }

    const handleStatus = (e) => {
        const status = e.target.value
        setProfileType(4);
        console.log('status', status)
        const param = {
            userId: userId,
            status: status
        }
        changeProfileStatusFromCompleted(param)
            .then(res => {
                if (res?.statusText === 'OK') {
                    alert.success(res?.data?.message);
                    navigate(RouterConstant.Employees, { state: { tabId: 4 } });
                    // navigate(RouterConstant.Employees)
                }
            })
            .catch(err => {
                console.log(err)
                alert.error(err?.message)
            })
    }


    // const updatedByAdmin = (isSendForKYCApproval)=>{
    //     switch(isSendForKYCApproval){
    //         case 
    //     }
    // }

    console.log('apoi=====>>>>>>', apiData)

    return (
        <>
            <div className="view-details-wrapper">
                <div className="profile-header">
                    <h4 className="view-details-heading text-capitalize">{apiData?.firstName} {apiData?.lastName} <span> User ID: {apiData?.generatedUserId}</span></h4>
                    <div className="profile-completition">
                        <span className="percent">0%</span>
                        <span className="percent-progress">
                            <span className="progress-bg" style={{ width: `${profilePercentage}%` }}></span>
                        </span>
                        <span className="percent">100%</span>
                    </div>
                    {
                        apiData?.isSendForKYCApproval == 3 ?
                            <h4 className="view-details-updated-by" >Rejected By <span>{(apiData?.rejectedUserData?.fullName) ? apiData?.rejectedUserData?.fullName : 'NA'}</span></h4>
                            :
                            <h4 className="view-details-updated-by" >Updated By <span>{(apiData?.updatedUserData?.fullName) ? apiData?.updatedUserData?.fullName : 'NA'}</span></h4>
                    }
                    {
                        apiData?.isSendForKYCApproval == 4 ?
                            <h4 className="view-details-updated-by">Approved By <span>{(apiData?.approvedUserData?.fullName) ? apiData?.approvedUserData?.fullName : 'NA'}</span></h4>
                            :
                            null
                    }

                </div>
                {
                    (apiData?.isSendForKYCApproval === 4 && isSuperAdmin) &&
                    <div className="complete-profile-extra-action">
                        <div className="list-item">
                            <Link
                                to={`${RouterConstant.AdvanceStatusUser}/${apiData?._id}`}
                                state={{ userId: apiData?._id }}
                                className='btn btn-width primary-btn'
                            >Advance Status</Link>
                        </div>
                        <div className="list-item">
                            <Link
                                to={`${RouterConstant.Request}`}
                                state={{ userId: apiData?._id }}
                                className='btn btn-width primary-btn '
                            >User Request</Link>
                        </div>
                        <div className="list-item">
                            <button className='btn btn-width danger-btn ' onClick={()=>handleToggleDelete(3)}>Delete User</button>
                        </div>

                        <div className="list-item">
                            <DropDownArrow className="dropdown-arrow" />
                            <select className="form-control input-box" onChange={handleStatus}>
                                <option value="" selected disabled>Change Status</option>
                                <option value="1">Incomplete</option>
                                <option value="2">Pending</option>
                                <option value="3">Rejected</option>
                                {/* <option value="completed">Completed</option> */}
                            </select>
                        </div>

                    </div>
                }

                <div className="row gx-5">
                    <div className="col-md-6 mb-4">
                        <BasicDatail
                            data={apiData}
                            toggle={handleToggleProfileStatus}
                            toggleField={toggleField}
                            toggleFieldStatus={handleToggleFieldStatus}
                        />
                    </div>

                    <div className="col-md-6 mb-4">
                        <CompanyDetail
                            data={apiData?.workDetails}
                            apiresponse={apiData}
                            toggle={handleToggleProfileStatus}
                            toggleField={toggleField}
                            toggleFieldStatus={handleToggleFieldStatus}
                        />
                    </div>

                    <div className="col-md-6 mb-4">
                        <BankDetail
                            data={apiData?.bankDetails}
                            apiresponse={apiData}
                            toggle={handleToggleProfileStatus}
                            toggleField={toggleField}
                            toggleFieldStatus={handleToggleFieldStatus}
                            verifyBank={verifyBank}
                        />
                    </div>

                    <div className="col-md-6 mb-4">
                        <KYCDetail
                            data={apiData?.kycDetails}
                            apiresponse={apiData}
                            toggle={handleToggleProfileStatus}
                            toggleField={toggleField}
                            toggleFieldStatus={handleToggleFieldStatus}
                        // verifyNin={verifyNin}
                        />
                    </div>
                    {
                        apiData?.workDetails?.salary ?
                            <div className="col-md-6 mb-4">
                                <ApproveLimitDetails
                                    data={apiData}
                                    apiresponse={apiData}
                                    toggle={handleToggleProfileStatus}
                                    toggleField={toggleField}
                                    toggleFieldStatus={handleToggleFieldStatus}
                                />
                            </div>
                            : null
                    }

                </div>

                <div className="d-flex align-items-center justify-content-between mt-5">
                    {/* {
                        apiData?.isSendForKYCApproval != 1 ?
                            <>
                                <div>
                                    <button className='btn btn-width primary-black'>Send for HR Approval</button>
                                </div>
                            </>
                            : null
                    } */}
                    {
                        apiData?.isSendForKYCApproval == 1 || apiData?.isSendForKYCApproval == 3 ?
                            <>
                                <div>
                                    <button className='btn btn-width primary-btn ' onClick={sendForKyc}>Send For KYC Approval</button>
                                </div>
                            </>
                            : apiData?.isSendForKYCApproval == 2 ?
                                <>
                                    <div>
                                        <button className='btn btn-width primary-btn ' onClick={acceptProfileComfirmation} >Accept</button>
                                    </div>
                                </>
                                : null
                    }

                    {
                        apiData?.isSendForKYCApproval == 2 ?
                            <>
                                <div>
                                    <button className='btn btn-width white-btn bordered-btn' onClick={rejectKycConfirmation}>Reject</button>
                                </div>
                            </>
                            : null

                    }

                    {apiData?.isSendForKYCApproval == 3 ?
                        <>
                            <div>
                                <button className="btn white-btn btn-width bordered-btn" onClick={()=>handleToggleDelete(3)}>Delete User</button>
                            </div>
                        </>
                        : null
                    }
                </div>
            </div>
            {
                loader ? <LoaderWrapper /> : ''
            }

            {/* employee status */}
            <EmployeeStatus
                isOpen={isOpen}
                toggleProfileStatus={handleToggleProfileStatus}
                data={adminUserData}
                updateStatus={updateStatus}
            />

            {/*Approve and Reject fields*/}
            <FormFieldStatus
                isOpen={isOpenFieldModal}
                toggleFieldStatus={handleToggleFieldStatus}
                data={adminUserData}
                submitFieldStatus={updateFieldStatus}
            />

            {/* update employee */}
            <UpdateDetail
                state={activateField}
                toggleField={toggleField}
                data={fieldData}
                updateField={updateField}
                apiData={apiData}
            />




            {/* DELETE USER TODO DESIGN */}
            <Modal
                isOpen={openDeleteUser}
                toggle={handleToggleDelete} centered>
                <ModalHeader toggle={handleToggleDelete}>
                    Delete user
                </ModalHeader>
                <ModalBody className="logout-body">
                    <p>Are you sure you want delete this user?</p>
                    <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn primary-btn min-padding ms-2"
                            onClick={deleteUser}
                        >
                            Yes
                        </Button>

                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={handleToggleDelete}
                        >
                            Cancel
                        </button>
                    </div>

                </ModalBody>

            </Modal>

            {/* SEND FOR KYC APPROVAL TODO DESIGN */}
            <Modal
                isOpen={isSendKycAlertModal} centered>
                <ModalHeader toggle={closeAlertModal}>
                    Send For KYC Approval
                </ModalHeader>
                <ModalBody className="logout-body">
                    <p>Are you sure you want to send
                        this profile for approval?</p>

                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={sendForKycApprove}>
                            Yes
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={closeAlertModal}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            {/* ACCEPT KYC TODO DESIGN */}
            <Modal
                isOpen={isAcceptKycModal}
                centered
                toggle={acceptProfileComfirmation}>
                <ModalHeader toggle={handleCloseAcceptProfile}>
                    Accept Profile
                </ModalHeader>
                <ModalBody className="logout-body">
                    <p>Are you sure you want to accept this profile?</p>

                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={acceptuserProfile} >
                            Yes
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={handleCloseAcceptProfile}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            {/* APPROVED ALL FIELD TODO DESIGN */}
            <Modal
                isOpen={isAcceptedKeysvalidationModal} centered>
                <ModalHeader toggle={closeAlertModal}>
                    Approve All field
                </ModalHeader>
                <ModalBody className="logout-body">
                    <p>Please approve all the sections for approving the profile</p>
                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={closeAlertModal}>
                            Close
                        </Button>
                    </div>
                </ModalBody>

            </Modal>

            {/* ACCOUNT LIMIT TODO DESIGN */}
            <Modal
                isOpen={isAccountLimitModal} centered>
                <ModalHeader toggle={closeAlertModal}>
                    {keyBlankMsg?.header}
                </ModalHeader>
                <ModalBody className="logout-body">
                    <p>{keyBlankMsg?.msg}</p>
                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={closeAlertModal}>
                            Close
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={closeAlertModal}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalBody>

            </Modal>

            {/* KYC REJECT TODO DESIGN */}
            <Modal
                isOpen={isKeyRejectModal} centered>
                <ModalHeader toggle={closeAlertModal}>
                    Kyc Verification Reject
                </ModalHeader>
                <ModalBody className="logout-body">
                    <p>Are you sure you want to reject this Kyc Verification Request?</p>
                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={confirmedKycReject}>
                            Yes
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={closeAlertModal}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            {/* NIN CONFIRMATION TODO DESIGN */}
            <Modal isOpen={isNinDisplay} centered size='lg' toggle={() => setNinDisplayModal(false)}>
                <ModalHeader >
                    Nin Confirmation
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div className='px-md-4'>

                        <div className='row border mb-2 py-2'>
                            <div className='col-4'>
                                <strong></strong>
                            </div>
                            <div className='col-4'>
                                <strong>User Input</strong>
                            </div>
                            <div className='col-4'>
                                <strong>KYC Verification Data</strong>
                            </div>
                        </div>
                        <div className='row py-2'>
                            <div className='col-4'>
                                <strong>First name</strong>
                            </div>
                            <div className='col-4'>
                                {apiData?.firstName}
                            </div>
                            <div className='col-4'>{ninApiData?.entity?.firstname}</div>
                        </div>
                        <div className='row py-2'>
                            <div className='col-4'>
                                <strong>Last Name</strong>
                            </div>
                            <div className='col-4'>
                                {apiData?.lastName}
                            </div>
                            <div className='col-4'>{ninApiData?.entity?.lastName}</div>
                        </div>
                        <div className='row py-3'>
                            <div className='col-4'>
                                <strong>Gender</strong>
                            </div>
                            <div className='col-4'>
                                {apiData?.gender}
                            </div>
                            <div className='col-4'>{ninApiData?.entity?.gender}</div>
                        </div>
                        <div className='row py-3'>
                            <div className='col-4'>
                                <strong>Date of Birth</strong>
                            </div>
                            <div className='col-4'>
                                {apiData.dob ? moment(apiData.dob).format('DD/MM/YYYY') : 'N/A'}
                            </div>
                            <div className='col-4'>{moment(ninApiData?.entity?.dob).format('DD/MM/YYYY')}</div>
                        </div>
                        <div className='row py-3 mb-5'>
                            <div className='col-4'>
                                <strong>Phone Number</strong>
                            </div>
                            <div className='col-4'>
                                +234 {apiData?.mobile}
                            </div>
                            <div className='col-4'>{ninApiData?.entity?.mobile}</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={confirmNinSubmit}>
                            Accept
                        </Button>
                        <Button
                            className="btn danger-btn min-padding ms-2" onClick={rejectNin} >
                            Reject
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={() => setNinDisplayModal(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            {/* NIN TODO DESIGN */}
            <Modal
                isOpen={isNinValidated} centered>
                <ModalHeader toggle={closeAlertModal}>
                    {ninMessage?.warning}
                </ModalHeader>
                <ModalBody className="logout-body">
                    <p>{ninMessage?.msg}</p>
                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn secondary-btn min-padding ms-2" onClick={closeAlertModal}>
                            Close
                        </Button>
                    </div>
                </ModalBody>

            </Modal>

            {/* BANK DETAIL TODO DESIGN */}
            <Modal isOpen={isBankDetailsModal} centered size='lg' toggle={() => setBankDetailsModal(false)}>
                <ModalHeader>
                    Bank Details
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div className='row border mb-2 py-2'>
                        <div className='col-4'>
                            <strong></strong>
                        </div>
                        <div className='col-4'>
                            <strong>User Input</strong>
                        </div>
                        <div className='col-4'>
                            <strong>Bank Verification Data</strong>
                        </div>
                    </div>
                    <div className='row py-2'>
                        <div className='col-4'>
                            <strong>Account Holder Name</strong>
                        </div>
                        <div className='col-4'>
                            {apiData?.bankDetails?.accountHolderName}
                        </div>
                        <div className='col-4'>{bankApiData?.account_name}</div>
                    </div>
                    <div className='row py-2'>
                        <div className='col-4'>
                            <strong>Bank Name</strong>
                        </div>
                        <div className='col-4'>
                            {apiData?.bankDetails?.bankName}
                        </div>
                        <div className='col-4'>{bankApiData?.bank_id}</div>
                    </div>
                    <div className='row py-3 mb-5'>
                        <div className='col-4'>
                            <strong>Account Number</strong>
                        </div>
                        <div className='col-4'>
                            {apiData?.bankDetails?.accountNo}
                        </div>
                        <div className='col-4'>{bankApiData?.account_number}</div>
                    </div>

                    <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={confirmBankVerification}>
                            Accept
                        </Button>
                        <Button
                            className="btn danger-btn min-padding ms-2" onClick={rejectBankVerification} >
                            Reject
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={() => setBankDetailsModal(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            {/* Confirmation */}
            {/* <Confirmation
            isOpen={}
            toggle={}
            btnText={}
            handleConfirmation={}
            heading={}
            >
                <p>Are u sure want to</p>
            </Confirmation> */}

        </>
    )
}

export default ViewEmployee
