import React, { useContext, useEffect, useRef, useState } from 'react'
import './request.scss'
import { NavLink } from 'react-router-dom'
import { getAllCompanyList, getRequest, saveCompanyName, deleteCompany,updateCompanyName } from '../../API/Services/authService';
import { useAlert } from "react-alert";
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
// import ReactPaginate from 'react-paginate';
// import { ReactComponent as Asc } from '../../assets/images/icons/assending.svg';
// import { ReactComponent as Desc } from '../../assets/images/icons/decending.svg';
import _ from "lodash";
// import moment from 'moment'
import SearchWrapper from '../../Components/Search';
import { RouterConstant } from '../../constants/RouteConstant';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { AppContext } from '../../AppContext';


const AdminControls = () => {

    // let { userId } = useParams();
    // const pagination = useRef();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    // const [status, setStatus] = useState("pending");
    // const [pageNumber, setPageNumber] = useState(1);
    // const [limit, setLimit] = useState(12);
    const [searchString, setSearchString] = useState('');
    const delayedQuery = useRef(_.debounce(q => sendQuery(q), 500)).current;
    const [searchQuery, setSearchQuery] = useState('');
    const [companyValue, setCompanyValue] = useState('');
    const [iussdNumber, setIussdNumber] = useState('');
    const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
    const [istoggleDeleteModal, setIstoggleDeleteModal] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [companyDetai, setCompanyDetai] = useState({});

    const { userProfile } = useContext(AppContext);
    useEffect(()=>{
        userProfile()
    },[])
    
    useEffect(() => {
        getAllCompany();
    }, [searchQuery])

    const getAllCompany = () => {
        setLoader(true)
        getAllCompanyList(searchQuery)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data)
                    // alert.success(response?.data?.isError);
                    // navigate(RouterConstant.Layout);

                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })

    }


    const handleSearch = (event) => {
        setSearchString(event.target.value);
        // setPageNumber(1)
        delayedQuery(event.target.value);
    }
    const sendQuery = query => setSearchQuery(query);

    const createCompany = () => {
        setCompanyDetai({});
        setIsCompanyModalOpen(!isCompanyModalOpen);
    }

    const closeCompanyModal = () => {
        setIsCompanyModalOpen(false);
        setCompanyValue('');
        setIstoggleDeleteModal(false);
    }

    const handleCompanyName = (event, name) => {
        if(name === 'companyName'){
            setCompanyValue(event.target.value);
        }else{
            setIussdNumber(event.target.value)
        }
    }


    const submitCompany = () => {
        if (companyValue.length > 0) {
            let param = { companyName: companyValue, iUSSDNumber: iussdNumber };
            setLoader(true);
            setCompanyValue('');
            setIussdNumber('')
            setIsCompanyModalOpen(false);
            saveCompanyName(param)
                .then((response) => {
                    if (response?.data?.status === 'OK') {
                        getAllCompany();
                        // alert.success(response?.data?.isError);
                        // navigate(RouterConstant.Layout);

                    } else {
                        console.log("test login api", response)
                    }
                    setLoader(false)
                })
                .catch(error => {
                    console.log("catch block", error)
                    alert.error(error?.message);
                    setLoader(false)
                })
        }
    }

    const deleteCompanyName = (_id) => {
        setCompanyId(_id);
        setIstoggleDeleteModal(!istoggleDeleteModal);
    }

    const comfirmDelete = () => {
        let param = { 'companyId': companyId };
        deleteCompany(param)
            .then((response) => {
                setIstoggleDeleteModal(false);
                if (response?.data?.status === 'OK') {
                    getAllCompany();
                    alert.success(response?.data?.message);
                    // navigate(RouterConstant.Layout);
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                alert.error(error?.message);
                setLoader(false)
            })
    }

    const editCompanyName = (company) => {
        setIsCompanyModalOpen(!isCompanyModalOpen);
        setCompanyDetai(company);
        setCompanyValue(company?.companyName);
        setIussdNumber(company?.iUSSDNumber)
    }  

    const updateCompany = () => {
        if (companyValue.length > 0) {
            let param = { companyName: companyValue, iUSSDNumber: iussdNumber,'companyId':companyDetai?._id};
            setLoader(true);
            setCompanyValue('');
            setIsCompanyModalOpen(false);
            updateCompanyName(param)
                .then((response) => {
                    if (response?.data?.status === 'OK') {
                        getAllCompany();
                         alert.success(response?.data?.message);
                    } else {
                        console.log("test login api", response)
                    }
                    setLoader(false)
                })
                .catch(error => {
                    console.log("catch block", error)
                    alert.error(error?.message);
                    setLoader(false)
                })
        }
    }

    return (
        <>

            <div className="top-filter-button align-items-center">
                <SearchWrapper
                    type='text'
                    name='searchString'
                    className='mb-3 mt-4 mt-md-0'
                    value={searchString}
                    onChange={handleSearch}
                    placeholder="Search..."
                />
                <div>
                    <button className="btn primary-btn min-padding" onClick={createCompany}><i className="fa fa-plus"></i> Add More</button>
                </div>
            </div>
            <div className="data-table table-responsive">
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">USSD No.</th>
                            <th scope="col">Company Name</th>
                            <th scope="col">More</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            apiData?.map((company, index) => {
                                return (
                                    <tr key={index}>
                                        <td scope="row" className="pt-md-4 pt-3">{(index + 1)}</td>
                                        <td scope="row" className="pt-md-4 pt-3">{company.iUSSDNumber}</td>
                                        <td scope="row" className="pt-md-4 pt-3">{company.companyName}</td>
                                        <td className="pt-md-4 pt-3">
                                            <NavLink to={`${RouterConstant.CompanyDetail}/${company.companyName}`} className="heighlight"> view more</NavLink>
                                            <span><i className='fa fa-trash text-danger cursor-pointer' onClick={() => deleteCompanyName(company?._id)}></i>
                                                <i className='fa fa-pencil cursor-pointer' onClick={() => editCompanyName(company)}></i>
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                {/* <div className="my-5 d-flex justify-content-center">
                    <ReactPaginate
                        ref={pagination}
                        pageCount={apiData?.total / limit}
                        pageRangeDisplayed={limit}
                        marginPagesDisplayed={1}
                        onPageChange={setPage}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        previousLabel={<>&laquo;</>}
                        nextLabel={<>&raquo;</>}
                    />
                </div> */}
            </div>

            {
                loader ?
                    <LoaderWrapper /> : ''
            }

            <Modal isOpen={isCompanyModalOpen} centered>
                <ModalHeader toggle={closeCompanyModal}>
                    {(companyDetai?._id) ?
                        'Update Company' : 'Create Company'
                    }
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div className='mb-4'>
                        <input type="text" placeholder='iUSSDNumber' className={`input-box form-control p-3`} onChange={(e)=>handleCompanyName(e, 'iUSSDNumber')} value={iussdNumber} maxLength="150"></input>
                    </div>
                    <div className='mb-4'>
                        <input type="text" placeholder='Company Name' className={`input-box form-control p-3`} onChange={(e)=>handleCompanyName(e, 'companyName')} value={companyValue} maxLength="150"></input>
                    </div>
                    <div className='d-flex justify-content-end'>
                        {(companyDetai?._id) ?
                            <button
                                className="btn primary-btn min-padding ms-3" onClick={updateCompany} >
                                Update
                            </button>
                            :
                            <button
                                className="btn primary-btn min-padding ms-3" onClick={submitCompany} >
                                Save
                            </button>
                        }

                        <button
                            className="btn bordered-btn min-padding ms-3" onClick={closeCompanyModal} >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={istoggleDeleteModal} centered>
                <ModalHeader toggle={closeCompanyModal}>
                    Delete Company
                </ModalHeader>
                <ModalBody className="logout-body">
                    
                        <p className='text-center'>
                            Are you sure you want to delete this company?
                        </p>
                    
                    <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn danger-btn min-padding" onClick={comfirmDelete} >
                            Delete
                        </Button>
                    </div>
                </ModalBody>
            </Modal>


        </>
    )
}
export default AdminControls
