import { ReactComponent as CommentIcon } from '../../assets/images/icons/comment_icon.svg';
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg';
import { ReactComponent as AcceptIcon } from '../../assets/images/icons/accept.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/icons/reject.svg';
import { ReactComponent as RejectedIcon } from '../../assets/images/icons/rejected.svg'

const BankDetail = ({ data, apiresponse, rejectBasicDetail, acceptBasicDetail, addNote, toggleField, toggle, toggleFieldStatus, verifyBank }) => {
    
    return (
        <>
            <div className="profile-section-card">
                <div className="head-wrap">
                    <h3 className="heading">Bank Details
                        {/* <span className={`badge ms-3 badge-msg ${data?.status === -1 ? "bg-danger" : data?.status === 2 ? "bg-success" : ''}`}>
                            {`${data?.status === -1 ? "Rejected" : data?.status === 2 ? "Approved" : ''}`}
                        </span> */}
                    </h3>
                    {apiresponse?.bankDetails?.paystackBankAccountValidationDone == 1 ?
                            <div className="admin-action-wrap edit me-auto ms-3" >
                                <span className="badge bg-success">Verified</span>
                            </div>
                            : null

                        }
                        {apiresponse?.isSendForKYCApproval == 2 && (apiresponse?.bankDetails?.paystackBankAccountValidationDone == 0 || apiresponse?.bankDetails?.paystackBankAccountValidationDone == 2) ?
                            <div className="accet-reject me-auto ms-3 cursor-pointer" >
                                <span className="badge bg-primary" onClick={() => { verifyBank({ "field": "bankCode", "fieldData": 1 }) }}>Verify</span>
                            </div>
                            : null
                        }
                    <ul className="head-action d-flex align-items-center">
                        {
                            // data?.status !== 0 ?
                            // <li className="admin-action-wrap accept" onClick={() => toggle({ "action": 'accept', 'type': 'bankDetails' })}>
                            //     <i className="fa fa-check text-success"></i>
                            // </li> 
                            // : null
                        }
                        
                        <li className="admin-action-wrap accept cursor-pointer" onClick={() => toggle({ "action": 'Add Comment', 'type': 'bankDetails' })}>
                            <CommentIcon className="" onClick={addNote} />
                            <span className='ms-3'>Add Comment</span>
                        </li>

                    </ul>
                </div>
                <div className="detail-field">
                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Account Number</label>
                            <p className="value">{data?.accountNo}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.accountNo != 2 ?
                                <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "accountNo", "fieldData": data?.accountNo, "feildType": "number", "fieldValidation": "Field is required", "fieldLabel": "Account Number" ,"required":true})}>
                                    <EditIcon className="admin-action" />
                                    <label className="label-key">Edit</label>
                                </div>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.accountNo == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.accountNo == 3 ?
                                    <>
                                        <div className="admin-action-wrap Rejected" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 && apiresponse?.bankDetails?.paystackBankAccountValidationDone == 1?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.accountNo != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'accountNo' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.accountNo == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'accountNo' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }


                                </>
                                : null
                        }

                    </div>

                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Bank Name</label>
                            <p className="value">{data?.bankName}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.bankName != 2 ?
                                <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "bankName", "fieldData": data?.bankName, "feildType": "select", "fieldValidation": "Field is required", "fieldLabel": "Bank Name" ,"required":true})}>
                                    <EditIcon className="admin-action" />
                                    <label className="label-key">Edit</label>
                                </div>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.bankName == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.bankName == 3 ?
                                    <>
                                        <div className="admin-action-wrap Rejected" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 && apiresponse?.bankDetails?.paystackBankAccountValidationDone == 1?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.bankName != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'bankName' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.bankName == 1 ?
                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'bankName' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }


                                </>
                                : null

                        }

                    </div>

                    <div className="field-wrapper">
                        <div className="form-group">
                            <label className="label-key">Account Holder Name</label>
                            <p className="value">{data?.accountHolderName}</p>
                        </div>
                        {
                            apiresponse?.isSendForKYCApproval != 4 && apiresponse?.fieldStatus?.accountHolderName != 2 ?
                                <div className="admin-action-wrap edit" onClick={() => toggleField({ "field": "accountHolderName", "fieldData": data?.accountHolderName, "feildType": "text", "fieldValidation": "Field is required", "fieldLabel": "Account Holder Name","required":true })}>
                                    <EditIcon className="admin-action" />
                                    <label className="label-key">Edit</label>
                                </div>
                                : null
                        }

                        {
                            apiresponse?.fieldStatus?.accountHolderName == 2 ?
                                <>
                                    <div className="admin-action-wrap accept" >
                                        <AcceptIcon className="admin-action" />
                                        <label className="label-key">Accepted</label>
                                    </div>
                                </>
                                :
                                apiresponse?.fieldStatus?.accountHolderName == 3 ?
                                    <>
                                        <div className="admin-action-wrap Rejected" >
                                            <RejectedIcon className="admin-action" />
                                            <label className="label-key">Rejected</label>
                                        </div>
                                    </>
                                    : null
                        }
                        {
                            apiresponse?.isSendForKYCApproval == 2 && apiresponse?.bankDetails?.paystackBankAccountValidationDone == 1?
                                <>
                                    {
                                        apiresponse?.fieldStatus?.accountHolderName != 2 ?
                                            <div className="admin-action-wrap accept" onClick={() => toggleFieldStatus({ "action": '2', 'keyName': 'accountHolderName' })}>
                                                <AcceptIcon className="admin-action" />
                                                <label className="label-key">Approve</label>
                                            </div>
                                            : null
                                    }
                                    {
                                        apiresponse?.fieldStatus?.accountHolderName == 1 ?

                                            <div className="admin-action-wrap reject" onClick={() => toggleFieldStatus({ "action": '3', 'keyName': 'accountHolderName' })}>
                                                <RejectIcon className="admin-action" />
                                                <label className="label-key">Reject</label>
                                            </div>
                                            : null
                                    }

                                </>
                                : null
                        }

                    </div>
                </div>
                {/* code of text area */}
            </div>
            {
                data?.comment && apiresponse?.isSendForKYCApproval == 1||apiresponse?.isSendForKYCApproval == 3 ?
                    <div className="commented-profile">
                        <textarea readOnly="true" value={data?.comment} />
                    </div>
                    : null
            }

        </>
    );
}

export default BankDetail;