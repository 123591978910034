import ApiInstance from "./../../config/intercepter";
import Api from "./../../config/api";

// login
export function loginApiFun(payload) {
  return ApiInstance.post(`${Api.login}`, payload);
}

// forgot password
export function forgotPasswordService(payload) {
  return ApiInstance.post(`${Api.forgotPassword}`, payload);
}

// reset Password
export function resetPasswordApiFun(payload) {
  return ApiInstance.post(`${Api.resetPassword}`, payload);
}

// reset Password
export function logoutApiFun(payload) {
  return ApiInstance.post(`${Api.logout}`, payload);
}

// get all user
export function getAllUser(pageType,sort,pageNumber,limit,searchString) {
  return ApiInstance.get(`${Api.getUserList}?pageType=${pageType}&sortKey=${sort?.keyName}&sortOrder=${sort?.order}&page=${pageNumber}&limit=${limit}&searchString=${searchString}`);
}

export function resetEmpPayments(userId) {
  return ApiInstance.post(`${Api.resetEmpPayment}`, {userId: userId});
}

// view user Detail
export function getUserDetail(userId) {
  return ApiInstance.get(`${Api.getUser}?userId=${userId}`);
}

// get all request
export function getRequest(status,sort,pageNumber,limit,searchString) {
  return ApiInstance.get(`${Api.getAllRequest}?statusFilter=${status?status:""}&sortKey=${sort?.keyName}&sortOrder=${sort?.order}&page=${pageNumber}&limit=${limit}&searchString=${searchString}`);
}

export function getRequestCsv(status,sort,pageNumber,limit,searchString) {
  return ApiInstance.get(`${Api.getAllRequest}?statusFilter=${status?status:""}&sortKey=${sort?.keyName}&sortOrder=${sort?.order}&page=${pageNumber}&limit=${limit}&searchString=${searchString}`);
}
//get request by id
export function getRequestById(userId) {
  return ApiInstance.get(`${Api.getAllRequest}?userId=${userId}`);
}
// get request detail
export function getRequestDetail(requestId) {
  return ApiInstance.get(`${Api.getRequestDetail}?requestId=${requestId}`);
}


export function paymentByCash(details) {
  return ApiInstance.post(`${Api.paymentByCash}`,details);
}
export function changeRequestStatus(data) {
  return ApiInstance.post(`${Api.changeRequestStatus}?requestId=${data?.requestId}&status=${data?.status}&rejectComment=${data?.rejectComment}&dueDateAdvanceStatusComplete=${data?.dueDateAdvanceStatusComplete}`);
}

export function changeStatusBulk(data) {
  return ApiInstance.post(`${Api.changeStatusBulk}`,data);
}


export function deleteUserRequest(data) {
 return ApiInstance.post(`${Api.deleteUserRequest}`,data);
}

export function changeAmountRequest(data) {
  // requestId=${data?.requestId}&requestedAmount=${data?.requestedAmount}
  return ApiInstance.post(`${Api.changeAmountRequest}`,data);
}

// employee Status
// user/changeStatus?userId=619de2dcc13d3803f374e12b&type=kycDetails&action=accept&comment=rejection%20reason
export function employeeStatus(data) {
  return ApiInstance.post(`${Api.changeEmployeeStatus}?userId=${data?.userId}&type=${data?.type}&action=${data?.action}&comment=${data?.comment}`);
}

export function addCommentOnSection(data) {
  return ApiInstance.post(`${Api.addCommentOnSection}`,data);
}

export function approveRejectUserFields(data) {
  return ApiInstance.post(`${Api.approveRejectUserFields}`,data);
}

export function approveKycVerification(data) {
  return ApiInstance.post(`${Api.approveKycVerification}`,data);
}
export function validateNinCode(data) {
  return ApiInstance.post(`${Api.validateNIN}`,data);
}
export function paystackBankAccountList() {
  return ApiInstance.get(`${Api.paystackBankAccountList}`);
}
export function bankAccountVerification(data) {
  return ApiInstance.post(`${Api.bankAccountVerification}`,data);
}
export function updateUserBankAccountVerification(data) {
  return ApiInstance.post(`${Api.updateUserBankAccountVerification}`,data);
}
export function companyNameList() {
  return ApiInstance.get(`${Api.companyNameList}`);
}
export function getCompanyContractorSite(data) {
  return ApiInstance.post(`${Api.getCompanyContractorSite}`,data);
}



export function updateUserNINVerification(data) {
  return ApiInstance.post(`${Api.updateUserNINVerification}`,data);
}

export function rejectKycVerification(data) {
  return ApiInstance.post(`${Api.rejectKycVerification}`,data);
}

export function sendKycVerification(data) {
  return ApiInstance.post(`${Api.sendKycVerification}`,data);
}


export function loanStatus(data) {
  return ApiInstance.post(`${Api.changeEmployeeStatus}?userId=${data?.userId}&type=${data?.type}&action=${data?.action}&comment=${data?.comment}`);
}

export function editEmployee(data) {
  return ApiInstance.post(`${Api.updateEmployee}`,data);
}

// employer signup list
export function getEmployerList() {
  return ApiInstance.get(`${Api.getEmployerList}`);
}

// get all user
export function getAdvanceStatusList(pageType,sort,pageNumber,limit,searchString, userId) {
  return ApiInstance.get(`${Api.advanceStatusList}?userId=${userId?userId:''}&pageType=${pageType}&sortKey=${sort?.keyName}&sortOrder=${sort?.order}&page=${pageNumber}&limit=${limit}&searchString=${searchString}`);
}

// admin Controls
export function getAllCompanyList(searchString) {
  return ApiInstance.get(`${Api.companyList}?searchString=${searchString}`);
} 
export function saveCompanyName(data) {
  return ApiInstance.post(`${Api.createCompanyName}`,data);
}
export function saveContrator(data) {
  return ApiInstance.post(`${Api.addCompanyContractors}`,data);
} 
export function saveSite(data) {
  return ApiInstance.post(`${Api.addCompanySite}`,data);
} 

export function getContractorSite(companyName) {
  return ApiInstance.post(`${Api.getCompanyContractorSite}`, {'companyName':companyName});
}

export function deleteCompany(data) {
  return ApiInstance.post(`${Api.deleteCompany}`,data);
} 

export function deleteCompanyContractors(data) {
  return ApiInstance.post(`${Api.deleteCompanyContractors}`,data);
} 
export function deleteCompanySite(data) {
  return ApiInstance.post(`${Api.deleteCompanySite}`,data);
}  
export function updateCompanyContractors(data) {
  return ApiInstance.post(`${Api.updateCompanyContractors}`,data);
} 
export function updateCompanySite(data) {
  return ApiInstance.post(`${Api.updateCompanySite}`,data);
} 
export function updateCompanyName(data) {
  return ApiInstance.post(`${Api.updateCompanyName}`,data);
} 

export function getDefaultProcessingFee() {
  return ApiInstance.get(`${Api.getDefaultProcessingFee}`);
}  
export function updateDefaultProcessingFee(data) {
  return ApiInstance.post(`${Api.updateDefaultProcessingFee}`,data);
}  
export function paystackPaymentByAdmin(data) {
  return ApiInstance.post(`${Api.paystackPaymentByAdmin}`,data);
}  


// individaul request
export function getAllUserRequestList(status,sort,pageNumber,limit,searchString, userId) {
  return ApiInstance.get(`${Api.getAllUserRequest}?userId=${userId}&statusFilter=${status?status:""}&sortKey=${sort?.keyName}&sortOrder=${sort?.order}&page=${pageNumber}&limit=${limit}&searchString=${searchString}`);
} 

export function getAllUserRequestListCsv(status,sort,pageNumber,limit,searchString, userId) {
  return ApiInstance.get(`${Api.getAllUserRequest}?userId=${userId}&statusFilter=${status?status:""}&sortKey=${sort?.keyName}&sortOrder=${sort?.order}&page=${pageNumber}&limit=${limit}&searchString=${searchString}`);
} 

// individaul Advance Status
export function allUserAdvanceStatusList(pageType,sort,pageNumber,limit,searchString, userId) {
  return ApiInstance.get(`${Api.allUserAdvanceStatusList}?userId=${userId}&pageType=${pageType}&sortKey=${sort?.keyName}&sortOrder=${sort?.order}&page=${pageNumber}&limit=${limit}&searchString=${searchString}`);
}


export function changeProfileStatusFromCompleted(param) {
  return ApiInstance.post(`${Api.changeProfileStatusFromCompleted}`,param);
}

// admin-list and permission
export function listSubAdminService(param) {
  return ApiInstance.get(`${Api.listSubAdmin}`,param);
}

export function updateSubAdminService(param) {
  return ApiInstance.post(`${Api.updateSubAdmin}`,param);
}

export function addSubAdminService(param) {
  return ApiInstance.post(`${Api.addSubAdmin}`,param);
}

export function adminProfileService(param) {
  return ApiInstance.get(`${Api.adminProfile}`,param);
}

export function getReferedEmployer(pageType,sort,pageNumber,limit,searchString, userId) {
  return ApiInstance.get(`${Api.getReferedEmployer}?userId=${userId}&pageType=${pageType}&sortKey=${sort?.keyName}&sortOrder=${sort?.order}&page=${pageNumber}&limit=${limit}&searchString=${searchString}`);
}




