import React, { useContext, useEffect, useState } from 'react'
import DashboardCard from '../../Components/Cards/DashboardCard'
import './overview.scss'
import { AppContext } from '../../AppContext'
const Overview = () => {
    const [user, setUser] = useState({})
    const { userProfile } = useContext(AppContext);
    useEffect(()=>{
        userProfile()
    },[])

    useEffect(()=>{
        let userData = JSON.parse(localStorage.getItem('user'))
        setUser(userData?.stats);
    },[])

    return (
        <>
            <div className="row">
                <DashboardCard
                    margin=""
                    heading="EMPLOYEES"
                    totalCount={user?.totalUser}
                    status=""
                    notificationCount={12}
                    notification={false}
                />
                
                <DashboardCard
                    margin=""
                    heading="LOAN REQUESTS"
                    totalCount={user?.totalRequest}
                    // status="Pending Approval"
                    notificationCount={3}
                    notification={false}
                />
                
            </div>
        </>
    )
}

export default Overview
