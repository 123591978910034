import React, { useContext, useEffect, useRef, useState } from 'react'
import './request.scss'
import { NavLink } from 'react-router-dom'
import { getDefaultProcessingFee, updateDefaultProcessingFee, saveCompanyName, deleteCompany, updateCompanyName } from '../../API/Services/authService';
import { useAlert } from "react-alert";
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import _ from "lodash";
// import moment from 'moment'
import SearchWrapper from '../../Components/Search';
import { RouterConstant } from '../../constants/RouteConstant';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { AppContext } from '../../AppContext';


const ProcessingFeeControl = () => {

    // let { userId } = useParams();
    // const pagination = useRef();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    // const [status, setStatus] = useState("pending");
    // const [pageNumber, setPageNumber] = useState(1);
    // const [limit, setLimit] = useState(12);
    const [searchString, setSearchString] = useState('');

    const [searchQuery, setSearchQuery] = useState('');
    const [processingValue, setProcessingValue] = useState(0);
    const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
    const [istoggleDeleteModal, setIstoggleDeleteModal] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [enableEditMode, setEnableEditMode] = useState(true);
    const [isActiveStatus, SetisActiveStatus] = useState(false);
    const { userProfile } = useContext(AppContext);
    useEffect(()=>{
        userProfile()
    },[])
  
    useEffect(() => {
        getprocessingFee();
    }, []);



    const getprocessingFee = () => {
        setLoader(true);
        getDefaultProcessingFee()
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data);
                    setProcessingValue(response?.data?.data?.processFee);
                    SetisActiveStatus(response?.data?.data?.isActive);
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })

    }

    const handleProcessingAmount = (event) => {
        setProcessingValue(event.target.value);

    }

    const editProcessFee = () => {
        setEnableEditMode(!enableEditMode);
    }

    const cancelEdit = () => {
        setEnableEditMode(!enableEditMode);
        setProcessingValue(apiData?.processFee);
        SetisActiveStatus(false);
    }

    const changeIsActiveStatus = (event) => {
        SetisActiveStatus(event.target.checked);
    }

    const setVal = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    const submitprocessfee = () => {
        let param = {
            'processingFeeId': apiData?._id,
            'processFee': parseInt(processingValue),
            'isActive': isActiveStatus
        }
        updateDefaultProcessingFee(param)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    getprocessingFee();
                    setEnableEditMode(!enableEditMode);
                    alert.success(response?.data?.message);
                    // navigate(RouterConstant.Layout);

                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })
    }

    return (
        <>
            <div className="view-details-wrapper my-5 px-0">
                <div className="profile-header">
                    <h4 className="view-details-heading text-capitalize">Processing Fee Control</h4>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="profile-section-card">
                            <div className="head-wrap align-items-center">
                                <h3 className="heading">Enabled</h3>
                                {
                                    enableEditMode &&
                                    <i className='fa fa-edit cursor-pointer text-primary' onClick={editProcessFee}>&nbsp;Edit</i>
                                }
                            </div>
                            <div className="detail-field">
                                <div className="field-wrapper">
                                    <div className="form-group w-100">
                                        <label className="label-key mb-2">Update Processing Fee</label>
                                        <div className="value">
                                            <div class="form-check form-switch">
                                                <input
                                                    disabled={enableEditMode}
                                                    onChange={changeIsActiveStatus}
                                                    checked={isActiveStatus}
                                                    className="form-check-input cursor-pointer"
                                                    type="checkbox"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field-wrapper">
                                    <div className="form-group w-100">
                                        <label className="label-key mb-2">Enter Processing fee</label>
                                        <div className="value">
                                            <input type="text" className={`input-box form-control p-3`} value={processingValue} maxLength={6} onChange={handleProcessingAmount}
                                                disabled={enableEditMode} pattern="[0-9]*"
                                                onKeyPress={setVal} />

                                        </div>
                                    </div>
                                </div>
                                <div className="field-wrapper">
                                    <div className="form-group w-100">
                                        {/* <label className="label-key">Update Processing Fee</label> */}
                                        <div className="value">
                                            {
                                            !enableEditMode &&
                                                <div className='d-flex justify-content-end'>
                                                    <button className="btn bordered-btn min-padding ms-3" onClick={cancelEdit}>Cancel</button>
                                                    <button className="btn primary-btn min-padding ms-3" style={{ marginLeft: "10px" }} onClick={submitprocessfee}>Submit</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> : ''
            }
        </>
    )
}
export default ProcessingFeeControl
