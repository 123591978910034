import React from 'react'
import './card.scss'
import {ReactComponent as RefreshIcon} from "../../assets/images/icons/refresh_icon.svg"
const DashboardCard = ({heading, totalCount, status, notification, notificationCount, margin}) => {
    return (
        <div className={`col-lg-6 col-xl-6 card-dashboard-wrapper ${margin}`}>
            <div className="card-dashboard">
                {notification ? <span className="notification-badge">{notificationCount}</span> : ""}
                <h2 className="dashboard-heading">
                    {heading}
                </h2>
                <p className="dashboard-count">{totalCount}<span className="total">(Total)</span></p>
                <div className="dashboard-status-wrapper">
                    <p className="dashboard-status">{status}</p>
                    {/* <RefreshIcon className="refresh-icon"/> */}
                </div>
            </div>
        </div>
    )
}

export default DashboardCard
