import React, { useEffect, useState } from 'react'
import { getContractorSite, saveContrator, saveSite, deleteCompanyContractors,deleteCompanySite,updateCompanyContractors,updateCompanySite} from '../../API/Services/authService';
import { useAlert } from "react-alert";
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import { RouterConstant } from '../../constants/RouteConstant';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const CompanyContractor = () => {
    let { companyName } = useParams();
    const navigate = useNavigate()
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [isContractModal, setIsContractModalOpen] = useState(false);
    const [isContracteditModal, setIsContracteditModalOpen] = useState(false);
    const [contractorValue, setcontractorValue] = useState('');
    const [isSiteModal, setIsSiteModalOpen] = useState(false);
    const [isSiteEditModal, setisSiteEditModal] = useState(false);
    const [siteInputvalue, setSiteValue] = useState('');
    const [contDeleteModel, setContDeleteModel] = useState(false);
    const [contrName, setContrName] = useState('');
    const [siteDeleteModel, setSiteDeleteModel] = useState(false);
    const [siteName, setSiteName] = useState('');
    const [editContValue, setEditContValue] = useState('');
    const [contrValueIndex, SetcontrValueIndex] = useState();
    const [siteValueIndex, SetsiteValueIndex] = useState();
    const [editSiteValue, SeteditSiteValue] = useState('');

    useEffect(() => {
        getCompanyContractorSite()
    }, [])

    const getCompanyContractorSite = () => {
        setLoader(true)
        getContractorSite(companyName)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data)
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })

    }

    /**** Contractor Create  */
    const createContrator = () => {
        setIsContractModalOpen(!isContractModal);
    }

    const hanleContractorName = (event) => {
        setcontractorValue(event.target.value);
        setEditContValue(event.target.value);
    }

    const submitNewContractor = () => {
        if (contractorValue.length > 0) {
            let param = { companyId: apiData?._id, contractorsName: contractorValue };
            setLoader(true);
            setcontractorValue('');
            setIsContractModalOpen(false);
            saveContrator(param)
                .then((response) => {
                    if (response?.data?.status === 'OK') {
                        getCompanyContractorSite();
                        // alert.success(response?.data?.isError);
                    } else {
                        console.log("test login api", response)
                    }
                    setLoader(false)
                })
                .catch(error => {
                    console.log("catch block", error)
                    alert.error(error?.message);
                    setLoader(false)
                })
        }

    }

    const closeContractorModal = () => {
        setIsContractModalOpen(false);
        setcontractorValue('');
        setContDeleteModel(false);
        setSiteDeleteModel(false);
        setIsContracteditModalOpen(false);
    }
    /****End code of Contractor Create */
    /***** Site Create  **********/
    const createSite = () => {
        setIsSiteModalOpen(!isSiteModal);
    }

    const submitNewSite = () => {
        if (siteInputvalue.length > 0) {
            let param = { companyId: apiData?._id, siteName: siteInputvalue };
            setLoader(true);
            setSiteValue('');
            setIsSiteModalOpen(!isSiteModal);
            saveSite(param)
                .then((response) => {
                    if (response?.data?.status === 'OK') {
                        getCompanyContractorSite();
                        // alert.success(response?.data?.isError);
                    } else {
                        console.log("test login api", response)
                    }
                    setLoader(false)
                })
                .catch(error => {
                    console.log("catch block", error)
                    alert.error(error?.message);
                    setLoader(false)
                })
        }

    }

    const hanleSiteName = (event) => {
        setSiteValue(event.target.value);
        SeteditSiteValue(event.target.value);
    }
    const closeSiteModal = () => {
        setIsSiteModalOpen(false);
        setSiteValue('');
        setisSiteEditModal(false);
    }
    /**** En code of Site Create */
    // Start code delete contractor 
    const deleteContractor = (contractor) => {
        setContDeleteModel(!contDeleteModel);
        setContrName(contractor);
    }

    const confirmContDelete = () => {
        let param = { 'companyId': apiData?._id, 'contractorsName': contrName };
        setLoader(true);
        deleteCompanyContractors(param)
            .then((response) => {
                setContDeleteModel(false);
                setContrName('');
                if (response?.data?.status === 'OK') {
                    getCompanyContractorSite();
                    alert.success(response?.data?.message);
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })
    }

    const confirmSiteDelete=()=>{
        let param = { 'companyId': apiData?._id, 'siteName': siteName };
        setLoader(true);
        deleteCompanySite(param)
            .then((response) => {
                setSiteDeleteModel(false);
                setSiteName('');
                if (response?.data?.status === 'OK') {
                    getCompanyContractorSite();
                    alert.success(response?.data?.message);
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })
    }

    const deleteSite=(site)=>{
        setSiteName(site);
        setSiteDeleteModel(!siteDeleteModel);
    }

   const editContractor=(contName,index)=>{
        setEditContValue(contName);
        setIsContracteditModalOpen(!isContracteditModal);
        SetcontrValueIndex(index);
   }

    const submitUpdateContr=()=>{
        apiData?.contractors.splice(contrValueIndex,1);
        apiData?.contractors.push(editContValue);
       let param={'companyId':apiData?._id,'contractors':apiData?.contractors};
       setLoader(true);
          updateCompanyContractors(param)
          .then((response) => {
            setIsContracteditModalOpen(false);
            if (response?.data?.status === 'OK') {
                getCompanyContractorSite();
                alert.success(response?.data?.message);
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        })
        .catch(error => {
            console.log("catch block", error)
            alert.error(error?.message);
            setLoader(false)
        })
    }

// edit site code 
    const editSite=(siteName,index)=>{
            setisSiteEditModal(!isSiteEditModal);
            SetsiteValueIndex(index);
            SeteditSiteValue(siteName);
    }

    const updateSite=()=>{
        apiData?.sites.splice(siteValueIndex,1);
        apiData?.sites.push(editSiteValue);
        setLoader(true);
        let param={'companyId':apiData?._id,'sites':apiData?.sites};
        updateCompanySite(param)
        .then((response) => {
            setSiteValue('');
            setisSiteEditModal(false);
            if (response?.data?.status === 'OK') {
                getCompanyContractorSite();
                alert.success(response?.data?.message);
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        })
        .catch(error => {
            console.log("catch block", error)
            alert.error(error?.message);
            setLoader(false)
        })
    }


    return (
        <>
            <div className="profile-section-card">
                <div className="head-wrap align-items-center">
                    <h3 className="heading">Company: {companyName}</h3>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group w-100">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className="d-block">Contractor</h4>
                                        <span className="text-primary cursor-pointer" onClick={createContrator}><i className="fa fa-plus"></i> Add More</span>
                                    </div>
                                    <div className="data-table table-responsive">
                                        <table className="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S/N</th>
                                                    <th scope="col">Contractor</th>
                                                    {<th scope="col">Action</th>}
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    apiData?.contractors?.map((contractor, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td scope="row" className="pt-md-4 pt-3">{(index + 1)}</td>
                                                                <td scope="row" className="pt-md-4 pt-3">{contractor}</td>
                                                                {
                                                                    <td className="pt-md-4 pt-3">
                                                                        <i className="fa fa-trash text-danger cursor-pointer" onClick={() => deleteContractor(contractor)}></i>
                                                                        <i className='fa fa-pencil cursor-pointer' onClick={() => editContractor(contractor,index)}></i>
                                                                    </td>
                                                                    // <span><i className='fa fa-trash text-danger cursor-pointer' onClick={() => deleteCompanyName(company?._id)}></i>
                                                                    //     <i className='fa fa-pencil cursor-pointer' onClick={() => editCompanyName(company)}></i>
                                                                    // </span>
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group w-100">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className="d-block">Sites</h4>
                                        <span className="text-primary cursor-pointer" onClick={createSite}><i className="fa fa-plus"></i> Add More</span>
                                    </div>
                                    <div className="data-table table-responsive">
                                        <table className="table table-borderless">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S/N</th>
                                                    <th scope="col">Site</th>
                                                    {<th scope="col">Action</th>}
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    apiData?.sites?.map((site, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td scope="row" className="pt-md-4 pt-3">{(index + 1)}</td>
                                                                <td scope="row" className="pt-md-4 pt-3">{site}</td>
                                                                {
                                                                    <td className="pt-md-4 pt-3">
                                                                        <i className="fa fa-trash text-danger cursor-pointer" onClick={() => deleteSite(site)}></i>
                                                                        <i className='fa fa-pencil cursor-pointer' onClick={() => editSite(site,index)}></i>
                                                                    </td>
                                                                }

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {
                loader ? <LoaderWrapper /> : ''
            }

            <Modal isOpen={isContractModal} centered>
                <ModalHeader toggle={closeContractorModal}>
                    Create Contractor
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div>
                        <input type="text" placeholder="Contractor name" className={`input-box form-control p-3`} onChange={hanleContractorName} value={contractorValue} maxLength="150"></input>
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={submitNewContractor} >
                            Save
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2" onClick={closeContractorModal} >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={isContracteditModal} centered>
                <ModalHeader toggle={closeContractorModal}>
                    Update Contractor
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div>
                        <input type="text" placeholder='Contractor Name' className={`input-box form-control p-3`} onChange={hanleContractorName} value={editContValue} maxLength="150"></input>
                    </div>

                    <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={submitUpdateContr} >
                            Update
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2" onClick={closeContractorModal} >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={isSiteModal} centered>
                <ModalHeader toggle={closeSiteModal}>
                    Create Site
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div>
                        <input type="text" placeholder='Site name' className={`input-box form-control p-3`} onChange={hanleSiteName} value={siteInputvalue} maxLength="150"></input>
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={submitNewSite} >
                            Save
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2" onClick={closeSiteModal} >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={isSiteEditModal} centered>
                <ModalHeader toggle={closeSiteModal}>
                    Update Site
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div>
                        <input type="text" placeholder='Update Site' className={`input-box form-control p-3`} onChange={hanleSiteName} value={editSiteValue} maxLength="150"></input>
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn primary-btn min-padding ms-3" onClick={updateSite} >
                            Update
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-3" onClick={closeSiteModal} >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={contDeleteModel} centered>
                <ModalHeader toggle={closeContractorModal}>
                    Delete Contrator
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div>
                        <p className='text-center'>
                            Are you sure you want to delete this Contrator ?
                        </p>
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn danger-btn min-padding ms-2" onClick={confirmContDelete} >
                            Delete
                        </Button>

                        <button
                            className="btn bordered-btn min-padding ms-2" onClick={closeContractorModal} >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal isOpen={siteDeleteModel} centered>
                <ModalHeader toggle={closeContractorModal}>
                    Delete Site
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div>
                        <p className='text-center'>
                            Are you sure you want to delete this Site ?
                        </p>
                    </div>
                    <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn danger-btn min-padding ms-2" onClick={confirmSiteDelete} >
                            Delete
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2" onClick={closeContractorModal} >
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>


        </>
    );
}

export default CompanyContractor;