export const RouterConstant = {
    Layout: '/', 
    Overview: '', 
    Login: "/login",
    Request:"/request",
    PaymentDetail: '/request/payment-detail',
    Employees: "/employees",
    ViewEmployee: "/employees/detail",
    ViewRequest: "/view-request",
    ForgotPassword : '/forgot-password',
    AdvanceStatus : '/advance-status',
    AdvanceStatusUser : '/advance-status/employee',
    AdvanceStatusDetail : '/advance-status',
    AdminControl : '/admin-controls',
    CompanyDetail : '/admin-controls/company-detail',
    ProcessingFeeControl : '/processingfee-controls',
    manageAdmin : '/manage-admin',
    manageAdminList : '/manage-admin',
    manageAdminDetails : '/manage-admin/details',
    manageAdminAdd : '/manage-admin/add-admin',

    // employer
    EmployerList : "employer",

    // refer your employer
    referedEmployer: '/refered-employer'
}