import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { RouterConstant } from '../../constants/RouteConstant'
import LoaderWrapper from '../LoaderWrapper/LoaderWrapper'
import './logout.scss'
const Logout = ({ isOpen, toggleLogout }) => {
    
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        setLoader(true)
        setTimeout(()=>{
            setLoader(false)
            navigate(RouterConstant.Login)
        }, 500)
    }
    return (
        <>{
            loader ? <LoaderWrapper /> :
        <Modal
            isOpen={isOpen}
            toggle={toggleLogout}
            centered
        >
            <ModalHeader toggle={toggleLogout}>
                Logout
            </ModalHeader>
            <ModalBody className="logout-body text-center">
                Are you sure you want to logout ?
            </ModalBody>
            <ModalFooter className='justify-content-end'>
                    <Button
                        className="btn primary-btn min-padding"
                        onClick={handleLogout}>
                        Logout
                    </Button>
                    <Button
                        className="btn white-btn min-padding ms-3"
                        onClick={toggleLogout}
                    >
                        Cancel
                    </Button>
            </ModalFooter>
        </Modal>
        }
        </>
    )
}

export default Logout
