import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import './profilestatus.scss';
import { Input, Label, FormGroup, FormText, FormFeedback, Row, Col } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form'
import { companyNames, dayDate } from '../../../constants/StringsConstant';
import { editEmployee, validateNinCode, paystackBankAccountList, companyNameList, getCompanyContractorSite } from '../../../API/Services/authService';
import { useAlert } from 'react-alert';
import { useParams } from 'react-router-dom';
import { getDaysList } from '../../../helper/dateData';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import LoaderWrapper from '../../../Components/LoaderWrapper/LoaderWrapper'

const UpdateDetail = ({ state, toggleField, data, updateField, apiData }) => {
    const [dateOfMonth, setDateOfMonth] = useState([]);
    const [isAmountWarning, setAmmountConfirmation] = useState(false);
    const [isNinDisplay, seNinDisplayModal] = useState(false);
    const [ninApiData, setNinApiresponse] = useState({});
    const [allData, setAmountData] = useState({});
    const [allBankData, setBankData] = useState([]);
    const [allCompanyList, setCompanyLists] = useState([]);
    const [allContractors, setContractorList] = useState([]);
    const [allSites, setSiteLists] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isNinerrorDisp, setNinErrorDisp] = useState(false);
    const [isAccountNumberErrorDisp, setAccountNumberErrorDisp] = useState(false);

    useEffect(() => {
        if (state) {
            let date = new Date();
            setDateOfMonth(getDaysList(date));
            getAllBankList();
            companyList();
            if (data?.field === 'contractor') {
                let param = { companyName: data['companyDetails'] }
                contractorSites(param);
            }
        }

    }, [state])

    let { field, fieldData, feildType, fieldValidation, fieldLabel } = data;





    const [file, setFile] = useState({})
    const alert = useAlert();
    let { userId } = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState,
        formState: { errors, isDirty, isSubmitSuccessful },
        control,
    } = useForm();

    useEffect(() => {
        setValue(field, fieldData)
        // setValue('holderName', bankDetails?.accountHolderName)
        // setValue('acc', bankDetails?.accountNo)
    }, [data])

    useEffect(() => {
        // reset();
        // if (isSubmitSuccessful) {
        //     reset();
        // }
    }, [isSubmitSuccessful, reset])
    // call APi for Bank lists
    const getAllBankList = () => {
        setLoader(true);
        paystackBankAccountList()
            .then((response) => {
                setLoader(false)
                if (response?.data?.status) {
                    setBankData(response?.data?.data?.data)
                } else {
                    console.log("test login api", response)
                }

            })
            .catch(error => {
                setLoader(false);
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
            })

    }

    // Api of Company Lists
    const companyList = () => {
        setLoader(true);
        companyNameList().then((response) => {
            setLoader(false);
            if (response?.data?.status) {
                setCompanyLists(response?.data?.data)
            } else {
                console.log("test login api", response)
            }
        })
            .catch(error => {
                setLoader(false);
                console.log("catch block", error)
                alert.error(error?.message);
            })
    }


    // Api's of Contractors List 
    const contractorSites = (param) => {
        setLoader(true);
        getCompanyContractorSite(param).then((response) => {
            setLoader(false);
            if (response?.data?.status) {
                setContractorList(response?.data?.data?.contractors);
                setSiteLists(response?.data?.data?.sites);
            } else {
                console.log("test login api", response)
            }
        })
            .catch(error => {
                setLoader(false);
                console.log("catch block", error)
                alert.error(error?.message);
            })
    }




    const handleSubmitData = (formData) => {
        let formField = new FormData();
        if (formData?.mobile && formData?.mobile?.length > 10) {
            let newFormField = formData.mobile.substring(1)
            formField.append(field, newFormField)
        } else if (feildType === "file") {
            formField.append(field, file)
        } else if (feildType === 'select' && field === 'dob') {
            let tempDOB = moment(formData[field]).format('DD/MM/YYYY')
            formField.append(field, tempDOB)
            console.log('tempDOB', tempDOB)
        }
        else if (feildType === 'select' && field === 'bankName') {
            let bankname = allBankData.find((e) => {
                return e.name === formData[field]
            })
            formField.append('bankCode', bankname?.code);
            formField.append('bankName', bankname?.name);
        }
        else if (feildType === 'select' && field === 'companyName') {
            let param = { companyName: formData[field] }
            contractorSites(param);
            formField.append('companyName', formData[field]);
        }
        else if (feildType === 'number' && field === 'nin') {
            formField.append('nin', formData[field]);
            if (formData[field].length != 11) {
                setNinErrorDisp(true);
                return;
            }
        }
        else if (feildType === 'number' && field === 'accountNo') {
            formField.append('accountNo', formData[field]);
            if (formData[field].length != 10) {
                setAccountNumberErrorDisp(true);
                return;
            }
        }

        else {
            formField.append(field, formData[field])
        }

        if (field == 'accountLimit') {
            let isNumber = isNaN(apiData['workDetails']['salary']);
            if (!isNumber) {
                let halfAmount = apiData['workDetails']['salary'] * 50 / 100;
                if (halfAmount < formData?.accountLimit) {
                    setAmmountConfirmation(!isAmountWarning)
                    formField.append("userId", userId)
                    setAmountData(formField);
                    return;
                }
            }

        }
        formField.append("userId", userId);

        callApi(formField);
    }

    const callApi = (formField) => {
        setLoader(true);
        editEmployee(formField)
            .then(res => {
                setLoader(false);
                if (res?.statusText === 'OK') {
                    updateField(false);
                    alert.success(res?.data?.message)
                }
            })
            .catch(err => {
                setLoader(false);
                console.log(err)
                alert.error(err?.message)
            })
    }

    // confirm Amount submit more than 50 of salary
    const confirmAmountSubmit = () => {
        callApi(allData);
        setAmmountConfirmation(false);

    }

    const closeAmountDialog = () => {
        setAmmountConfirmation(!isAmountWarning)
    }



    const handleFileChnage = (e) => {
        setFile(e.target.files[0]);
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    const maxlimitNumber = (e) => {
        setNinErrorDisp(false);
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength);
        }

    }

    const maxlimitAccount = (e) => {
        setAccountNumberErrorDisp(false);
        if (e.target.value.length > e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength);
        }
    }

    return (
        <>
            <Modal
                isOpen={state}
                toggle={toggleField}
                className="profile-status-wrapper"
                centered={true}
            >
                {/* <ModalHeader toggle={toggleProfileStatus}>
                        Profile Status
                    </ModalHeader> */}
                <ModalBody toggle={toggleField}>
                    {/* <div className="profile-status-wrapper"> */}
                    <form className="profile-status" onSubmit={handleSubmit(handleSubmitData)}>
                        <div className="heading-wrapper">
                            <h4 className="heading fw-bold">Update</h4>
                        </div>
                        <div className="body-content-wrapper">
                            <div className="update-field mb-3 mt-4">
                                <label className="field-label mb-2">{fieldLabel}</label>


                                {
                                    feildType === "select" && field === "gender" ?
                                        <Controller
                                            render={({ field: { onChange, value } }) => (
                                                <Input type="select" className={`form-control input-box p-3 ${errors.gender ? "not-valid" : ""}`} style={{ color: "#A1A1A1" }}
                                                    value={value}
                                                    id="gender"
                                                    onChange={onChange}
                                                >
                                                    <option value="" selected disabled>Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Other</option>
                                                </Input>
                                            )}
                                            control={control}
                                            name="gender"
                                            rules={{ required: "Please select your gender" }}
                                        /> :
                                        feildType === "select" && field === "salaryDueDate" ?
                                            <Controller
                                                render={({ field: { onChange, value } }) => (
                                                    <Input type="select" className={`form-control input-box p-3 ${errors.dueDate ? "not-valid" : ""}`} style={{ color: "#A1A1A1" }}
                                                        value={value}
                                                        id="dueDate"
                                                        onChange={onChange}
                                                    >
                                                        <option value="" selected disabled>Salary Due Date</option>
                                                        {dayDate?.map((date, index) => {
                                                            return <option value={index + 1} key={index}>{date.day}</option>
                                                        })}
                                                    </Input>
                                                )}
                                                control={control}
                                                name="salaryDueDate"
                                                rules={{ required: "Please select salary due date" }}
                                            /> :
                                            feildType === "select" && field === "salarycreditDay" ?
                                                <Controller
                                                    render={({ field: { onChange, value } }) => (
                                                        <Input type="select" className={`form-control input-box p-3 ${errors.lastDate ? "not-valid" : ""}`}
                                                            value={value}
                                                            onChange={onChange}
                                                        >
                                                            <option value="" selected disabled>Select date</option>
                                                            {dayDate?.map((date, index) => {
                                                                return <option value={index + 1} key={index}>{date.day}</option>
                                                            })}
                                                        </Input>
                                                    )}
                                                    control={control}
                                                    name="salarycreditDay"
                                                    rules={{ required: "Please fill your last date of salary payment" }}
                                                />
                                                :
                                                feildType === "select" && field === "companyName" ?

                                                    <Controller
                                                        render={({ field: { onChange, value } }) => (
                                                            <Input type="select" className={`form-control input-box p-3 ${errors.companyName ? "not-valid" : ""}`}
                                                                value={value}
                                                                id="company-name"
                                                                onChange={onChange}
                                                            >
                                                                <option value="" selected disabled>Company Name</option>
                                                                {allCompanyList?.map((company, key) => {
                                                                    return <option value={company.companyName} key={key}>{company.companyName}</option>
                                                                })}
                                                                {/* <option value="Johns Group">Johns Group</option>
                                    <option value="Yost and Sons">Yost and Sons</option>
                                    <option value="Abernathy Group">Abernathy Group</option> */}
                                                            </Input>
                                                        )}
                                                        control={control}
                                                        name="companyName"
                                                        rules={{ required: "Please select company name" }}
                                                    />
                                                    :
                                                    feildType === "select" && field === "bankName" ?
                                                        <Controller
                                                            render={({ field: { onChange, value } }) => (
                                                                <Input type="select" className={`input-box form-control p-3 ${errors.bankName ? "not-valid" : ""}`}
                                                                    // {...register('bankName', { required: true })}
                                                                    id="bank-name"
                                                                    value={value}
                                                                    onChange={onChange}
                                                                >
                                                                    <option value="" selected disabled>Select Bank</option>
                                                                    {
                                                                        allBankData?.map((bank, key) => {
                                                                            return <option value={bank.name} key={key}>{bank.name}</option>

                                                                        })
                                                                    }
                                                                </Input>
                                                            )}
                                                            control={control}
                                                            name="bankName"
                                                            rules={{ required: "Please select bank" }}
                                                        /> :
                                                        feildType === "select" && field === "contractor" ?
                                                            <Controller
                                                                render={({ field: { onChange, value } }) => (
                                                                    <Input type="select" className={`input-box form-control p-3 ${errors.contractor ? "not-valid" : ""}`}
                                                                        // {...register('bankName', { required: true })}
                                                                        id="contractor-name"
                                                                        value={value}
                                                                        onChange={onChange}
                                                                    >
                                                                        <option value="" selected disabled>Select Contractor</option>
                                                                        {
                                                                            allContractors?.map((contractor, key) => {
                                                                                return <option value={contractor} key={key}>{contractor}</option>

                                                                            })
                                                                        }
                                                                    </Input>
                                                                )}
                                                                control={control}
                                                                name="contractor"
                                                                rules={{ required: "Please select Contractor" }}
                                                            /> :
                                                            feildType === "select" && field === "site" ?
                                                                <Controller
                                                                    render={({ field: { onChange, value } }) => (
                                                                        <Input type="select" className={`input-box form-control p-3 ${errors.site ? "not-valid" : ""}`}
                                                                            // {...register('bankName', { required: true })}
                                                                            id="site-name"
                                                                            value={value}
                                                                            onChange={onChange}
                                                                        >
                                                                            <option value="" selected disabled>Select Location</option>
                                                                            {
                                                                                allSites?.map((site, key) => {
                                                                                    return <option value={site} key={key}>{site}</option>

                                                                                })
                                                                            }
                                                                        </Input>
                                                                    )}
                                                                    control={control}
                                                                    name="site"
                                                                    rules={{ required: "Please select Location" }}
                                                                /> :
                                                                feildType === "select" && field === "dob" ?
                                                                    <Controller
                                                                        render={({ field: { onChange, value } }) => (
                                                                            <ReactDatePicker
                                                                                showPopperArrow={false}
                                                                                className={`form-control input-box p-3 ${errors.dob ? "not-valid" : ""}`}
                                                                                dateFormat="dd-mm-yyyy"
                                                                                selected={moment(value).toDate()}
                                                                                onChange={onChange}
                                                                                placeholderText="Date of birth"
                                                                                maxDate={new Date()}
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                                scrollableYearDropdown

                                                                            // withPortal
                                                                            />
                                                                        )}
                                                                        control={control}
                                                                        name="dob"
                                                                        rules={{ required: "Please fill your Dob" }}
                                                                    />
                                                                    :

                                                                    feildType === "number" && field === 'nin' ?
                                                                        <input
                                                                            className={`input-box form-control p-3`} onKeyPress={preventMinus}
                                                                            onInput={maxlimitNumber}
                                                                            type="number"
                                                                            maxlength={11} minlength={11}
                                                                            {...register(field,)} />

                                                                        : feildType === "number" && field === 'accountNo' ?
                                                                            <input
                                                                                className={`input-box form-control p-3`} onKeyPress={preventMinus}
                                                                                onInput={maxlimitAccount}
                                                                                type="number"
                                                                                maxlength={10} minlength={10}
                                                                                {...register(field,)} />
                                                                            :
                                                                            feildType === "file" ?
                                                                                <>

                                                                                    <input type="file" className={`input-box form-control p-3`}
                                                                                        {...register(field)}
                                                                                        onChange={handleFileChnage}
                                                                                    />
                                                                                    <div style={{ width: "150px", margin: '30px auto' }}>
                                                                                        {
                                                                                            fieldData ?
                                                                                                <p>Please Update</p> :
                                                                                                <p>Please upload file</p>
                                                                                        }
                                                                                    </div>
                                                                                    {/* {errors.bankName && errors.bankName.type === "required" && <small className="error-msg">Please select your bank</small>} */}
                                                                                </> :
                                                                                <input type="text" className={`input-box form-control p-3`}
                                                                                    {...register(field,)}
                                                                                />
                                }


                            </div>
                            {isNinerrorDisp ?
                                <small className="error-msg">Please enter a valid NIN</small>
                                :
                                null
                            }
                            {isAccountNumberErrorDisp ?
                                <small className="error-msg">Account Number should be 10 digits</small>
                                :
                                null
                            }


                            {errors[field] && errors[field]?.type === "required" && <small className="error-msg">{data?.fieldValidation}</small>}
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn primary-btn min-padding ms-2">Update</button>
                            <button type="button" onClick={toggleField} className="btn bordered-btn min-padding ms-2">Cancel</button>
                        </div>
                    </form>
                    {/* </div> */}
                    {
                        loader ? <LoaderWrapper /> : ''
                    }
                </ModalBody>
            </Modal>

            {
                loader ? <LoaderWrapper /> : ''
            }

            <Modal
                isOpen={isAmountWarning}
                toggle={closeAmountDialog} centered>
                <ModalHeader toggle={closeAmountDialog}>
                    Ammount Confirmation
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div>
                        <p className='mb-3'>Are you sure you want to set this limit?</p>
                        <div className='d-flex justify-content-end'>
                            <Button
                                className="btn primary-btn min-padding ms-2" onClick={confirmAmountSubmit} >
                                Yes
                            </Button>
                            <button type="button" onClick={closeAmountDialog} className="btn bordered-btn min-padding ms-2">Cancel</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/* 
            <Modal
                isOpen={isNinDisplay}
            >
                <ModalHeader>
                    Nin Confirmation
                </ModalHeader>
                <ModalBody className="logout-body">
                    <div>

                        <p>First Name:{ninApiData?.entity?.firstname}</p>
                        <p>NIN:{ninApiData?.entity?.nin}</p>
                        <p>BirthCountry Code:{ninApiData?.entity?.birthcountry}</p>
                        <Button
                            className="btn-logout btn-color-grey" onClick={confirmAmountSubmit}>
                            Accept
                        </Button>
                        <Button
                            className="btn-logout btn-color-grey" onClick={rejectNin} >
                            Reject
                        </Button>
                    </div>
                </ModalBody>
            </Modal> */}

        </>
    );
}

export default UpdateDetail;