import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useParams, useLocation } from 'react-router-dom'
import { getAllUser, resetEmpPayments } from '../../API/Services/authService'
import { useAlert } from "react-alert";
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import ReactPaginate from 'react-paginate';
import { ReactComponent as Asc } from '../../assets/images/icons/assending.svg';
import { ReactComponent as Desc } from '../../assets/images/icons/decending.svg';
import _ from "lodash";
import moment from 'moment'
import SearchWrapper from '../../Components/Search';
import { RouterConstant } from '../../constants/RouteConstant';
import { CSVLink } from "react-csv";
import { AppContext } from '../../AppContext';
const Employees = () => {
    // let { userId } = useParams();
    const pagination = useRef();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [profileType, setProfiteType] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchString, setSearchString] = useState('');
    const delayedQuery = useRef(_.debounce(q => sendQuery(q), 500)).current;
    const [searchQuery, setSearchQuery] = useState('');
    const [profilePercentage, setProfilePercentage] = useState(0);
    const [csvData, setCsvData] = useState([]);
    const { state } = useLocation();
    const { userProfile, userData } = useContext(AppContext);
    const [sorting, setSorting] = useState({
        order: 1,
        keyName: 'createdDate',
    });

    useEffect(() => {
        userProfile()
    }, [])

    const userPermission = (permissionMatched) => {
        // let allMatched = false
        if (userData?.role === "admin") {
            return true
        } else {
            if (userData?.permissions?.length) {
                return userData?.permissions?.some((section) => section === permissionMatched);
            }
        }
    }


    const headers = [
        { label: "User ID", key: "userId" },
        { label: "User Name", key: "userName" },
        { label: "Mobile No", key: "mobileNo" },
        { label: "Request Id", key: "gender" },
        { label: "Profile Completion", key: "profileCompletion" },
        { label: "Company Name", key: "companyName" },
        { label: "Date", key: "date" },
        { label: "Time", key: "time" },
    ];

    const handleClickDownload = async () => {
        setLoader(true);
        let tempCsvData = [];
        let limit = 50;
        let totalLoopCount = apiData?.total / limit;

        for (let i = 0; i < totalLoopCount; i++) {
            await getAllUser(profileType, sorting, i + 1, limit, searchString)
                .then((response) => {
                    if (response?.data?.status === 'OK') {
                        // setApiData(response?.data?.data);
                        let requestData = response?.data?.data?.users;
                        requestData.map(user => {
                            tempCsvData.push(
                                {
                                    userId: (user?.generatedUserId) ? (user?.generatedUserId) : '-',
                                    userName: (user?.fullName) ? (user?.fullName) : `${user.firstName} ${user.lastName}`,
                                    mobileNo: `${user?.mobileCountryCode} ${user?.mobile}`,
                                    gender: (user?.gender) ? (user?.gender) : '-',
                                    profileCompletion: ProfilePercent(user),
                                    companyName: (user?.companyName) ? (user?.companyName) : '-',
                                    date: moment(user?.createdDate).format('DD/MM/YYYY'),
                                    time: moment(user?.createdDate).format('LT'),
                                }
                            )
                        });
                        // ProfilePercent(response?.data?.data)
                        // alert.success(response?.data?.isError);
                        // navigate(RouterConstant.Layout);

                    } else {
                        alert.error(response?.message);
                    }
                    // setLoader(false)
                })
                .catch(error => {
                    console.log("catch block", error)
                    alert.error(error?.message);
                    // setLoader(false)
                })

        }
        setCsvData(current => [...current, ...tempCsvData]);
    };

    useEffect(() => {
        if (csvData.length > 0) {
            const downloadElement = document.getElementById("clickDownload");
            setTimeout(() => {
                downloadElement.click();
                setLoader(false);
                setCsvData([])
            }, 3000)
        }
    }, [csvData]);


    useEffect(() => {
        if (state) {
            const { tabId } = state;
            if (tabId != null) {
                setProfiteType(tabId);
            }
        }


    }, [])
    useEffect(() => {

        getAllUserList();
    }, [profileType, sorting, pageNumber, limit, searchQuery])

    const getAllUserList = () => {
        setLoader(true)
        getAllUser(profileType, sorting, pageNumber, limit, searchString)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data);
                    ProfilePercent(response?.data?.data)
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })

    }



    const setPage = ({ selected }) => {
        let pageNo = selected + 1
        setPageNumber(pageNo);
        // getStoreValue(pageNo)
    }

    const handleAdminFilter = (profileType) => {
        //  setPageNumber(1);
        setLimit(10);
        setPage({ selected: 0 });
        setProfiteType(profileType);
        // getAllUserList(profileType)
    }

    //  
    const handleSorting = (sortKey, sortOrder) => {
        setSorting({
            order: sortOrder,
            keyName: sortKey
        })
    }

    const handleSearch = (event) => {
        setSearchString(event.target.value);
        setPageNumber(1)
        delayedQuery(event.target.value);
    }
    const sendQuery = query => {
        setSearchQuery(query)
    };

    const ProfilePercent = (data) => {
        let x = 0
        if (data?.fieldStatus) {
            let tempPercentage = Object.values(data?.fieldStatus)
            let counts = {}
            for (const num of tempPercentage) {
                counts[num] = counts[num] ? counts[num] + 1 : 1;
            }
            counts[2] = counts[2] ? counts[2] : 0
            x = ((counts[2] / tempPercentage.length) * 100)
        } else {
            x = 0;
        }
        return x;

    }

    const resetPay = (userID) => {
        setLoader(true)
        resetEmpPayments(userID)
            .then((res => {
                if (res?.data?.status === 'OK') {
                    setLoader(false);
                    alert.success(res?.data?.message);
                } else {
                    console.log("test login api", res)
                }
                setLoader(false)
            }))
            .catch((err => {
                setLoader(false)
                alert.error(err?.message);
            }))
    }

    return (
        <>
            <div className="top-filter-button">
                <ul className="list-unstyled btn-filter-list">
                    {
                        userPermission('employee-incomplete') &&
                        <li className={`btn white-btn ${profileType === 1 && 'active'}`} onClick={() => handleAdminFilter(1)}>Incomplete Profile</li>
                    }
                    {
                        userPermission('kyc-pending') &&
                        <li className={`btn white-btn ${profileType === 2 && 'active'}`} onClick={() => handleAdminFilter(2)}>KYC Pending</li>
                    }
                    {
                        userPermission('kyc-rejected') &&
                        <li className={`btn white-btn ${profileType === 3 && 'active'}`} onClick={() => handleAdminFilter(3)}>KYC Rejected</li>
                    }
                    {
                        userPermission('employee-completed') &&
                        <li className={`btn white-btn ${profileType === 4 && 'active'}`} onClick={() => handleAdminFilter(4)}>Complete Profile</li>
                    }
                </ul>
                <SearchWrapper
                    type='text'
                    name='searchString'
                    className='mb-3 mt-4 mt-md-0'
                    value={searchString}
                    onChange={handleSearch}
                    placeholder="Search..."
                />
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <span className="export-csv" onClick={handleClickDownload}>
                    <i className="fa fa-download"></i>Export
                </span>
            </div>
            <CSVLink id="clickDownload" data={csvData} headers={headers}></CSVLink>
            <div className="data-table table-responsive">
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">User ID</th>
                            <th scope="col">Name
                                {
                                    (sorting['keyName'] === 'fullName') && (sorting['order'] === -1) ? <Desc className="ascending_icons ms-3" onClick={() => handleSorting('fullName', 1)} />
                                        : <Asc className="ascending_icons ms-3" onClick={() => handleSorting('fullName', -1)} />
                                }
                            </th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Profile Completion</th>
                            <th scope="col">Company Name
                                {
                                    (sorting['keyName'] === 'companyName') && (sorting['order'] === -1) ? <Desc className="ascending_icons ms-3" onClick={() => handleSorting('companyName', 1)} />
                                        : <Asc className="ascending_icons ms-3" onClick={() => handleSorting('companyName', -1)} />
                                }
                            </th>
                            <th scope="col">Date
                                {
                                    (sorting['keyName'] === 'createdDate') && (sorting['order'] === -1) ? <Desc className="ascending_icons ms-3" onClick={() => handleSorting('createdDate', 1)} />
                                        : <Asc className="ascending_icons ms-3" onClick={() => handleSorting('createdDate', -1)} />
                                }
                            </th>
                            <th scope="col">Time</th>
                            {
                                (userData?.role === "admin" && profileType === 4) &&
                                <th scope="col">Reset Pay</th>
                            }
                            <th scope="col">More</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            apiData?.users?.map((user, index) => {

                                return <tr key={index}>
                                    <td scope="row" className="pt-md-4 pt-3">{((index + 1) + ((pageNumber - 1) * limit))}</td>
                                    <td className="pt-md-4 pt-3">{(user?.generatedUserId) ? (user?.generatedUserId) : '-'}</td>
                                    <td className="pt-md-4 pt-3">{(user?.fullName) ? (user?.fullName) : `${user.firstName} ${user.lastName}`}</td>
                                    <td className="pt-md-4 pt-3">{user?.mobileCountryCode} {user?.mobile}</td>
                                    <td className="pt-md-4 pt-3">{(user?.gender) ? (user?.gender) : '-'}</td>
                                    <td className="pt-md-4 pt-3">{ProfilePercent(user)}%</td>
                                    <td className="pt-md-4 pt-3">{(user?.companyName) ? (user?.companyName) : '-'}</td>
                                    <td className="pt-md-4 pt-3">{moment(user?.createdDate).format('DD/MM/YYYY')}</td>
                                    <td className="pt-md-4 pt-3">{moment(user?.createdDate).format('LT')}</td>
                                    {
                                        (userData?.role === "admin" && profileType === 4) &&
                                        <td className="pt-md-4 pt-3"><span className='link-primary cursor-pointer' onClick={() => resetPay(user?._id)}>Reset</span></td>
                                    }
                                    <td className="pt-md-4 pt-3"><NavLink to={`${RouterConstant.ViewEmployee}/${user?._id}`} className="heighlight">view more</NavLink></td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
                <div className="my-5 d-flex justify-content-center">
                    <ReactPaginate
                        ref={pagination}
                        pageCount={apiData?.total / limit}
                        pageRangeDisplayed={limit}
                        marginPagesDisplayed={1}
                        onPageChange={setPage}
                        containerClassName="pagination"
                        activeClassName="active"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        nextClassName="page-item"
                        previousClassName="page-item"
                        previousLabel={<>&laquo;</>}
                        nextLabel={<>&raquo;</>}
                    />
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> : ''
            }
        </>
    )
}

export default Employees
