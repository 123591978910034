import React, { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert';
import { Link, useParams, useNavigate } from 'react-router-dom'
import { changeAmountRequest, changeRequestStatus, getRequestById, getRequestDetail } from '../../API/Services/authService';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { RouterConstant } from '../../constants/RouteConstant';
import { ReactComponent as Arrow } from './../../assets/images/icons/right_black_arrow.svg'
import { AppContext } from '../../AppContext';

const ViewRequest = () => {
    let navigate = useNavigate()
    let { requestId } = useParams();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState({});
    const [requestData, setRequestData] = useState([]);
    const [editRequestAmount, setEdiRequestAmount] = useState(false);
    const [requestWarning, setRequestWarning] = useState(false);
    const [requAmt, setReqAmt] = useState(0);
    const [openRejectModal, setRejectStatus] = useState(false);
    const [rejectdReason, setRejectedReason] = useState('');
    const [chanegAmountBtnDble, SetChanegAmountBtnDble] = useState(false);
    const { userData } = useContext(AppContext);
    const advanceStatusAllString = ['live-request', 'past-request', 'lapse-request'];
    // useEffect(()=>{
    //     userProfile()
    // },[])
    const alert = useAlert();
    useEffect(() => {
        getAllUserList();
    }, [])



    const userPermission = (permissionMatched) => {
        // let allMatched = false
        if (userData?.role === "admin") {
            return true
        } else {
            if (userData?.permissions?.length) {
                return permissionMatched.every((str) => userData?.permissions.includes(str));
            }
        }
    }


    const getAllRequest = (userId) => {
        setLoader(true)

        getRequestById(userId)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setRequestData(response?.data?.data?.requests)
                } else {
                    alert.success(response?.data?.isError);
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })
    }
    const getAllUserList = () => {
        setLoader(true)
        getRequestDetail(requestId)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data?.reqData);
                    setReqAmt(response?.data?.data?.reqData?.requestedAmount)
                    getAllRequest(response?.data?.data?.reqData?.userId);

                } else {
                    alert.success(response?.data?.isError);
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })

    }
    const handleRequestStatus = (e) => {
        setLoader(true)
        let requestStatus = e.target.value;
        let data = {
            "requestId": requestId,
            "status": requestStatus,
        }
        changeRequestStatus(data)
            .then(response => {
                setLoader(false)
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message)
                    navigate(RouterConstant.Request, { state: { tabId: "pending" } });
                }
                else {
                    console.log("test login api", response)
                }
            })
            .catch(error => {
                setLoader(false)
                console.log(error)
                alert.error(error?.message)
            })
    }

    // open edit request Amount
    const handleToggleLogout = () => {
        setEdiRequestAmount(!editRequestAmount);
    }



    // edit request Amount 
    const requestAmtCall = () => {
        setRequestWarning(true);
    }

    const changeRequestAmt = (e) => {
        if (apiData?.availableBalence < e.target.value) {
            SetChanegAmountBtnDble(true);
        } else {
            SetChanegAmountBtnDble(false);
        }
        setReqAmt(e.target.value);
    }

    const editRequestAmt = () => {

        setLoader(true)
        let param = {
            "requestedAmount": parseInt(requAmt),
            "requestId": requestId
        }
        changeAmountRequest(param)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setEdiRequestAmount(false);
                    setRequestWarning(false)
                    getAllUserList()
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })
    }

    const handleToggleReject = () => {
        setRejectStatus(!openRejectModal)
    }

    const submitReject = () => {
        setLoader(true)
        let data = {
            "requestId": requestId,
            "status": 'rejected',
            "rejectComment": rejectdReason
        }
        changeRequestStatus(data)
            .then(response => {
                setLoader(false);
                setRejectStatus(false)
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message)
                    getAllUserList()
                }
                else {
                    console.log("test login api", response)
                }
            })
            .catch(error => {
                setLoader(false)
                console.log(error)
                alert.error(error?.message)
            })
    }

    const handleReject = (e) => {
        setRejectedReason(e.target.value);
    }





    return (
        <>
            <div className="view-details-wrapper">
                <div className="profile-header">
                    <h4 className="view-details-heading text-capitalize">{apiData?.accountHolderName} <span> User ID: {apiData?.generatedUserId}</span></h4>
                </div>
                <div className="row gx-5">
                    <div className="col-md-4 mb-4">
                        <div className="profile-section-card">
                            <div className="head-wrap">
                                <h3 className="heading text-info">Total Requests Made</h3>

                            </div>
                            <div className="detail-field">
                                <div className="field-wrapper">
                                    <div className="form-group">
                                        <p className="value">{apiData?.totalRequest}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="profile-section-card">
                            <div className="head-wrap">
                                <h3 className="heading text-danger">Total Rejected Requests</h3>

                            </div>
                            <div className="detail-field">
                                <div className="field-wrapper">
                                    <div className="form-group">
                                        <p className="value">{apiData?.rejectedRequest}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mb-4">
                        <div className="profile-section-card">
                            <div className="head-wrap">
                                <h3 className="heading text-success">Total Approved Requests</h3>

                            </div>
                            <div className="detail-field">
                                <div className="field-wrapper">
                                    <div className="form-group">
                                        <p className="value">{apiData?.approvedRequest}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="profile-section-card">
                            <div className="head-wrap">
                                <h3 className="heading text-success">Total Lapsed</h3>

                            </div>
                            <div className="detail-field">
                                <div className="field-wrapper">
                                    <div className="form-group">
                                        <p className="value">{apiData?.lapsedRequest}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        apiData?.status === 'pending' ?
                            <div className="col-md-4 mb-4">
                                <div className="profile-section-card">
                                    <div className="head-wrap">
                                        <h3 className="heading text-success">Amount Requested</h3>
                                    </div>
                                    <div className="detail-field">
                                        <div className="field-wrapper">
                                            <div className="form-group d-flex justify-content-between mb-2">
                                                <p className="value">{apiData?.requestedAmount}</p>
                                                <div className='d-flex flex-column align-items-center cursor-pointer'
                                                    onClick={handleToggleLogout}>
                                                    <i class="fa fa-edit text-primary"></i>
                                                    <span className="d-block">Edit</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            null
                    }

                    <div className="col-md-4 mb-4">
                        <div className="profile-section-card">
                            <div className="head-wrap">
                                <h3 className="heading text-success">Available Balance</h3>

                            </div>
                            <div className="detail-field">
                                <div className="field-wrapper">
                                    <div className="form-group">
                                        <p className="value">{apiData?.availableBalence}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <ul className="list-unstyled request-redirection">
                        <li className="redirection-item">
                            <Link
                                className="redirection-link"
                                to={`${RouterConstant.ViewEmployee}/${apiData?.userId}`}>
                                <span>Profile</span>
                                <span className="icon-arrow">
                                    <Arrow />
                                </span>
                            </Link>
                        </li>
                        {
                            userPermission(advanceStatusAllString) &&
                            <li className="redirection-item">
                                <Link
                                    to={`${RouterConstant.AdvanceStatus}`}
                                    state={{ userId: apiData?.userId }}
                                    className='redirection-link'
                                >
                                    <span>Advance Status</span>
                                    <span className="icon-arrow">
                                        <Arrow />
                                    </span>
                                </Link>

                            </li>
                        }
                        <li className="redirection-item">
                            <Link
                                to={`${RouterConstant.Request}`}
                                state={{ userId: apiData?.userId }}
                                className='redirection-link'
                            >
                                <span>User Request</span>
                                <span className="icon-arrow">
                                    <Arrow />
                                </span>
                            </Link>
                        </li>
                    </ul>


                    {apiData?.status === 'pending' ?
                        <div className="col-12 mb-5">
                            <div className='row justify-content-between'>
                                {/* <div className='col-md-3'>
                                    <button className="btn" onClick={handleRequestStatus} value="paid" disabled={(apiData?.status === 'pending' || apiData?.status === 'rejected' || apiData?.isPaid === true)}>Pay</button>
                                </div> */}
                                {/* <div className='col-md-3'>
                                    <button className="btn success-btn" value="approved" disabled={(apiData?.status === 'approved' || apiData?.status === 'rejected')}>Send for HR Approval</button>
                                </div> */}
                                <div className='col-md-3'>
                                    <button className="btn primary-btn" onClick={handleRequestStatus} value="approved" disabled={(apiData?.status === 'approved' || apiData?.status === 'rejected')}>Approve</button>
                                </div>
                                <div className='col-md-3'>
                                    <button className="btn white-btn" onClick={handleToggleReject} value="rejected" disabled={(apiData?.status === 'approved' || apiData?.status === 'rejected')}>Reject</button>
                                </div>
                            </div>
                        </div>
                        : ''
                    }
                </div>
            </div>
            {
                loader ? <LoaderWrapper /> : ''
            }

            <Modal
                isOpen={editRequestAmount}
                toggle={handleToggleLogout} centered>
                <ModalHeader toggle={handleToggleLogout}>
                    Edit Requested Amount
                </ModalHeader>
                <ModalBody className="logout-body">
                    {
                        requestWarning
                            ?
                            <>
                                <p className='text-center'>Are you sure you want change the request Amount?</p>
                                <div className='d-flex justify-content-end mt-4'>
                                    <Button
                                        className="btn primary-btn min-padding ms-2"

                                        onClick={editRequestAmt} >
                                        Yes
                                    </Button>
                                    <button
                                        className="btn bordered-btn min-padding ms-2"

                                        onClick={handleToggleLogout} >
                                        Cancel
                                    </button>
                                </div>
                            </>
                            :
                            <>

                                <input type="text" className='form-control p-3' placeholder='Requested Amount'
                                    value={requAmt} onChange={changeRequestAmt} />
                                <div className='d-flex justify-content-end'>
                                    <Button disabled={chanegAmountBtnDble}
                                        className="btn primary-btn min-padding ms-2"
                                        onClick={requestAmtCall}>
                                        Submit
                                    </Button>
                                    <button disabled={chanegAmountBtnDble}
                                        className="btn bordered-btn min-padding ms-2"
                                        onClick={handleToggleLogout}>
                                        Cancel
                                    </button>
                                </div>
                            </>

                    }


                </ModalBody>

            </Modal>

            <Modal
                isOpen={openRejectModal}
                toggle={handleToggleReject}>
                <ModalHeader toggle={handleToggleReject}>
                    Reject Request
                </ModalHeader>
                <ModalBody className="logout-body">
                    <p className='text-center mb-4'>Are you sure you want to reject this request?</p>
                    <textarea rows={5} type="text" className='form-control' placeholder='Reason'
                        value={rejectdReason} onChange={handleReject} />
                    <div className='d-flex justify-content-end mt-4'>
                        <Button className="btn primary-btn min-padding ms-2" onClick={submitReject}>
                            Yes
                        </Button>
                        <button disabled={chanegAmountBtnDble}
                            className="btn bordered-btn min-padding ms-2"
                            onClick={handleToggleReject}>
                            Cancel
                        </button>
                    </div>

                </ModalBody>

            </Modal>


        </>
    )
}

export default ViewRequest
