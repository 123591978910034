import React, { useEffect, useRef, useState } from 'react'
import { changeRequestStatus, getAdvanceStatusList, getRequestDetail } from '../../API/Services/authService'
import { useAlert } from "react-alert";
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import { useParams } from 'react-router';
import moment from 'moment'
import { RouterConstant } from '../../constants/RouteConstant';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import { calculateNextMonthDate } from '../../helper/helper';

const AdvanceStatusDetail = () => {
    let { advanceId } = useParams();
    let navigate = useNavigate()
    const pagination = useRef();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [requAmt, setReqAmt] = useState(0);
    const [islapsedModalToggle, setIslapsedModalToggle] = useState(false);
    const [isCompModalToggle, setCompModalToggle] = useState(false);
    const [isDatePickerToggle, setIsDatePickerToggle] = useState(false);
    const [Cdate, setDate] = useState(new Date());
    const [isCompletedPaymentbtnEnable, setCompletedButtonDisable] = useState(false);

    useEffect(() => {
        getAdvanceStatusDetail();
    }, [])

    const getAdvanceStatusDetail = () => {
        setLoader(true)
        getRequestDetail(advanceId)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data?.reqData);
                    setReqAmt(response?.data?.data?.reqData?.requestedAmount)

                    // alert.success(response?.data?.isError);
                    // navigate(RouterConstant.Layout);
                    // getAllRequest()
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
                setLoader(false)
            })

    }


    const handleRequestStatus = (status) => {
        setCompletedButtonDisable(true);
        setLoader(true);
        let data = {
            "requestId": advanceId,
            "status": status
        }
        if( status=='completed'){
            data['dueDateAdvanceStatusComplete']=Cdate;
        }
        changeRequestStatus(data)
            .then(response => {
                setCompletedButtonDisable(false);
                setLoader(false)
                closeAlertModal();
                if (response?.data?.status === 'OK') {
                    alert.success(response?.data?.message)
                    navigate(RouterConstant.AdvanceStatus);
                }
                else {
                    console.log("test login api", response)
                }
            })
            .catch(error => {
                setLoader(false)
                console.log(error)
                alert.error(error?.message)
            })
    }

    const closeAlertModal = () => {
        setIslapsedModalToggle(false);
        setCompModalToggle(false);
        setIsDatePickerToggle(false);
    }

    const statusConfirmation=(status)=>{
        if(status==='lapsed'){
            setIslapsedModalToggle(!islapsedModalToggle);
        }
        if(status==='completed'){
            // setCompModalToggle(!isCompModalToggle);
            setIsDatePickerToggle(!isDatePickerToggle);
        }
       
    }

    const selectDate=(date)=>{
        let tempDOB = moment(date).format('DD-MM-YYYY');
        setDate(tempDOB);
    }
  
    const saveDueDate=()=>{
        setIsDatePickerToggle(false);
      setCompModalToggle(!isCompModalToggle);
    }


    return (
        <>
            <div className="profile-section-card">
                <div className="head-wrap">
                    <h3 className="heading">Transaction Details</h3>
                </div>
                <div className="row gx-5">
                    <div className="col-md-6">
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Request ID</label>
                                    <p className="value">{apiData?.generatedRequestId}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">User ID</label>
                                    <p className="value">{apiData?.generatedUserId}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Phone Number</label>
                                    <p className="value">+234 {apiData?.mobileCountryCode} {apiData?.mobile}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">

                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Amount Approved</label>
                                    <p className="value">{apiData?.requestedAmount}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">

                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Approved Date</label>
                                    <p className="value">{moment(apiData?.approvedDate).format('DD/MM/YYYY  | HH:mm')}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">

                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Due Date ddd</label>
                                    <p className="value">{apiData?.salaryDueDate && calculateNextMonthDate(apiData?.salaryDueDate) }</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">

                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Transaction Reference No.</label>
                                    <p className="value">{apiData?.transactionId}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">

                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Account Name</label>
                                    <p className="value">{apiData?.accountHolderName}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Bank Name</label>
                                    <p className="value">{apiData?.bankName}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Account Number</label>
                                    <p className="value">{apiData?.accountNo}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">

                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Payment Mode</label>
                                    <p className="value">{apiData?.paymentMode === 1 ? 'Paystack' : apiData?.paymentMode === 2 ? 'Wallet Africa' : apiData?.paymentMode === 3 ? 'Cash' : '-'}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-6">

                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Payment Date</label>
                                    <p className="value">{moment(apiData?.paymentDate).format('DD/MM/YYYY')}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <div className="col-md-6">

                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Download Invoice</label>
                                    <a className="value" href={apiData?.invoicePdfURL} target='_blank'>{apiData?.invoicePdfURL}</a>
                                </div>
                            </div>

                        </div>

                    </div> */}

                </div>
                <div className="d-flex w-50 mb-4 mt-5">
                    <button className="btn btn-primary me-3" onClick={() => statusConfirmation('lapsed')}>Lapsed</button>
                    <button className="btn btn-outline-primary" onClick={() => statusConfirmation('completed')}>Complete</button>
                </div>
                {/* code of text area */}
            </div>
            {
                loader ?
                    <LoaderWrapper /> : ''
            }

            <Modal
                isOpen={islapsedModalToggle}
                    centered
                >
                <ModalHeader toggle={closeAlertModal}>
                    Status Lapsed
                </ModalHeader>
                <ModalBody className="logout-body">
                        <p className='text-center'>Are You sure want to change the Status?</p>
                    <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn primary-btn min-padding ms-2"
                           onClick={() => handleRequestStatus('lapsed')}>
                            Lapsed
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={closeAlertModal }>
                            Close
                        </button>
                    </div>
                </ModalBody>

            </Modal>

            <Modal
                isOpen={isCompModalToggle} centered>
                <ModalHeader toggle={closeAlertModal}>
                   Status Complete
                </ModalHeader>
                <ModalBody className="logout-body">
                        <p className='text-center'>Are You sure want to change the Status?</p>
                        <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn primary-btn min-padding ms-2"
                            disabled={isCompletedPaymentbtnEnable}  onClick={() => handleRequestStatus('completed')}>
                            Complete
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={closeAlertModal }>
                            Close
                        </button>
                    </div>
                </ModalBody>

            </Modal>

            <Modal
                isOpen={isDatePickerToggle} centered>
                <ModalHeader toggle={closeAlertModal}>
                   Due Date
                </ModalHeader>
                <ModalBody className="logout-body">
                  
                        <ReactDatePicker
                            showPopperArrow={false}
                            className={`form-control input-box p-3`}
                            dateFormat="dd-mm-yyyy"
                            value={Cdate}
                            //  selected={Cdate}                          
                            placeholderText="Select Due Date"
                            maxDate={new Date()}
                            showYearDropdown
                            dropdownMode="select"
                            scrollableYearDropdown
                            onChange={selectDate}
                            />
                            <div className='d-flex justify-content-end mt-4'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={saveDueDate}>
                            Save
                        </Button>
                        <button
                            className="btn bordered-btn min-padding ms-2"
                            onClick={closeAlertModal }>
                            Close
                        </button>
                    </div>
                </ModalBody>

            </Modal>

        </>
    )
}

export default AdvanceStatusDetail;
