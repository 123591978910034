import React, { createContext, useState } from 'react';
import { adminProfileService } from './API/Services/authService';

// Create the context
export const AppContext = createContext();

// Create a provider component
export const AppContextProvider = ({ children }) => {
    const [userData, setUserData] = useState([])
    const userProfile = () => {
        adminProfileService()
            .then(res => {
                if (res?.statusText === "OK") {
                    setUserData(res?.data?.data?.user);
                } else {
                    console.log('res profile else', res);
                }
            })
            .catch(err => {
                console.log('res profile', err);
            })
    }

    // Provide the state and functions to the children components
    return (
        <AppContext.Provider
            value={{
                userData,
                userProfile
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
