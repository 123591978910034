import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAlert } from "react-alert";
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import _ from "lodash";
import SearchWrapper from '../../Components/Search';

import { useLocation } from 'react-router-dom'
import { RouterConstant } from '../../constants/RouteConstant';
import { listSubAdminService } from '../../API/Services/authService';


const AdminListPage = () => {
    const ref = useRef()
    const navigate = useNavigate();
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [searchString, setSearchString] = useState('');
    const delayedQuery = useRef(_.debounce(q => sendQuery(q), 500)).current;
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = (event) => {
        setSearchString(event.target.value);
        delayedQuery(event.target.value);
    }
    const sendQuery = query => setSearchQuery(query);

    const handleSubAdmin = () => {
        setLoader(true);
        listSubAdminService()
            .then((res => {
                if (res?.statusText === 'OK') {
                    setApiData(res?.data?.data);
                } else {
                    alert.error(res?.message?.message);
                }
                setLoader(false);
            }))
            .catch((error) => {
                console.log('eooro', error);
                setLoader(false);
            })
    }

    useMemo(() => {
        handleSubAdmin()
    }, [])

    const handleNext = (data) => {
        navigate(`${RouterConstant.manageAdminDetails}/${data?._id}`, { state: { data } });
    }

    const handleAddNewAdmin = () => {
        navigate(`${RouterConstant.manageAdminAdd}`, { state: { add: 'add' } });
    }

    const filteredItems = apiData.filter((item) =>
        item?.fullName?.toLowerCase().includes(searchString.toLowerCase())
    );

    return (
        <>
            <h1 className='top-heading'>Manage Admin</h1>
            <div className='manage-admin'>
                <div className="top-filter-button">
                    <SearchWrapper
                        type='text'
                        name='searchString'
                        className='mb-3 mt-4 mt-md-0'
                        value={searchString}
                        onChange={handleSearch}
                        placeholder="Search..."
                    />
                </div>

                <div className='admin-button-wrapper'>
                    <button className="btn btn-admin-add" onClick={handleAddNewAdmin}>+ Add New Admin</button>
                </div>

                <div className="data-table table-responsive" ref={ref}>
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">Full Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Status</th>
                                <th scope="col">More</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredItems?.map((emp) => (
                                    <tr className='table-row'>
                                        <td className="">
                                            {emp?.fullName}
                                        </td>
                                        <td className="">
                                            {emp?.email}
                                        </td>
                                        <td className="">
                                            <div className='status-column'>
                                                <span className="icon active"></span>
                                                <span className="status-text">
                                                    {emp?.isActive ? 'Active' : 'Inactive'}
                                                </span>
                                            </div>
                                        </td>
                                        <td className=''>
                                            <span onClick={() => handleNext(emp)} className="heighlight">view more</span>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className="my-5 d-flex justify-content-center">

                    </div>
                </div>
            </div>
            {
                loader ?
                    <LoaderWrapper /> : ''
            }
        </>
    )
}

export default AdminListPage;
