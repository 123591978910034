import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import './profilestatus.scss';
import { useForm } from 'react-hook-form'
import React, {  useState } from 'react'

const EmployeeStatus = ({ isOpen, toggleProfileStatus, data, updateStatus }) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        control,
    } = useForm();

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleProfileStatus}
            className="profile-status-wrapper"
            centered={true}
        >
            {/* <ModalHeader toggle={toggleProfileStatus}>
                        Profile Status
                    </ModalHeader> */}
            <ModalBody toggle={toggleProfileStatus}>
                {/* <div className="profile-status-wrapper"> */}
                <form className="profile-status" onSubmit={handleSubmit(updateStatus)}>
                    <div className="heading-wrapper">
                        <h4 className="heading fw-bold">Add Comment</h4>
                    </div>
                    {/* <div className="body-content-wrapper">
                        <p className="text-center">Are you sure you want to <span className={`fw-bold ${data?.action === "Add Comment" ? "text-danger" : "text-success"}`}>{data?.action}</span> to {data?.type=='basicDetails'?'Basic Details':''}</p>
                    </div> */}

                    {data?.action === "Add Comment" ?
                        <div className="mt-4 text-center">
                            <label className="label mb-2">Add Note regarding the Comment</label>
                            <textarea className="form-control" rows={10}  classes ={`input-box form-control ${errors.salary ? "not-valid" : ""}`}
                                {...register("comment", { required: true })} 
                            ></textarea>
                            {errors.comment && errors.comment.type === "required" && <small className="error-msg">Please type the comment</small>}
                        </div>
                        :
                        ""
                    }

                    <div className="d-flex justify-content-end mt-4">
                        <button type="submit" className="btn primary-btn min-padding ms-2" >Save</button>
                        <button type="button" onClick={toggleProfileStatus} className="btn bordered-btn min-padding ms-2" >Cancel</button>
                    </div>
                </form>
                {/* </div> */}
            </ModalBody>
        </Modal>
    );
}

export default EmployeeStatus;