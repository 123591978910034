import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import './profilestatus.scss';
import { useForm } from 'react-hook-form'
import React, { useState } from 'react'

const FormFieldStatus = ({ isOpen, toggleFieldStatus, data, submitFieldStatus }) => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
        control,
    } = useForm();

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggleFieldStatus}
            className="profile-status-wrapper"
            centered={true}
        >
            {/* <ModalHeader toggle={toggleProfileStatus}>
                        Profile Status
                    </ModalHeader> */}
            <ModalHeader toggle={toggleFieldStatus}>
                {data.action === '2' ? 'Approve' : 'Reject'}
            </ModalHeader>
            <ModalBody className="logout-body">
                <p>{data?.action === '3' ? 'Are you sure want to Reject ?' : 'Are you sure want to Approve ?'}</p>
                <div className='d-flex justify-content-end'>
                    <Button
                        className="btn primary-btn  ms-2 min-padding" onClick={submitFieldStatus}>
                        Yes
                    </Button>
                    <button
                        className="btn bordered-btn ms-2 min-padding" onClick={toggleFieldStatus}>
                        Cancel
                    </button>
                </div>

            </ModalBody>
        </Modal>
    );
}

export default FormFieldStatus;