import React, { useContext, useEffect, useRef, useState } from 'react'
import { getRequestDetail, paymentByCash, paystackBankAccountList, bankAccountVerification, updateUserBankAccountVerification, editEmployee, paystackPaymentByAdmin } from '../../API/Services/authService';
import { useAlert } from "react-alert";
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import { RouterConstant } from '../../constants/RouteConstant';
import { useForm, Controller } from 'react-hook-form'
import { Input, Label, FormGroup, FormText, FormFeedback, Row, Col } from 'reactstrap';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppContext } from '../../AppContext';

const Payment = ({ data, rejectBasicDetail, acceptBasicDetail, addNote, toggleField, toggle, toggleFieldStatus }) => {
    let { requestId } = useParams();
    const navigate = useNavigate()
    const alert = useAlert();
    const [loader, setLoader] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [methodPayment, setMethodPayment] = useState('Pay with Paystack');
    const [isEditPayment, seIsPaymentEdit] = useState(false);
    const [allBankData, setBankData] = useState([]);
    const [bankApiData, setBankDeatilsApiData] = useState({});
    const [isBankDetailsModal, setBankDetailsModal] = useState(false);
    const [isEnablePayNow, setEnablePayNow] = useState(false);
    const advanceStatusAllString = ['live-request', 'past-request', 'lapse-request'];
    const { userData } = useContext(AppContext);
    useEffect(() => {
        getAllBankList();

    }, []);

    const userPermission = (permissionMatched) => {
        // let allMatched = false
        if (userData?.role === "admin") {
            return true
        } else {
            if (userData?.permissions?.length) {
                return permissionMatched.some((str) => userData?.permissions.includes(str));
            }
        }
    }


    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        formState,
        getValues,
        formState: { errors, isDirty, isSubmitSuccessful },
        control,
    } = useForm();

    useEffect(() => {
        const fields = ['accountHolderName', 'accountNo', 'bankName'];
        fields.forEach(field => setValue(field, apiData[field]));
        // setValue(field, fieldData)
        // setValue('holderName', bankDetails?.accountHolderName)
        // setValue('acc', bankDetails?.accountNo)
    }, [apiData])

    // call APi for Bank lists
    const getAllBankList = () => {
        setLoader(true);
        paystackBankAccountList()
            .then((response) => {
                getrequestDetails();
                setLoader(false)
                if (response?.data?.status) {
                    setBankData(response?.data?.data?.data)
                } else {
                    console.log("test login api", response)
                }

            })
            .catch(error => {
                setLoader(false);
                console.log("catch block", error)
                //   setMessage(error.data.message);
                alert.error(error?.message);
            })

    }


    const getrequestDetails = () => {
        setLoader(true);
        getRequestDetail(requestId)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    setApiData(response?.data?.data?.reqData);
                    setValue('accountNo', response?.data?.data?.reqData?.accountNo);
                    setValue('accountHolderName', response?.data?.data?.reqData?.accountHolderName);
                    setValue('bankName', response?.data?.data?.reqData?.bankName);
                    setValue('bankCode', response?.data?.data?.reqData?.bankCode);
                    setEnablePayNow(response?.data?.data?.reqData?.paymentStatus)
                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })

    }


    const paymentconfirm = () => {
        if (methodPayment === 'Pay Via Cash') {
            setLoader(true);
            let param = { requestId: requestId }
            paymentByCash(param)
                .then((response) => {
                    if (response?.data?.status === 'OK') {
                        alert.show(response?.data?.message);
                        setLoader(false)
                        {
                            if(userPermission(advanceStatusAllString)){
                                navigate(RouterConstant.AdvanceStatus);
                            }else{
                                navigate(RouterConstant.Request);
                            }
                        }
                    } else {
                        console.log("test login api", response);
                    }
                    setLoader(false);
                })
                .catch(error => {
                    console.log("catch block", error);
                    alert.error(error?.message);
                    setLoader(false)
                })
        }
        else if (methodPayment == '') {
            alert.error('Select Payment Method');
        }
        else if (methodPayment === 'Pay with Paystack') {
            paystackPayment();
        }
        else {
            alert.error('Only Pay Via Cash and Pay with Paystack is available');
        }


    }

    const paymentMethod = (data) => {
        setMethodPayment(data.target.value)

    }

    const editBankDetails = () => {
        // isEditPayment = !isEditPayment;
        seIsPaymentEdit(!isEditPayment)
    }



    const handleSubmitData = (formData) => {
        let bankname = allBankData.find((e) => {
            return e.name === formData['bankName']
        })

        let obj = {
            accountNo: getValues('accountNo'),
            bankCode: getValues('bankCode') || '033',
            accountHolderName: formData['accountHolderName']

        }

        bankAccountVerification(obj).then((response) => {
            if (response?.data?.status === 'OK') {
                let formField = new FormData();
                formField.append('userId', apiData?.userId);
                formField.append('bankCode', obj?.bankCode);
                formField.append('bankName', formData['bankName']);
                formField.append('accountNo', formData?.accountNo);
                formField.append('accountHolderName', formData['accountHolderName']);
                userDetailsUpdateApi(formField);
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false);
            })

    }

    const confirmBankVerification = () => {
        let params = {
            "userId": apiData?.userId,
            "status": "1"
        }
        setLoader(true);
        setBankDetailsModal(false);
        updateUserBankAccountVerification(params).then((response) => {
            seIsPaymentEdit(!isEditPayment);
            if (response?.data?.status === 'OK') {
                alert.success('Bank Verified Successfully');
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        }).catch(error => {
            console.log("catch block", error)
            alert.error(error?.message);
            setLoader(false);
        })
    }

    const rejectBankVerification = () => {
        let params = {
            "userId": apiData?.userId,
            "status": "2"
        }
        setBankDetailsModal(false);
        setLoader(true);
        updateUserBankAccountVerification(params).then((response) => {
            seIsPaymentEdit(!isEditPayment);
            if (response?.data?.status === 'OK') {
            } else {
                console.log("test login api", response)
            }
            setLoader(false)
        }).catch(error => {
            console.log("catch block", error)
            alert.error(error?.message);
            setLoader(false);
        })
    }

    const userDetailsUpdateApi = (data) => {
        setLoader(true);
        editEmployee(data)
            .then(res => {
                setLoader(false);
                if (res?.statusText === 'OK') {
                    setBankDetailsModal(!isBankDetailsModal);
                    setBankDeatilsApiData(res?.data?.data?.user?.bankDetails)
                }
            })
            .catch(err => {
                setLoader(false);
                console.log(err)
                alert.error(err?.message)
            })
    }

    const paystackPayment = () => {
        setLoader(true);
        let obj = {
            'userId': apiData?.userId,
            'requestId': requestId,
            'amount': apiData?.amount
        }
        paystackPaymentByAdmin(obj)
            .then(res => {
                setLoader(false);
                if (res?.statusText === 'OK') {
                    alert.success(res?.data?.message);
                    navigate(RouterConstant.AdvanceStatus);
                }
            })
            .catch(err => {
                setLoader(false);
                console.log(err)
                alert.error(err?.message)
            })
    }


    return (
        <>
            <div className="view-details-wrapper my-5 px-0">
                <div className="profile-header">
                    <h4 className="view-details-heading text-capitalize">Pay the Due</h4>
                </div>
                <div className="profile-section-card col-lg-6">
                    <div className="head-wrap">
                        <h3 className="heading">Choose Payment Method
                            <span className={`badge ms-3 badge-msg ${data?.status === -1 ? "bg-danger" : data?.status === 2 ? "bg-success" : ''}`}>
                                {`${data?.status === -1 ? "Rejected" : data?.status === 2 ? "Approved" : ''}`}
                            </span>
                        </h3>
                    </div>
                    <form className="profile-status" onSubmit={handleSubmit(handleSubmitData)}>

                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key d-block mb-2">Payment Option</label>

                                    <select className="form-control p-3" onChange={e => paymentMethod(e)}>
                                        {/* <option value={''} selected disabled>Select</option> */}
                                        <option value={'Pay Via Cash'}>Pay Via Cash</option>
                                        <option value={'Pay with Paystack'} selected>Pay with Paystack</option>
                                        <option value={'Pay wallets affrica'}>Pay wallets affrica</option>
                                    </select>
                                </div>
                            </div>
                            {
                                methodPayment !== 'Pay Via Cash' &&

                                <>

                                    <div className="field-wrapper">
                                        <div className="form-group">
                                            <label className="label-key mb-2">Account Number</label>
                                            <input type="text" className={`input-box form-control p-3`}
                                                {...register('accountNo')} readOnly={!isEditPayment} />
                                        </div>

                                    </div>

                                    <div className="field-wrapper">
                                        <div className="form-group">
                                            <label className="label-key mb-2">Bank Name</label>
                                            <select className=" form-control p-3" {...register('bankName', { disabled: !isEditPayment })}  >
                                                {
                                                    allBankData?.map((bank, key) => {
                                                        return <option value={bank.name} key={key}>{bank.name}</option>

                                                    })
                                                }
                                            </select>
                                            {/* <p className="value">{apiData?.bankName}</p> */}
                                        </div>




                                    </div>

                                    <div className="field-wrapper">
                                        <div className="form-group">
                                            <label className="label-key mb-2">Account Holder Name</label>
                                            <input type="text" className={`input-box form-control p-3`}
                                                {...register('accountHolderName')} name="accountHolderName" readOnly={!isEditPayment} />
                                        </div>

                                    </div>
                                </>

                            }

                        </div>
                        {/* code of text area */}
                        {isEnablePayNow === 3 || isEnablePayNow === 4 ?
                            <div >
                                <span style={{ color: "red", marginLeft: "180px" }}>{apiData?.paymentReason}</span>
                            </div>
                            : ''}
                        {isEnablePayNow === 1 ?
                            <div style={{ color: "#E4A11B", textAlign: "right", marginRight: '35px' }}>
                                <p >Payment In Process</p>
                            </div>
                            : ''}

                        <div className='d-flex justify-content-end pb-4'>
                            {
                                !isEditPayment && isEnablePayNow != 1 ?
                                    <div>
                                        <button type='button' className="btn primary-btn min-padding ms-2" onClick={paymentconfirm}> Pay</button>
                                    </div>
                                    : null
                            }

                            {

                                isEditPayment && methodPayment === 'Pay with Paystack' ?
                                    <>
                                        <div>
                                            <button type='submit' className="btn primary-btn min-padding ms-2" >Save</button>
                                        </div>
                                        <div>
                                            <button className="btn primary-btn min-padding ms-2" onClick={editBankDetails}>Cancel</button>
                                        </div>
                                    </>
                                    :
                                    methodPayment === 'Pay with Paystack' && isEnablePayNow != 1 ?
                                        <div>
                                            <button className="btn primary-btn min-padding ms-2" onClick={editBankDetails}> Edit</button>
                                        </div>
                                        : ''
                            }


                            {/* <div>
                            <button type='button' className="btn min-padding ms-2 bordered-btn"> Add Comments</button>
                        </div> */}
                        </div>
                    </form>
                </div>
            </div>

            <Modal isOpen={isBankDetailsModal} centered>
                <ModalHeader>
                    Bank Details
                </ModalHeader>
                <ModalBody className="logout-body">
                        <p>Account Holder Name:{bankApiData?.accountHolderName}</p>
                        <p>Account Number:{bankApiData?.accountNo}</p>
                        <p>Bank id:{bankApiData?.bankCode}</p>
                        <div className='d-flex justify-content-end'>
                        <Button
                            className="btn primary-btn min-padding ms-2" onClick={confirmBankVerification}>
                            Accept
                        </Button>
                        <Button
                            className="btn danger-btn min-padding ms-2" onClick={rejectBankVerification} >
                            Reject
                        </Button>
                    </div>
                </ModalBody>
            </Modal>

            {
                loader ? <LoaderWrapper /> : ''
            }
        </>
    );
}

export default Payment;