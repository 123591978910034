import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginApiFun } from '../API/Services/authService';
import { RouterConstant } from '../constants/RouteConstant';
import { useForm } from 'react-hook-form';
import { ReactComponent as Logo } from '../assets/images/logo.svg';
import { useAlert } from "react-alert";
import './auth.scss';
import LoaderWrapper from '../Components/LoaderWrapper/LoaderWrapper';
const Login = () => {
    let navigate = useNavigate()
    const alert = useAlert();
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();


    const handleLogin = (formData) => {
        setLoader(true)
        let data = {
            "email": formData.email,
            "password": formData.password
        }
        loginApiFun(data)
            .then((response) => {
                if (response?.data?.status === 'OK') {
                    localStorage.setItem('token', response?.data?.data?.token);
                    localStorage.setItem('user', JSON.stringify(response?.data?.data))
                    alert.success(response?.data?.message);
                    navigate(RouterConstant.Layout);

                } else {
                    console.log("test login api", response)
                }
                setLoader(false)
            })
            .catch(error => {
                console.log("catch block", error)
                alert.error(error?.message);
                setLoader(false)
            })
    }

    return (
        <>
        <div className="auth-wrapper">
            <div className="auth-inner-wrapper">
                <div className="auth-header">
                    <div className="logo-wrapper">
                        <Logo className="logo-icon" />
                    </div>
                    <h2 className="auth-heading text-center">
                        Access your salary anytime, anywhere
                    </h2>
                    <p className="auth-sub-heading-1 text-center">Welcome Back</p>
                    <p className="auth-sub-heading-2 text-center">Sign In</p>
                </div>

                <form className="form-wrapper" onSubmit={handleSubmit(handleLogin)}>
                    <div className="input-wrapper">
                        <div className="phn-details-placeholder">
                            {/* <NumberIcon /> */}
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            <p>+234</p>
                            {/* <DropdownIcon /> */}
                        </div>
                        <input className={`form-control phn-field input-box ${errors.email ? "not-valid" : ""}`}
                            {...register('email', { required: true, pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/  })}
                             placeholder="Email Id"
                        />
                        {errors.email && errors.email.type === "required" && <small className="error-msg">Email is required</small>}
                        {/* {errors.email && errors.email.type === "required" && <small className="error-msg">Please enter all the details inorder to Login</small>} */}
                        {errors.email && errors.email.type === "pattern" && <small className="error-msg">Invalid email address.</small>}
                    </div>
                    <div className="input-wrapper">
                        <input type="password" className={`form-control input-box ${errors.password ? "not-valid" : ""}`}
                            {...register('password', { required: true })}
                            id="password" placeholder="Password" />
                        {errors.password && errors.password.type === "required" && <small className="error-msg">Password is required</small>}
                        
                    </div>
                    <div className="text-center submit">
                        <button type="submit" className="btn primary-btn">Continue</button>
                    </div>
                </form>
            </div>
        </div>
        {
            loader? 
            <LoaderWrapper/>:''
        }
        </>
    )
}

export default Login
