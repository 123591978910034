import React, { useContext, useEffect, useState } from 'react'
import './header.scss'
import {ReactComponent as MenuIcon} from'../assets/images/icons/menu_icon.svg'
// import { AppContext } from './AppContext';

const Header = ({toggle, toggleSidebar}) => {
    
    return (
        <header className="header-wrapper">
            <div className="menu-icon-wrapper" onClick={toggleSidebar}>
                <MenuIcon />
            </div>
            <div className = "header-user-wrapper">
                <div className="header-icon"></div>
                <div className="user-name-wrapper">
                    <h4 className="user-name">Ada Ike</h4>
                    <p className="user-role">Admin</p>
                </div>
            </div>
        </header>
    )
}

export default Header
