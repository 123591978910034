import React, { useEffect, useState } from 'react'
import { useAlert } from "react-alert";
import LoaderWrapper from '../../Components/LoaderWrapper/LoaderWrapper';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import _ from "lodash";
import { RouterConstant } from '../../constants/RouteConstant';
import { addSubAdminService, updateSubAdminService } from '../../API/Services/authService';
import { ReactComponent as Edit } from './../../assets/images/icons/edit.svg';

const AdminDetailsDetail = (props) => {
    const navigate = useNavigate();
    let { adminId } = useParams();
    let location = useLocation()
    const pageType = location?.state?.add;
    const data = location?.state?.data;
    const [formValue, setFormValue] = useState({
        fullName: data?.fullName ? data?.fullName : '',
        email: data?.email ? data?.email : '',
        isActive: data?.isActive ? data?.isActive : false,
        permissions: data?.permissions?.length ? data?.permissions : []
    })
    const [updateAdmin, setUpdateAdmin] = useState(false)
    const alert = useAlert();
    const [loader, setLoader] = useState(false);


    const handleUpdateEmployee = () => {
        setLoader(true);
        const requestPayload = {
            "userId": data?._id,
            "fullName": formValue?.fullName,
            "email": formValue?.email,
            "isActive": Boolean(formValue?.isActive),
            "permissions": formValue?.permissions
        }
        if (pageType === "add") {
            addSubAdminService(requestPayload).
                then(res => {
                    if (res?.statusText === 'OK') {
                        alert.success(res?.data?.message);
                        navigate(RouterConstant?.manageAdmin);
                    } else {
                        alert.success(res?.data?.message);
                    }
                    setLoader(false);
                })
                .catch(error => {
                    console.log('error', error);
                    alert.error(error);
                    setLoader(false);

                })

        } else {
            updateSubAdminService(requestPayload).
                then(res => {
                    if (res?.statusText === 'OK') {
                        alert.success(res?.data?.message);
                        navigate(RouterConstant?.manageAdmin);
                    } else {
                        alert.error(res?.data?.message);
                    }
                    setLoader(false);
                })
                .catch(error => {
                    console.log('error', error);
                    alert.error(error);
                    setLoader(false);

                })
        }
    }
    const handleCancel = () => {
        navigate(RouterConstant?.manageAdmin)
    }

    const handleFormChange = (event) => {

        const { name, value } = event.target;
        setFormValue((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    const handlePermission = (event) => {
        const { name, value } = event.target;
        const tempRequest = _.cloneDeep(formValue?.permissions)

        if (formValue?.permissions?.includes(name)) {
            const newArray = tempRequest.filter((item) => item !== name);
            setFormValue((prevFormData) => ({
                ...prevFormData,
                permissions: newArray,
            }));
        } else {
            tempRequest?.push(name)
            setFormValue((prevFormData) => ({
                ...prevFormData,
                permissions: tempRequest,
            }));
        }

    }
    return (
        <>
            <h1 className='top-heading'>{updateAdmin ? 'Edit Admin Profile' : pageType === "add" ? 'Add New Admin' : 'Admin Details'}</h1>
            <div className="profile-section-card">
                <div className="head-wrap d-flex py-3 justify-content-between">
                    <h3 className="heading">{updateAdmin ? 'Edit Admin Profile' : 'Admin Details'}</h3>
                    {
                        pageType !== "add" &&
                        <div className='d-flex align-items-center cursor-pointer' onClick={() => setUpdateAdmin(!updateAdmin)
                        } >
                            <h6 className='me-2'>{!updateAdmin ? 'Edit' : 'Cancel'}</h6>
                            {
                                !updateAdmin &&
                                <Edit className='cursor-pointer' />
                            }
                        </div>
                    }
                </div>
                <div className="row gx-5">
                    <div className="col-md-6">
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Full Name</label>
                                    {
                                        (updateAdmin || pageType === "add") ?
                                            <div>
                                                <input name="fullName" onChange={handleFormChange} value={formValue?.fullName} className='form-control' type="text" />
                                            </div>
                                            :
                                            <p className="value">{formValue?.fullName}</p>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Email</label>
                                    {
                                        (updateAdmin || pageType === "add") ?
                                            <div>
                                                <input name="email" onChange={handleFormChange} value={formValue?.email} className='form-control' type="text" />
                                            </div>
                                            :
                                            <p className="value">{formValue?.email}</p>
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Status</label>
                                    {
                                        (updateAdmin || pageType === "add") ?
                                            <div className='d-flex'>
                                                <div className="form-check">
                                                    <input className="form-check-input" onChange={handleFormChange} type="radio" value='true' checked={formValue?.isActive == 'true' || formValue?.isActive == true} name="isActive" id="statusActive" />
                                                    <label className="form-check-label" htmlFor="statusActive">Active</label>
                                                </div>
                                                <div className="form-check ms-5">
                                                    <input className="form-check-input" onChange={handleFormChange} type="radio" name="isActive" value='false' checked={formValue?.isActive == 'false' || formValue?.isActive == false} id="statusInActive" />
                                                    <label className="form-check-label" htmlFor="statusInActive">In Active</label>
                                                </div>
                                            </div>
                                            :
                                            <p className="value">{(formValue?.isActive == 'true') ? 'Active' : 'In Active'}</p>
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">Web Role</label>
                                    {
                                        (updateAdmin || pageType === "add") ?
                                            <div>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <h6 className='mt-2 mb-3'>Employee Permission</h6>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="employee-incomplete" checked={formValue?.permissions?.includes('employee-incomplete')} id="incompleteProfile" name="employee-incomplete" />
                                                            <label className="form-check-label" htmlFor="incompleteProfile">Incomplete Profile</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="kyc-pending" id="kycPending" checked={formValue?.permissions?.includes('kyc-pending')} name="kyc-pending" />
                                                            <label className="form-check-label" htmlFor="kycPending">KYC Pending</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="kyc-rejected" id="kycReject" checked={formValue?.permissions?.includes('kyc-rejected')} name="kyc-rejected" />
                                                            <label className="form-check-label" htmlFor="kycReject">KYC Rejected</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="employee-completed" id="completeProfile" checked={formValue?.permissions?.includes('employee-completed')} name="employee-completed" />
                                                            <label className="form-check-label" htmlFor="completeProfile">Complete Profile</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <h6 className='mt-3 mb-3'>Request Permissions</h6>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="" id="Pending" checked={formValue?.permissions?.includes('pending-request')} name="pending-request" />
                                                            <label className="form-check-label" htmlFor="Pending">Pending</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="rejected-request" id="Rejected" checked={formValue?.permissions?.includes('rejected-request')} name="rejected-request" />
                                                            <label className="form-check-label" htmlFor="Rejected">Rejected</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="approved-request" id="Approved" checked={formValue?.permissions?.includes('approved-request')} name="approved-request" />
                                                            <label className="form-check-label" htmlFor="Approved">Approved</label>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <h6 className='mt-3 mb-3'>Advance Permissions</h6>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="live-request" id="Live" checked={formValue?.permissions?.includes('live-request')} name="live-request" />
                                                            <label className="form-check-label" htmlFor="Live">Live</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="past-request" id="Past" checked={formValue?.permissions?.includes('past-request')} name="past-request" />
                                                            <label className="form-check-label" htmlFor="Past">Past</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="lapse-request" id="Lapse" checked={formValue?.permissions?.includes('lapse-request')} name="lapse-request" />
                                                            <label className="form-check-label" htmlFor="Lapse">Lapse</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <h6 className='mt-3 mb-3'>Other Permissions</h6>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="" id="Admin Controls" checked={formValue?.permissions?.includes('admin-control')} name="admin-control" />
                                                            <label className="form-check-label" htmlFor="Admin Controls">admin-control</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="processing-fee-control" id="Processing Fee Controls" checked={formValue?.permissions?.includes('processing-fee-control')} name="processing-fee-control" />
                                                            <label className="form-check-label" htmlFor="Processing Fee Controls">Processing Fee Controls</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" onChange={handlePermission} type="checkbox" value="manage-admin" id="Manage Admins" checked={formValue?.permissions?.includes('manage-admin')} name="manage-admin" />
                                                            <label className="form-check-label" htmlFor="Manage Admins">Manage Admins</label>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                            :
                                            <>
                                                {
                                                    formValue?.permissions?.map((item) => (
                                                        <p className="value" key={item}>{item}</p>
                                                    ))
                                                }
                                            </>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="detail-field">
                            <div className="field-wrapper">
                                <div className="form-group">
                                    <label className="label-key">User ID</label>
                                    <p className="value">{data?._id}</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {
                    (updateAdmin || pageType === "add") &&
                    <div className="d-flex w-50 mb-4 mt-5">
                        <button className="btn btn-primary me-3" onClick={handleUpdateEmployee}>Save</button>
                        <button className="btn btn-outline-primary" onClick={handleCancel}>Calcel</button>
                    </div>
                }
                {/* code of text area */}
            </div>
            {
                loader ?
                    <LoaderWrapper /> : ''
            }
        </>
    )
}

export default AdminDetailsDetail;
