import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import './manage-admin.scss';
import { AppContext } from '../../AppContext';


const ManageAdmin = () => {
    const { userProfile } = useContext(AppContext);
    useEffect(()=>{
        userProfile()
    },[])
    
    return (
        <Outlet/>
    )
}

export default ManageAdmin;
